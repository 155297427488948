import API, { post, get } from "./api";

export const samplesListSelect = [
    { value: "sampleName", label: "Nazwa próbki" },
    { value: "supplier", label: "Dostawca" },
    { value: "placement", label: "Miejsce" },
    { value: "description", label: "Opis" },
];

export interface SamplePropsResponse {
    sampleList: SampleProps[];
}

export interface SampleProps {
    sampleName: string;
    userName: string;
    supplier: string;
    description: string;
    placement: string;
    createdAt: string;
    id: number;
}

const addNewSample = (credentials: any) =>
    post<SampleProps>(`${API.baseApiUrl}/add-sample`, credentials).then(({ parsedBody }) => parsedBody);

const getSampleList = (credentials: any) =>
    get<SampleProps>(`${API.baseApiUrl}/samples`, credentials).then(({ parsedBody }) => parsedBody);

const editSample = (credentials: any) =>
    post<SampleProps>(`${API.baseApiUrl}/edit-sample`, credentials).then(({ parsedBody }) => parsedBody);

const deleteSample = (credentials: any) =>
    post<SampleProps>(`${API.baseApiUrl}/delete-sample`, credentials).then(({ parsedBody }) => parsedBody);

export default {
    addNewSample,
    getSampleList,
    editSample,
    deleteSample,
};
