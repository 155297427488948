import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "../IngredientList/ingredienstList.action";
import { fetchIngredientsList } from "./ingredienstList.action";
import { IngredientsProps } from "../../api/ingredientsList";

export type IngredientsListAction = ActionType<typeof actions>;

export type IngredientsListState = Readonly<{
    ingredients: IngredientsProps[];
    isLoading: boolean;
}>;

export const ingredientsListInitialState: IngredientsListState = {
    isLoading: false,
    ingredients: [],
};

export const ingredientsListReducer = createReducer<IngredientsListState, IngredientsListAction>(
    ingredientsListInitialState
)
    .handleAction(fetchIngredientsList.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchIngredientsList.success, (state, action) => {
        return {
            ...state,
            ingredients: action.payload.ingredients,
            isLoading: false,
        };
    })
    .handleAction(fetchIngredientsList.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
