import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkingRecipesListState } from "store/workingRecipe/workingRecipe.selector";
import { fetchWorkingRecipesList } from "store/workingRecipe/workingRecipe.actions";
import RecipesList from "components/RecipesList/RecipesList";
import { RecipeSource } from "components/RecipeView/RecipeView";
import styles from "./WorkingRecipes.module.scss";
import AddRecipeButton, { AddRecipeSource } from "../AddRecipeButton/AddRecipeButton";
import { frontMessages } from "../../utils/messages";

const WorkingRecipes: React.FC = () => {
    const dispatch = useDispatch();
    const { workingRecipes } = useSelector(getWorkingRecipesListState);

    React.useEffect(() => {
        if (!workingRecipes?.length) {
            dispatch(fetchWorkingRecipesList.request());
        }
    }, []);

    return (
        <>
            <div className={styles.recipesButton}>
                <AddRecipeButton
                    addRecipeSource={AddRecipeSource.WORKING}
                    recipeButtonTitle={frontMessages.button.addWorkingRecipe}
                />
            </div>
            <div className={styles.workingRecipesWrapper}>
                <RecipesList
                    recipesListState={workingRecipes}
                    recipeSource={RecipeSource.WORKING_LIST}
                    isHistory={false}
                    isWorkingHistory={false}
                />
            </div>
        </>
    );
};

export default WorkingRecipes;
