import { createAsyncAction } from "typesafe-actions";
import { EditUserApiResponse, UserEditProps, UserManagementResponse } from "api/userManagement";

export const fetchUserList = createAsyncAction(
    "FETCH_USER_LIST_REQUEST",
    "FETCH_USER_LIST_SUCCESS",
    "FETCH_USER_LIST_FAILURE"
)<void, UserManagementResponse, Error>();

export const editUser = createAsyncAction("EDIT_USER_REQUEST", "EDIT_USER_SUCCESS", "EDIT_USER_FAILURE")<
    UserEditProps,
    EditUserApiResponse,
    Error
>();
