import React from "react";
import styles from "./RecipeIngredients.module.scss";
import { NewRecipeState } from "components/RecipeFormCreator/RecipeFormCreator";
import { IngredientsProps } from "api/ingredientsList";
import Input from "../Input/Input";
import { FieldTitle, FieldType } from "../../utils/forms.helper";
import { frontMessages } from "../../utils/messages";
import PositionChanger from "../PositionChanger/PositionChanger";
import { Ingredient } from "../../api/recipesList";
import RecipeIngredientAutocomplete from "./RecipeIngredientAutocomplete/RecipeIngredientAutocomplete.";
import RecipeActionButtons from "../RecipeFormCreator/RecipeActionButtons/RecipeActionButtons";
import RecipeInstruction from "../RecipeFormCreator/RecipeInstruction/RecipeInstruction";
import { useIsInMobileView } from "../../hooks/useIsInMobileView";
import RecipeIngredientPrice from "./RecipeIngredientPrice/RecipeIngredientPrice";
import { useSelector } from "react-redux";
import { getWorkingIngredientsListState } from "../../store/workingIngredients/WorkingIngredientList.selector";
import { setPrice } from "../../utils/utils";

interface RecipesIngredientsProps {
    ingredients: IngredientsProps[];
    ingredientInitialValues?: Ingredient[];
    isPricesOpen: boolean;
    initialSumValue?: number;
    registerForm: any;
    errors: any;
}

const RecipeIngredients: React.FC<RecipesIngredientsProps> = ({
    registerForm,
    ingredients,
    errors,
    initialSumValue,
    ingredientInitialValues,
    isPricesOpen,
}) => {
    const [sumValue, setSumValue] = React.useState(0);
    const { isMobile } = useIsInMobileView();
    const [recipeDensity, setRecipeDensity] = React.useState(null);
    const { workingIngredients } = useSelector(getWorkingIngredientsListState);
    const [inputList, setInputList] = React.useState<NewRecipeState[]>([
        {
            ingredientName: "",
            ingredientValue: "",
            ingredientPrice: "0",
            ingredientDescription: null,
            isActive: false,
        },
    ]);

    React.useEffect(() => {
        if (ingredientInitialValues) {
            const remappedValues = ingredientInitialValues?.map((item) => {
                return {
                    ingredientName: item.name,
                    ingredientValue: item.value.toString(),
                    ingredientDescription: item.description || null,
                    ingredientPrice: item.price || "0",
                    isActive: false,
                };
            });

            if (initialSumValue) {
                setSumValue(initialSumValue);
            }

            setInputList(remappedValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSumChange = (event: any) => {
        let values = sumValue;
        values = event.target.value;
        setSumValue(values);
    };

    const handleRecipeDensityChange = (event: any) => {
        const values = event.target.value;
        setRecipeDensity(values);
    };

    const percentageSum = () => {
        const value = inputList.reduce((a, b) => {
            const numberValue = b.ingredientValue === "" ? 0 : parseFloat(b.ingredientValue.replace(/,/g, ""));
            return a + numberValue;
        }, 0);

        return isNaN(value) ? 0 : value;
    };

    const calculatePriceSum = () => {
        const value = inputList.reduce((a, b) => {
            const workingPrice = workingIngredients.find((ingredient) => ingredient.ingredient === b.ingredientName);
            const productionPrice = ingredients.find((ingredient) => ingredient.ingredient === b.ingredientName);
            const price = setPrice(b.ingredientPrice, workingPrice?.price || "0", productionPrice?.price || "0");
            const numberValue = parseFloat(price.replace(/,/g, ""));
            return a + (numberValue * parseFloat(b.ingredientValue)) / 100;
        }, 0);

        return isNaN(value) ? 0 : value.toFixed(3);
    };

    const handleChangeIngredients = (index: number, event: any) => {
        const values = [...inputList];
        const fieldName = `ingredients[${index}]`;

        if (event.target.name === `${fieldName}.name`) {
            values[index].ingredientName = event.target.value;
        }

        if (event.target.name === `${fieldName}.value`) {
            if (event.target.value.length > 6) {
                return;
            }

            values[index].ingredientValue = event.target.value;
        }

        if (event.target.name === `${fieldName}.price`) {
            values[index].ingredientPrice = event.target.value;
        }

        if (event.target.name === `${fieldName}.description`) {
            values[index].ingredientDescription = event.target.value;
        }

        setInputList(values);
    };

    const ingredientsSumValue = (ingredientValue: number) => (ingredientValue * sumValue) / 100;

    const ingredientsDensityValue = (ingredientValue: number) => {
        if (recipeDensity) {
            const value = ingredientValue / recipeDensity;
            return Number(value.toFixed(3));
        }

        return (ingredientValue * sumValue) / 100;
    };

    const handleFocusInput = (index: number) => () => {
        const ingredientList = [...inputList];
        ingredientList[index].isActive = true;
        setInputList(ingredientList);
    };

    const handleBlurInput = (index: number) => () => {
        const ingredientList = [...inputList];
        setInputList(ingredientList);
        ingredientList[index].isActive = false;
    };

    const handleRecountPrice = () => {
        const newInputLists = inputList.map((input) => {
            const ingredient = ingredients.find((item) => input.ingredientName === item.ingredient);

            if (ingredient?.price) {
                return {
                    ...input,
                    ingredientPrice: ingredient.price,
                };
            }

            return input;
        });

        setInputList(newInputLists);
    };

    return (
        <>
            {inputList.map((item: any, index) => {
                const fieldName = `ingredients[${index}]`;
                return (
                    <React.Fragment key={index}>
                        <div className={styles.content} key={index}>
                            <div className={styles.contentInputs} key={fieldName}>
                                <div className={styles.formPositionChanger}>
                                    <PositionChanger index={index} inputList={inputList} setInputList={setInputList} />
                                </div>
                                <div className={styles.formIndex}>{index + 1}</div>
                                <div className={`${styles.formName} ${styles.contentAutocompleteWrapper}`}>
                                    <input
                                        onFocus={handleFocusInput(index)}
                                        onBlur={handleBlurInput(index)}
                                        name={`${fieldName}.name`}
                                        placeholder={frontMessages.placeholders.IngredientName}
                                        type="text"
                                        value={item.ingredientName || ""}
                                        required={true}
                                        ref={registerForm}
                                        onChange={(event) => handleChangeIngredients(index, event)}
                                    />
                                    <RecipeIngredientAutocomplete
                                        inputList={inputList}
                                        setInputList={setInputList}
                                        index={index}
                                    />
                                </div>
                                <span className={styles.formValue}>
                                    <input
                                        name={`${fieldName}.value`}
                                        placeholder={frontMessages.placeholders.ingredientValue}
                                        type="number"
                                        value={item.ingredientValue || ""}
                                        ref={registerForm({ maxLength: 8 })}
                                        onChange={(event) => handleChangeIngredients(index, event)}
                                        required={true}
                                    />
                                </span>
                                <span className={styles.formSum}>
                                    {ingredientsSumValue(item.ingredientValue).toFixed(3)}{" "}
                                </span>
                                {!isMobile && (
                                    <span className={styles.formDensity}>
                                        {ingredientsDensityValue(item.ingredientValue).toFixed(3)}
                                    </span>
                                )}
                                <div className={styles.formPrice}>
                                    {isPricesOpen && (
                                        <RecipeIngredientPrice
                                            handleChangeIngredients={handleChangeIngredients}
                                            index={index}
                                            item={item}
                                            registerForm={registerForm}
                                            fieldName={fieldName}
                                        />
                                    )}
                                </div>
                                <div className={`${styles.formButton} ${styles.contentButtons}`}>
                                    <RecipeActionButtons
                                        inputList={inputList}
                                        index={index}
                                        item={item}
                                        setInputList={setInputList}
                                    />
                                </div>
                            </div>
                        </div>
                        <RecipeInstruction
                            setInputList={setInputList}
                            inputList={inputList}
                            item={item}
                            index={index}
                            fieldName={fieldName}
                            registerForm={registerForm}
                            handleChangeIngredients={handleChangeIngredients}
                        />
                    </React.Fragment>
                );
            })}
            <div className={styles.contentSummary}>
                <div className={styles.contentSummaryWrapper}>
                    <div className={styles.formPositionChanger} />
                    <div className={styles.formIndex} />
                    <div className={styles.formName} />
                    <div className={`${styles.formValue} ${styles.contentSummaryWrapperPercentSum}`}>
                        <span className={styles.contentSummaryWrapperPercentSumTitle}>SUMA %</span>
                        <span className={styles.contentSummaryWrapperPercentSumTotality}>
                            {percentageSum().toFixed(3)}
                        </span>
                    </div>
                    <div className={`${styles.formSum} ${styles.contentSummaryDensity}`}>
                        <span className={styles.contentSummaryWrapperContentSumTitle}>SUMA KG</span>
                        <Input
                            name={FieldTitle.RECIPE_SUM}
                            required={true}
                            isRecipeInput={true}
                            label={frontMessages.addRecipe.form.recipeName}
                            placeholder={"Ilość"}
                            defaultValue={100}
                            register={registerForm}
                            type={FieldType.NUMBER}
                            errors={errors.recipeSum}
                            onChange={handleSumChange}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequiredShort}
                        />
                    </div>
                    <div
                        className={`${styles.formDensity} ${styles.contentSummaryDensity} ${styles.contentSummaryDensitySecond}`}>
                        <span className={styles.contentSummaryWrapperContentSumTitle}>OBJ.</span>
                        <Input
                            name={FieldTitle.RECIPE_DENSITY}
                            required={false}
                            isRecipeInput={true}
                            label={""}
                            placeholder={"Gęstość"}
                            register={registerForm}
                            type={FieldType.NUMBER}
                            errors={""}
                            onChange={handleRecipeDensityChange}
                            step={"0.01"}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                        />
                    </div>
                    <div className={`${styles.formPrice} ${styles.contentSummaryWrapperContentSum}`}>
                        {isPricesOpen && (
                            <div className={styles.contentPriceWrapper}>
                                <span className={styles.contentSummaryWrapperContentSumTitle}>CENA</span>
                                <button
                                    onClick={handleRecountPrice}
                                    type="button"
                                    className="btn--default btn--height-medium btn--width-small">
                                    Przelicz
                                </button>
                                <span
                                    className={
                                        styles.contentSummaryWrapperPercentSumTotality
                                    }>{`${calculatePriceSum()} zł`}</span>
                            </div>
                        )}
                    </div>
                    <div className={styles.formButton} />
                </div>
            </div>
        </>
    );
};

export default RecipeIngredients;
