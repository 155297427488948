import { RecipesProps } from "../../api/recipesList";

export const getIngredientDetailFromUrl = (pathname: string) => {
    const lastSlash = pathname.lastIndexOf("/");

    return pathname.substring(lastSlash + 1);
};

export const filterRecipesToIngredients = (recList: RecipesProps[], locationType: any) =>
    recList.filter((recipe) => {
        const ingredientsIncluded = recipe.ingredients.filter((item) => item.name === locationType?.ingredientName);

        if (ingredientsIncluded.length) {
            return {
                ...recipe,
            };
        }

        return null;
    });
