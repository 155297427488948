import React from "react";

export const useRecipeViewList = () => {
    const [isMas, setMas] = React.useState(true);
    const handleSetMas = (isMas: boolean) => () => setMas(!isMas);

    return {
        isMas,
        handleSetMas,
    };
};
