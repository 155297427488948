import { call, put } from "redux-saga/effects";
import Api, { IngredientsHistoryResponse } from "api/IngredientsHistory";
import { notification } from "components/Notification/Notification";
import { fetchIngredientsHistory } from "store/ingredientsHistory/IngredientsHistory.actions";

export function* fetchIngredientsHistorySaga(action: ReturnType<typeof fetchIngredientsHistory.request>): Generator {
    try {
        const response = yield call(Api.getIngredientsHistory, action.payload);
        const typedResponse = response as IngredientsHistoryResponse;
        yield put(
            fetchIngredientsHistory.success({
                ingredientsHistory: typedResponse.ingredientsHistory,
                success: typedResponse.success,
            })
        );
    } catch (err: any) {
        notification(err.message, false);
        yield put(fetchIngredientsHistory.failure(err));
    }
}
