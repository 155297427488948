import React from "react";
import { FieldTitle } from "utils/forms.helper";
import styles from "./Select.module.scss";
import { useSelector } from "react-redux";
import { getCategories } from "store/categories/categories.selector";
import { useSelect } from "../../hooks/useSelect";

interface SelectComponentProps {
    required: boolean;
    errorMessage: string;
    initialSelectValue?: string;
    register: any;
    errors: any;
    setValue: any;
}

export const SelectComponent: React.FC<SelectComponentProps> = ({
    setValue,
    required,
    register,
    errors,
    errorMessage,
    initialSelectValue,
}) => {
    const { categories } = useSelector(getCategories);
    const { selectedValue, selectComponent, setSelectedValue, setSelectedLabel } = useSelect(categories);

    React.useEffect(() => {
        if (selectedValue) {
            setValue(FieldTitle.CATEGORY, selectedValue, { shouldTouch: true });
        }
    }, [selectedValue]);

    React.useEffect(() => {
        if (initialSelectValue) {
            setSelectedValue(initialSelectValue);
            setSelectedLabel(initialSelectValue);
        }
    }, []);

    return (
        <div className={styles.selectWrapper}>
            {selectComponent}
            <select className={styles.selectContent} name={FieldTitle.CATEGORY} ref={register({ required })}>
                <option value={selectedValue}>{selectedValue}</option>
            </select>
            <span className={styles.selectError}>{errors && errorMessage}</span>
        </div>
    );
};
