import React from "react";
import { IngredientsHistory } from "../api/IngredientsHistory";

export const useDateFilter = () => {
    const [searchStartDate, setSearchStartDate] = React.useState("");
    const [searchEndDate, setSearchEndDate] = React.useState("");
    const handleStartDateChange = (phrase: string) => setSearchStartDate(phrase);
    const handleEndDateChange = (phrase: string) => setSearchEndDate(phrase);
    const filterHistory = (item: IngredientsHistory, searchInput: string) => {
        const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
        const setEndDate = searchEndDate === "" ? currentDate : searchEndDate;

        return (
            item.ingredient.includes(searchInput) && item.createdAt >= searchStartDate && item.createdAt <= setEndDate
        );
    };

    const searchStartDateComponent = (
        <>
            <span>od:</span>
            <input
                type="date"
                placeholder="szukaj w historii"
                value={searchStartDate}
                onChange={(e) => handleStartDateChange(e.target.value)}
            />
        </>
    );

    const searchEndDateComponent = (
        <>
            <span>do:</span>
            <input
                type="date"
                placeholder="szukaj w historii"
                value={searchEndDate}
                onChange={(e) => handleEndDateChange(e.target.value)}
            />
        </>
    );

    return {
        filterHistory,
        searchStartDate,
        searchEndDate,
        searchStartDateComponent,
        searchEndDateComponent,
    };
};
