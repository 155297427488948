import React from "react";
import styles from "../RecipeView.module.scss";
import { frontMessages } from "../../../utils/messages";
import { useSelector } from "react-redux";
import { getUserPrivileges } from "../../../store/auth/auth.selector";
import Icons, { IconType } from "../../Icons/Icons";
import ReactToPrint from "react-to-print";

interface RecipeViewFooterButtonProps {
    componentRef: any;
    isMainRecipe: boolean;
    isWorkingRecipe: boolean;
    openModal(): void;
    onNextStep(): void;
}

const RecipeViewFooterButton: React.FC<RecipeViewFooterButtonProps> = ({
    openModal,
    isMainRecipe,
    isWorkingRecipe,
    onNextStep,
    componentRef,
}) => {
    const { hasEditorPrivileges } = useSelector(getUserPrivileges);
    return (
        <div className={styles.container}>
            <div className={styles.buttonsContent}>
                <ReactToPrint
                    trigger={() => (
                        <button className={"btn btn--default btn--width-medium"}>
                            <span>{frontMessages.button.printWorkingRecipe}</span>
                            <Icons iconType={IconType.PRINTER} styles={"icon-button"} />
                        </button>
                    )}
                    content={() => componentRef.current}
                />
            </div>
            {isMainRecipe && (
                <div className={styles.buttonsContent}>
                    <button type="button" className={"btn btn--default btn--width-medium"} onClick={openModal}>
                        <span> {frontMessages.button.printProductionRecipe}</span>
                        <Icons iconType={IconType.PRINTER} styles={"icon-button"} />
                    </button>
                </div>
            )}
            {(isMainRecipe || isWorkingRecipe) && hasEditorPrivileges && (
                <div className={styles.buttonsContent} onClick={onNextStep}>
                    <button type="button" className={"btn btn--default btn--width-medium"}>
                        <span>{frontMessages.button.editRecipe}</span>
                        <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default RecipeViewFooterButton;
