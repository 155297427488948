import React from "react";
import { getBasicMenu, getFullMenu } from "./HeaderNavHelpers";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationState } from "store/auth/auth.selector";
import { removeAccessToken } from "utils/utils";
import { logOut } from "store/auth/auth.actions";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import DesktopHeader from "./DesktopHeader/DesktopHeader";
import MobileHeader from "./MobileHeader/MobileHeader";
import { useIsInMobileView } from "../../hooks/useIsInMobileView";

export const HeaderComponent: React.FC = () => {
    const dispatch = useDispatch();
    const { userName, privileges } = useSelector(getAuthorizationState);
    const { push } = useHistory();

    const handleLogout = () => {
        dispatch(logOut());
        removeAccessToken();
        push(routes.HOME);
    };

    const menu = privileges.hasOnlyBasicPrivileges ? getBasicMenu() : getFullMenu(privileges);
    const { isMobile } = useIsInMobileView();

    return isMobile ? (
        <MobileHeader menu={menu} userName={userName} handleLogout={handleLogout} />
    ) : (
        <DesktopHeader menu={menu} userName={userName} handleLogout={handleLogout} />
    );
};
