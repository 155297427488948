import React from "react";
import { RecipeActionType } from "../../../utils/forms.helper";
import { fetchWorkingIngredientsList } from "../../../store/workingIngredients/WorkingIngredientList.action";
import { useDispatch, useSelector } from "react-redux";
import { getWorkingIngredientsListState } from "../../../store/workingIngredients/WorkingIngredientList.selector";
import { getIngredientsListState } from "../../../store/IngredientList/IngredientsList.selector";
import { fetchIngredientsList } from "../../../store/IngredientList/ingredienstList.action";

export const useRecipeFormCreatorState = () => {
    const [saveRecipeActionType, setSaveRecipeActionType] = React.useState(RecipeActionType.NONE);
    const [modalIsOpen, setModalOpen] = React.useState(false);
    const [isPricesOpen, setPricesOpen] = React.useState(false);
    const { workingIngredients } = useSelector(getWorkingIngredientsListState);
    const { ingredients } = useSelector(getIngredientsListState);

    const openModal = () => setModalOpen(true);
    const dispatch = useDispatch();
    const closeModal = () => setModalOpen(false);
    const setRecipeSaveActionType = (actionType: RecipeActionType) => setSaveRecipeActionType(actionType);
    const handleTogglePrices = (toggleOpenPrice: boolean) => setPricesOpen(toggleOpenPrice);

    React.useEffect(() => {
        if (!workingIngredients.length) {
            dispatch(fetchWorkingIngredientsList.request());
        }

        if (!ingredients.length) {
            dispatch(fetchIngredientsList.request());
        }
    }, []);

    return {
        modalIsOpen,
        saveRecipeActionType,
        isPricesOpen,
        closeModal,
        openModal,
        handleTogglePrices,
        setRecipeSaveActionType,
    };
};
