import API, { get, post } from "./api";
import { RecipesProps } from "api/recipesList";

export interface WorkingRecipesListResponse {
    workingRecipes: RecipesProps[];
}

const addNewWorkingRecipe = (credentials: any) =>
    post(`${API.baseApiUrl}/newWorkingRecipe`, credentials).then(({ parsedBody }) => parsedBody);

const getWorkingRecipeList = (credentials: any) =>
    get<WorkingRecipesListResponse>(`${API.baseApiUrl}/workingRecipeList`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const editWorkingRecipe = (credentials: any) =>
    post<WorkingRecipesListResponse>(`${API.baseApiUrl}/edit-workingRecipe`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const deleteWorkingRecipe = (credentials: any) =>
    post<WorkingRecipesListResponse>(`${API.baseApiUrl}/delete-workingRecipe`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

export default {
    addNewWorkingRecipe,
    getWorkingRecipeList,
    editWorkingRecipe,
    deleteWorkingRecipe,
};
