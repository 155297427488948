import API, { get, post } from "./api";

export interface WorkingIngredientsListResponse {
    workingIngredients: WorkingIngredientsProps[];
}

export interface WorkingIngredientsProps {
    id: number;
    ingredientCount: number;
    price?: string;
    ingredient: string;
    density?: string;
    description?: string;
    ingredientCategory?: string;
    ingredientSource: string;
}

export interface UpdateWorkingIngredientNameResponse {
    success: boolean;
    message: string;
}

export interface UpdateWorkingIngredientNameRequest {
    id: number;
    price: string;
    oldName: string;
    newName: string;
    userName: string;
    ingredientCategory: string;
}

export interface UpdateWorkingIngredientPriceRequest {
    id: number;
    price: string;
    newPrice: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

export interface UpdateWorkingIngredientCategoryRequest {
    id: number;
    price: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

export interface UpdateWorkingIngredientDensityRequest {
    id: number;
    density: string;
    newDensity: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

export interface DeleteWorkingIngredientRequest {
    id: number;
    price: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

const fetchWorkingIngredientsList = (credentials: any) =>
    get<WorkingIngredientsListResponse>(`${API.baseApiUrl}/working-ingredients`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const updateWorkingIngredientName = (credentials: any) =>
    post<UpdateWorkingIngredientNameResponse>(`${API.baseApiUrl}/working-ingredient/update-name`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const updateWorkingIngredientPrice = (credentials: any) =>
    post<UpdateWorkingIngredientNameResponse>(`${API.baseApiUrl}/working-ingredient/update-price`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const updateWorkingIngredientDensity = (credentials: any) =>
    post<UpdateWorkingIngredientNameResponse>(`${API.baseApiUrl}/working-ingredient/update-density`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const updateWorkingIngredientCategory = (credentials: any) =>
    post<UpdateWorkingIngredientNameResponse>(`${API.baseApiUrl}/working-ingredient/update-category`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const deleteWorkingIngredient = (credentials: any) =>
    post<UpdateWorkingIngredientNameResponse>(
        `${API.baseApiUrl}/working-ingredient/delete-ingredient`,
        credentials
    ).then(({ parsedBody }) => parsedBody);

export default {
    fetchWorkingIngredientsList,
    updateWorkingIngredientName,
    updateWorkingIngredientPrice,
    updateWorkingIngredientDensity,
    updateWorkingIngredientCategory,
    deleteWorkingIngredient,
};
