import React from "react";
import styles from "./Input.module.scss";
import { FieldType } from "../../utils/forms.helper";

interface InputProps {
    isRecipeInput?: boolean;
    pattern?: {
        value: any;
        message: string;
    };
    required: boolean;
    isChecked?: boolean;
    step?: string;
    label: string;
    name: string;
    type: any;
    errorMessage: string;
    defaultValue?: string | number;
    placeholder?: string;
    minLength?: number;
    register: any;
    errors: any;
    classWrapper?: string;
    onChange?(event: any): void;
}

const Input: React.FC<InputProps> = ({
    label,
    register,
    required,
    name,
    errorMessage,
    errors,
    type,
    isRecipeInput,
    placeholder,
    onChange,
    pattern,
    step,
    minLength,
    isChecked,
    defaultValue,
    classWrapper,
}) => {
    const handleOnChange = (event: any) => {
        if (onChange) {
            onChange(event);
        }
    };

    if (type === FieldType.CHECKBOX) {
        return (
            <div className={styles.checkboxInputWrapper}>
                <input name={name} type={type} defaultChecked={isChecked} ref={register({ required })} />
                <label className={styles.inputWrapperLabel}>{label}</label>
                <span className={styles.checkboxInputWrapperError}>{errors && errorMessage}</span>
            </div>
        );
    }

    if (type === FieldType.TEXT_AREA) {
        return (
            <div className={styles.textAreaInputWrapper}>
                <textarea name={name} ref={register({ required })} placeholder={placeholder} />
                <span className={styles.textAreaInputWrapperError}>{errors && errorMessage}</span>
            </div>
        );
    }

    if (!isRecipeInput) {
        return (
            <div className={`${styles.inputWrapper} ${classWrapper ? classWrapper : ""}`}>
                <label className={styles.inputWrapperLabel}>{label}</label>
                <input name={name} type={type} ref={register({ required, pattern })} placeholder={placeholder} />
                <span className={styles.inputWrapperError}>{errors && (errors?.message || errorMessage)}</span>
            </div>
        );
    }

    return (
        <div className={`${styles.inputWrapper} ${classWrapper ? classWrapper : ""}`}>
            <input
                step={step}
                name={name}
                defaultValue={defaultValue}
                onChange={(event) => handleOnChange(event)}
                type={type}
                placeholder={placeholder}
                ref={register({ required, pattern, minLength })}
            />
            <span className={styles.inputWrapperErrorRecipe}>{errors && (errors?.message || errorMessage)}</span>
        </div>
    );
};

export default Input;
