import React from "react";
import { useSelector } from "react-redux";
import { getWorkingRecipesListState } from "../../../../store/workingRecipe/workingRecipe.selector";
import RecipesList from "../../../RecipesList/RecipesList";
import { RecipeSource } from "../../../RecipeView/RecipeView";

interface ProjectViewRecipeFinderProps {
    recipes: string;
    projectId: string;
}

const ProjectViewRecipeFinder: React.FC<ProjectViewRecipeFinderProps> = ({ projectId }) => {
    const { workingRecipes } = useSelector(getWorkingRecipesListState);
    const recipes = workingRecipes.filter((item) => item.recipeNumber.includes(projectId));

    return (
        <RecipesList
            recipesListState={recipes}
            recipeSource={RecipeSource.WORKING_LIST}
            isHistory={false}
            isWorkingHistory={true}
        />
    );
};

export default ProjectViewRecipeFinder;
