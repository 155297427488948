import React from "react";
import styles from "./Logo.module.scss";
import Icons, { IconType } from "../Icons/Icons";

export const Logo: React.FC = () => {
    return (
        <div className={styles.container}>
            <Icons iconType={IconType.RECIPE_LOGO} styles={styles.containerImage} />
            <span className={styles.containerText}> RECEPTURY </span>
        </div>
    );
};
