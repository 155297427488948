import React from "react";

export const useClickedOutside = (outSideClickCallback: () => void) => {
    const ref = React.useRef(null);

    const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (ref.current && !ref.current?.contains(event.target)) {
            outSideClickCallback();
        }
    };

    React.useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref };
};
