export default {
    ADD_NEW_RECIPE: "/dodaj-recepture",
    RECIPES_HISTORY: "/historia-produkcyjnych",
    RECIPES_HISTORY_WORKING: "/historia-roboczych",
    INGREDIENTS_HISTORY: "/historia-składnikow",
    HOME: "/",
    INGREDIENTS_LIST: "/skladniki",
    WORKING_INGREDIENT_LIST: "/skladniki-robocze",
    WORKING_INGREDIENT_NAME: "/skladniki-robocze/:name",
    RECIPES: "/receptury-produkcyjne",
    USERS: "/uzytkownicy",
    WORKING_RECIPES: "/receptury-robocze",
    CATEGORIES: "/kategorie",
    INGREDIENT_NAME: "/skladniki/:name",
    RECIPE_VIEW: "/receptura/:name",
    WORKING_RECIPE_VIEW: "/receptura-robocza/:name",
    HISTORY_RECIPE_VIEW: "/receptura-historia/:name",
    WORKING_HISTORY_RECIPE_VIEW: "/receptura-robocza-historia/:name",
    RECIPE_EDIT: "/edycja/:name",
    USER_REGISTER: "dodaj-uzytkownika",
    SAMPLES: "/proby",
    PROJECTS: "/projekty",
    PROJECTS_VIEW: "/projekty/:projectId",
};
