import { createAsyncAction } from "typesafe-actions";
import { RecipeActionType } from "../../utils/forms.helper";

export interface RecipeCreatorFormProps {
    userName: string;
    recipeSum: number;
    ingredients: RecipeIngredient[];
    actionType: RecipeActionType;
    category: string;
    company: string;
    defaultValue: string;
    density: string;
    hardener: string;
    recipeDensity: string;
    recipeName: string;
    recipeNumber: string;
    viscosity: string;
    editReason?: string;
}

export interface RecipeIngredient {
    name: string;
    value: string;
    description?: string;
    price?: string;
}

export interface AddNewRecipeResponseProps {
    message: string;
    success: boolean;
}

export interface ApiResponse {
    message: string;
    success: boolean;
}

export const addNewRecipe = createAsyncAction(
    "ADD_NEW_RECIPE_REQUEST",
    "ADD_NEW_RECIPE_SUCCESS",
    "ADD_NEW_RECIPE_FAILURE"
)<RecipeCreatorFormProps, ApiResponse, Error>();

export const editRecipe = createAsyncAction("EDIT_RECIPE_REQUEST", "EDIT_RECIPE_SUCCESS", "EDIT_RECIPE_FAILURE")<
    RecipeCreatorFormProps,
    ApiResponse,
    Error
>();

export const deleteRecipe = createAsyncAction(
    "DELETE_RECIPE_REQUEST",
    "DELETE_RECIPE_SUCCESS",
    "DELETE_RECIPE_FAILURE"
)<RecipeCreatorFormProps, ApiResponse, Error>();

export const printRecipe = createAsyncAction("PRINT_RECIPE_REQUEST", "PRINT_RECIPE_SUCCESS", "PRINT_RECIPE_FAILURE")<
    RecipeCreatorFormProps,
    ApiResponse,
    Error
>();
