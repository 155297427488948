import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectListState } from "../../../store/projects/projects.selector";
import React from "react";
import { fetchProjectList } from "../../../store/projects/projects.actions";
import { fetchWorkingRecipesList } from "../../../store/workingRecipe/workingRecipe.actions";
import { getWorkingRecipesListState } from "../../../store/workingRecipe/workingRecipe.selector";

export const useProjectView = () => {
    const { projectsList } = useSelector(getProjectListState);
    const { workingRecipes } = useSelector(getWorkingRecipesListState);
    const { projectId } = useParams<{ projectId: string }>();
    const dispatch = useDispatch();

    const project = projectsList.find((item) => item.projectId === projectId);

    React.useEffect(() => {
        if (!projectsList.length) {
            dispatch(fetchProjectList.request());
        }

        if (!workingRecipes?.length) {
            dispatch(fetchWorkingRecipesList.request());
        }

        // eslint-disable-next-line
    }, []);

    return {
        project,
    };
};
