import React from "react";
import { RecipesProps } from "../../api/recipesList";
import RecipeFormCreator from "../RecipeFormCreator/RecipeFormCreator";
import { frontMessages } from "../../utils/messages";
import styles from "./RecipeEditor.module.scss";
import { IconType } from "../Icons/Icons";
import HeaderTitleWithIcon from "../HeaderTitleWithIcon/HeaderTitleWithIcon";

interface RecipeEditorProps {
    selectedRecipe: RecipesProps;
    isWorkingRecipe: boolean;
    onPrevStep: any;
}

const RecipeEditor: React.FC<RecipeEditorProps> = ({ selectedRecipe, onPrevStep, isWorkingRecipe }) => {
    return (
        <div className={styles.width}>
            <div className={styles.header}>
                <button className="btn btn--black" onClick={onPrevStep}>
                    {frontMessages.button.backToList}
                </button>
                <div className={styles.headerTitle}>
                    <HeaderTitleWithIcon title={"EDYCJA"} iconType={IconType.EDIT} />
                </div>
            </div>
            <RecipeFormCreator
                initialValues={selectedRecipe}
                isRecipeEditor={!isWorkingRecipe}
                isRecipeCreator={false}
                isWorkingRecipe={isWorkingRecipe}
                isProjectRecipe={false}
            />
        </div>
    );
};

export default RecipeEditor;
