import { put, call } from "redux-saga/effects";
import Api from "../../api/auth";
import { authorize, AuthResponse, createNewUser, verifyMe } from "./auth.actions";
import { removeAccessToken, setAccessToken } from "../../utils/utils";
import { notification, notificationsMessages } from "../../components/Notification/Notification";
import { fetchUserList } from "../userManagement/userManagement.actions";

export function* authorizationSaga(action: ReturnType<typeof authorize.request>): Generator {
    try {
        const response = yield call(Api.authorize, action.payload);
        const typedResponse = response as AuthResponse;
        setAccessToken(typedResponse.accessToken);
        notification(notificationsMessages.loginSuccess, true);
        yield put(authorize.success(typedResponse));
    } catch (err: any) {
        notification(err.parsedBody.message || notificationsMessages.loginError, err.success || false);
        yield put(authorize.failure(err));
    }
}

export function* verifyMeSaga(action: ReturnType<typeof verifyMe.request>): Generator {
    try {
        const response = yield call(Api.verifyMe, action.payload);
        const typedResponse = response as any;
        yield put(verifyMe.success(typedResponse));
    } catch (err: any) {
        notification("Sesja wygasła", false);
        removeAccessToken();
        yield put(verifyMe.failure(err));
    }
}

export function* registerUserSaga(action: ReturnType<typeof createNewUser.request>): Generator {
    try {
        const response = yield call(Api.registerUser, action.payload);
        const typedResponse = response as any;
        yield put(createNewUser.success(typedResponse));
        notification("Pomyślnie dodano użytkownika!", true);
        yield put(fetchUserList.request());
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(verifyMe.failure(response));
    }
}
