import { verifyMe } from "../auth/auth.actions";
import { call, put } from "redux-saga/effects";
import Api, { IngredientsListResponse, UpdateIngredientNameResponse } from "../../api/ingredientsList";
import { notification } from "components/Notification/Notification";
import {
    deleteIngredient,
    fetchIngredientsList,
    updateIngredientCategory,
    updateIngredientDensity,
    updateIngredientName,
    updateIngredientPrice,
} from "./ingredienstList.action";
import { fetchRecipesList } from "store/RecipesList/RecipesList.actions";
import { fetchWorkingRecipesList } from "store/workingRecipe/workingRecipe.actions";
import { fetchRecipesHistory } from "store/recipesHistory/recipesHistory.actions";

export function* fetchIngredientsListSaga(action: ReturnType<typeof fetchIngredientsList.request>): Generator {
    try {
        const response = yield call(Api.fetchIngredientsList, action.payload);
        const typedResponse = response as IngredientsListResponse;
        yield put(fetchIngredientsList.success(typedResponse));
    } catch (err: any) {
        notification(err.message, false);
        yield put(verifyMe.failure(err));
    }
}

export function* updateIngredientNameSaga(action: ReturnType<typeof updateIngredientName.request>): Generator {
    try {
        const response = yield call(Api.updateIngredientName, action.payload);
        const typedResponse = response as UpdateIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateIngredientName.success(typedResponse));
        yield put(fetchIngredientsList.request());
        yield put(fetchRecipesList.request());
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchRecipesHistory.request());
    } catch (err: any) {
        notification(err.message, false);
        yield put(updateIngredientName.failure(err));
    }
}

export function* updateIngredientPriceSaga(action: ReturnType<typeof updateIngredientPrice.request>): Generator {
    try {
        const response = yield call(Api.updateIngredientPrice, action.payload);
        const typedResponse = response as UpdateIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateIngredientPrice.success(typedResponse));
        yield put(fetchIngredientsList.request());
        yield put(fetchRecipesList.request());
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchRecipesHistory.request());
    } catch (err: any) {
        notification(err.message, false);
        yield put(updateIngredientPrice.failure(err));
    }
}

export function* updateIngredientDensitySaga(action: ReturnType<typeof updateIngredientDensity.request>): Generator {
    try {
        const response = yield call(Api.updateIngredientDensity, action.payload);
        const typedResponse = response as UpdateIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateIngredientDensity.success(typedResponse));
        yield put(fetchIngredientsList.request());
        yield put(fetchRecipesList.request());
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchRecipesHistory.request());
    } catch (err: any) {
        notification(err.message, false);
        yield put(updateIngredientDensity.failure(err));
    }
}

export function* deleteIngredientSaga(action: ReturnType<typeof deleteIngredient.request>): Generator {
    try {
        const response = yield call(Api.deleteIngredient, action.payload);
        const typedResponse = response as UpdateIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(deleteIngredient.success(typedResponse));
        yield put(fetchIngredientsList.request());
    } catch (err: any) {
        notification(err.message, false);
        yield put(deleteIngredient.failure(err));
    }
}

export function* updateIngredientCategorySaga(action: ReturnType<typeof updateIngredientCategory.request>): Generator {
    try {
        const response = yield call(Api.updateIngredientCategory, action.payload);
        const typedResponse = response as UpdateIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateIngredientCategory.success(typedResponse));
        yield put(fetchIngredientsList.request());
    } catch (err: any) {
        notification(err.message, false);
        yield put(updateIngredientCategory.failure(err));
    }
}
