import React from "react";
import styles from "./UserList.module.scss";
import { UserManagementProps } from "api/userManagement";
import CheckboxComponent from "../CheckboxCompomnent/ChekboxComponent";
import { IconType } from "../Icons/Icons";
import HeaderTitleWithIcon from "../HeaderTitleWithIcon/HeaderTitleWithIcon";
import { frontMessages } from "../../utils/messages";

interface UserListProps {
    users: UserManagementProps[];
    handleEditUser(id: number): () => void;
}

const UserList: React.FC<UserListProps> = ({ users, handleEditUser }) => {
    return (
        <>
            <div className={styles.usersTopSection}>
                <div className={styles.usersTitle}>
                    <HeaderTitleWithIcon title={"UŻYTKOWNICY"} iconType={IconType.USER} />
                </div>
                <div className={styles.usersTop}>
                    <span>Ilość użytkowników: </span>
                    <span className="bold"> {users.length}</span>
                </div>
            </div>
            <div className={styles.usersHeader}>
                <span className={styles.usersIndex}>lp. </span>
                <span className={styles.usersUser}> Użytkownik</span>
                <span className={styles.usersEmail}>Email</span>
                <span className={styles.usersRoles}>Uprawnienia</span>
                <span className={styles.usersCreated}>Utworzono</span>
                <span className={styles.usersBlocked}>Zablokowany</span>
                <span className={styles.usersEdit}>Edytuj</span>
            </div>
            <ul className={styles.users}>
                {users.map((user, index) => {
                    return (
                        <li key={index} className={styles.usersList}>
                            <span className={styles.usersIndex}>{index + 1}.</span>
                            <span className={styles.usersUser}>{user.username}</span>
                            <span className={styles.usersEmail}>{user.email}</span>
                            <div className={styles.usersRoles}>
                                {user.roles.map((item, index) => {
                                    return (
                                        <div className={styles.usersListRolesWrapper} key={index}>
                                            <CheckboxComponent item={item} />
                                        </div>
                                    );
                                })}
                            </div>
                            <span className={styles.usersCreated}>{user.createdAt.slice(0, 10)}</span>
                            <span className={styles.usersBlocked}>{user.isBlocked ? "TAK" : "NIE"}</span>
                            <span className={styles.usersEdit}>
                                <button onClick={handleEditUser(user.id)}>
                                    <span>{frontMessages.button.edit}</span>
                                </button>
                            </span>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default UserList;
