export enum UserPrivileges {
    VIEWER = "viewer",
    PRICE = "price",
    EDITOR = "editor",
    OWNER = "owner",
    ADMIN = "admin",
}

export const hasKeyTrueValue = (value: boolean, key: string) => (value ? key : "");

export const prepareUserRoles = (values: any) =>
    [
        hasKeyTrueValue(values.viewer, UserPrivileges.VIEWER),
        hasKeyTrueValue(values.editor, UserPrivileges.EDITOR),
        hasKeyTrueValue(values.price, UserPrivileges.PRICE),
        hasKeyTrueValue(values.owner, UserPrivileges.OWNER),
    ].filter((item) => item);
