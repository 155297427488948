import styles from "./RecipeFormButtons.module.scss";
import { RecipeActionType } from "../../../utils/forms.helper";
import { frontMessages } from "../../../utils/messages";
import React from "react";
import { useSelector } from "react-redux";
import { getUserPrivileges } from "../../../store/auth/auth.selector";
import { useLocation } from "react-router-dom";
import { AddRecipeSource } from "../../AddRecipeButton/AddRecipeButton";
import Icons, { IconType } from "../../Icons/Icons";

interface RecipeFormButtonsProps {
    isRecipeEditor: boolean;
    isRecipeCreator: boolean;
    isWorkingRecipe: boolean;
    openModal(): void;
    setRecipeSaveActionType(actionType: RecipeActionType): void;
}

interface StateProps {
    addRecipeSource: AddRecipeSource;
}

const RecipeFormButtons: React.FC<RecipeFormButtonsProps> = ({
    isRecipeEditor,
    openModal,
    setRecipeSaveActionType,
    isRecipeCreator,
    isWorkingRecipe,
}) => {
    const { hasEditorPrivileges } = useSelector(getUserPrivileges);
    const { state, pathname } = useLocation<StateProps>();
    const isWorkingRecipeAddSource =
        state?.addRecipeSource === AddRecipeSource.WORKING || pathname.includes("/receptura-robocza/");
    const isProductionRecipeAddSource =
        state?.addRecipeSource === AddRecipeSource.PRODUCTION || pathname.includes("/receptura/");

    return (
        <div className={styles.wrapperButton}>
            {isRecipeEditor && (
                <div className={styles.buttons}>
                    <button
                        type="submit"
                        className="btn btn--default"
                        onMouseDown={() => setRecipeSaveActionType(RecipeActionType.CREATE_WORKING)}>
                        <span>{frontMessages.button.saveWorkingRecipe}</span>
                        <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                    </button>
                    <button
                        className="btn btn--green"
                        type="button"
                        onMouseDown={() => {
                            openModal();
                            setRecipeSaveActionType(RecipeActionType.EDIT);
                        }}>
                        <span>{frontMessages.button.save}</span>
                        <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                    </button>
                    <button
                        className="btn btn--red"
                        type="submit"
                        onMouseDown={() => {
                            openModal();
                            setRecipeSaveActionType(RecipeActionType.DELETE);
                        }}>
                        <span>{frontMessages.button.delete}</span>
                        <Icons iconType={IconType.TRASH} styles={"icon-button"} />
                    </button>
                </div>
            )}
            {isRecipeCreator && (
                <>
                    {isWorkingRecipeAddSource && (
                        <button
                            type="submit"
                            className="btn btn--default"
                            onMouseDown={() => setRecipeSaveActionType(RecipeActionType.CREATE_WORKING)}>
                            <span>{frontMessages.button.addWorkingRecipe}</span>
                            <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                        </button>
                    )}
                    {hasEditorPrivileges && isProductionRecipeAddSource && (
                        <button
                            type="submit"
                            className="btn btn--default"
                            onMouseDown={() => setRecipeSaveActionType(RecipeActionType.CREATE)}>
                            <span>{frontMessages.button.addRecipe}</span>
                            <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                        </button>
                    )}
                </>
            )}
            {isWorkingRecipe && (
                <>
                    <button
                        type="submit"
                        className="btn btn--default btn--width-large"
                        onMouseDown={() => setRecipeSaveActionType(RecipeActionType.CREATE_WORKING)}>
                        <span>{frontMessages.button.saveAnotherWorkingRecipe}</span>
                        <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                    </button>
                    <button
                        type="submit"
                        className="btn btn--default btn--green btn--width-large"
                        onMouseDown={() => setRecipeSaveActionType(RecipeActionType.CREATE)}>
                        <span>{frontMessages.button.saveRecipeFromWorking}</span>
                        <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                    </button>
                    <button
                        className="btn btn--default btn--width-large"
                        type="submit"
                        onMouseDown={() => {
                            openModal();
                            setRecipeSaveActionType(RecipeActionType.EDIT_WORKING);
                        }}>
                        <span>{frontMessages.button.saveEditWorkingRecipe}</span>
                        <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                    </button>
                    <button
                        type="submit"
                        className="btn btn--default btn--red btn--width-large"
                        onMouseDown={() => {
                            openModal();
                            setRecipeSaveActionType(RecipeActionType.DELETE_WORKING);
                        }}>
                        <span>{frontMessages.button.deleteWorking}</span>
                        <Icons iconType={IconType.TRASH} styles={"icon-button"} />
                    </button>
                </>
            )}
        </div>
    );
};

export default RecipeFormButtons;
