import React from "react";
import Icons, { IconType } from "../../Icons/Icons";
import styles from "./ProjectsHeader.module.scss";
import { frontMessages } from "../../../utils/messages";

interface ProjectsHeaderProps {
    handleOpenNewProject: any;
}

const ProjectsHeader: React.FC<ProjectsHeaderProps> = ({ handleOpenNewProject }) => {
    return (
        <div className={styles.header}>
            <button className={"btn btn--default btn--width-medium"} onClick={handleOpenNewProject}>
                {frontMessages.button.addProject}
                <Icons iconType={IconType.PLUS} styles={"icon-button"} />
            </button>
        </div>
    );
};

export default ProjectsHeader;
