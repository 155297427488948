export enum FieldType {
    TEXT = "text",
    PASSWORD = "password",
    NUMBER = "number",
    SELECT = "select",
    CHECKBOX = "checkbox",
    TEXT_AREA = "textarea",
}

export enum FieldTitle {
    EMAIL = "email",
    USERNAME = "username",
    PASSWORD = "password",
    REGISTER_PASSWORD = "registerPassword",
    VIEWER_PRIVILEGE = "viewer",
    PRICE_PRIVILEGE = "price",
    EDITOR_PRIVILEGE = "editor",
    OWNER_PRIVILEGE = "owner",
    RECIPE_NAME = "recipeName",
    RECIPE_NUMBER = "recipeNumber",
    CATEGORY = "category",
    RECIPE_SUM = "recipeSum",
    NEW_NAME = "newName",
    PRICE = "price",
    DENSITY = "density",
    CATEGORY_NAME = "categoryName",
    NEW_CATEGORY_NAME = "newCategoryName",
    SAMPLE_NAME = "sampleName",
    SUPPLIER = "supplier",
    PLACEMENT = "placement",
    DESCRIPTION = "description",
    DEFAULT_VALUE = "defaultValue",
    RECIPE_DENSITY = "recipeDensity",
    IS_BLOCKED = "isBlocked",
    INGREDIENT_CATEGORY = "ingredientCategory",
}

export enum RecipeActionType {
    CREATE = "stworzono recepturę",
    EDIT = "edytowano recepturę",
    EDIT_WORKING = "edytowano recepturę roboczą",
    PRINT = "wydrukowano recepturę",
    DELETE = "usunięto recepturę",
    CREATE_WORKING = "stworzono recepturę robocza",
    DELETE_WORKING = "usunięto recepturę roboczą",
    NONE = "",
}
