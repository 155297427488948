import React from "react";
// import SVGInline from "react-svg-inline";
import recipeLogo from "../../assets/images/recipeLogo.svg";
import minus from "../../assets/images/minus.svg";
import plus from "../../assets/images/plus.svg";
import instructions from "../../assets/images/instructions.svg";
import trash from "../../assets/images/trash.svg";
import user from "../../assets/images/user.svg";
import arrow from "../../assets/images/arrow.svg";
import sort from "../../assets/images/sort.png";
import search from "../../assets/images/search.svg";
import list from "../../assets/images/list.svg";
import history from "../../assets/images/history.svg";
import working from "../../assets/images/cogwheel.svg";
import ingredient from "../../assets/images/chemical.svg";
import category from "../../assets/images/categories.svg";
import edit from "../../assets/images/edit.svg";
import view from "../../assets/images/view.svg";
import printer from "../../assets/images/printer.svg";
import close from "../../assets/images/icons-close.svg";

interface IconsProps {
    iconType: IconType;
    styles?: string;
}

export enum IconType {
    RECIPE_LOGO = "recipeLogo",
    PLUS = "plus",
    MINUS = "minus",
    INSTRUCTIONS = "instructions",
    TRASH = "trash",
    USER = "user",
    ARROW = "arrow",
    SORT = "sort",
    SEARCH = "search",
    LIST = "list",
    HISTORY = "history",
    INGREDIENT = "ingredient",
    WORKING = "working",
    CATEGORY = "category",
    EDIT = "edit",
    VIEW = "view",
    PRINTER = "printer",
    CLOSE = "close",
}

const setIconSrc = (type: IconType) => {
    const iconStrategy = {
        [IconType.RECIPE_LOGO]: () => recipeLogo,
        [IconType.MINUS]: () => minus,
        [IconType.PLUS]: () => plus,
        [IconType.INSTRUCTIONS]: () => instructions,
        [IconType.TRASH]: () => trash,
        [IconType.USER]: () => user,
        [IconType.ARROW]: () => arrow,
        [IconType.SORT]: () => sort,
        [IconType.SEARCH]: () => search,
        [IconType.LIST]: () => list,
        [IconType.WORKING]: () => working,
        [IconType.HISTORY]: () => history,
        [IconType.INGREDIENT]: () => ingredient,
        [IconType.CATEGORY]: () => category,
        [IconType.VIEW]: () => view,
        [IconType.EDIT]: () => edit,
        [IconType.PRINTER]: () => printer,
        [IconType.CLOSE]: () => close,
    };

    if (iconStrategy[type]) {
        return iconStrategy[type]();
    }

    return "";
};

const Icons: React.FC<IconsProps> = ({ iconType, styles }) => {
    return (
        <>
            <img className={styles} src={setIconSrc(iconType)} alt="logo" />
        </>
    );
};

export default Icons;
