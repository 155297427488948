import { call, put } from "redux-saga/effects";
import Api from "../../api/projects";
import { notification } from "../../components/Notification/Notification";
import { addNewProject, deleteProject, editProject, fetchProjectList } from "./projects.actions";
import { ApiResponse } from "../recipeOperations/recipeOperations.actions";

export function* addNewProjectSaga(action: ReturnType<typeof addNewProject.request>): Generator {
    try {
        const response = yield call(Api.addNewProject, action.payload);
        const typedResponse = response as ApiResponse;
        notification(typedResponse.message, typedResponse.success);
        yield put(addNewProject.success(typedResponse));
        yield put(fetchProjectList.request());
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(addNewProject.failure(response));
    }
}

export function* editProjectSaga(action: ReturnType<typeof editProject.request>): Generator {
    try {
        const response = yield call(Api.editProject, action.payload);
        const typedResponse = response as ApiResponse;
        notification(typedResponse.message, typedResponse.success);
        yield put(editProject.success());
        yield put(fetchProjectList.request());
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(editProject.failure(response));
    }
}

export function* deleteProjectSaga(action: ReturnType<typeof deleteProject.request>): Generator {
    try {
        const response = yield call(Api.deleteProject, action.payload);
        const typedResponse = response as ApiResponse;
        notification(typedResponse.message, typedResponse.success);
        yield put(deleteProject.success());
        yield put(fetchProjectList.request());
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(editProject.failure(response));
    }
}

export function* fetchProjectsSaga(action: ReturnType<typeof fetchProjectList.request>): Generator {
    try {
        const response = yield call(Api.getProjectList, action.payload);
        const typedResponse = response as any;
        notification(typedResponse.message, typedResponse.success);
        yield put(fetchProjectList.success(typedResponse));
    } catch (response: any) {
        notification(response.parsedBody, false);
        yield put(addNewProject.failure(response));
    }
}
