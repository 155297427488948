import React from "react";
import styles from "./RecipeInstruction.module.scss";
import Icons, { IconType } from "../../Icons/Icons";
import { NewRecipeState } from "../RecipeFormCreator";
interface RecipeInstructionProps {
    setInputList: React.Dispatch<React.SetStateAction<NewRecipeState[]>>;
    inputList: NewRecipeState[];
    item: NewRecipeState;
    index: number;
    fieldName: string;
    registerForm: any;
    handleChangeIngredients(index: number, event: any): void;
}
const RecipeInstruction: React.FC<RecipeInstructionProps> = ({
    item,
    fieldName,
    registerForm,
    index,
    inputList,
    setInputList,
    handleChangeIngredients,
}) => {
    const isGap = item.ingredientDescription === "GAP";
    const removeInstruction = (index: number) => () => {
        const ingredientList = [...inputList];
        ingredientList[index].ingredientDescription = null;
        setInputList(ingredientList);
    };

    const addInstruction = (index: number) => () => {
        const ingredientList = [...inputList];
        ingredientList[index].ingredientDescription = "";
        setInputList(ingredientList);
    };

    const closeInstruction = (index: number) => () => {
        const ingredientList = [...inputList];
        ingredientList[index].ingredientDescription = "GAP";
        setInputList(ingredientList);
    };

    if (item.ingredientDescription === null) {
        return null;
    }

    return (
        <div className={styles.contentTextArea}>
            <textarea
                className={isGap ? styles.contentHide : ""}
                name={`${fieldName}.description`}
                placeholder="Dodaj instrukcję"
                value={item.ingredientDescription || ""}
                ref={registerForm}
                onChange={(event) => handleChangeIngredients(index, event)}
            />
            <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperGap}>PRZERWA</div>
                {isGap && (
                    <div onMouseDown={addInstruction(index)} className={styles.contentIcon}>
                        <Icons iconType={IconType.INSTRUCTIONS} styles={styles.contentIconContent} />
                    </div>
                )}
                {!isGap && (
                    <div onMouseDown={closeInstruction(index)} className={styles.contentIcon}>
                        <Icons iconType={IconType.MINUS} styles={styles.contentIconContent} />
                    </div>
                )}
                <div onMouseDown={removeInstruction(index)} className={styles.contentIcon}>
                    <Icons iconType={IconType.TRASH} styles={styles.contentIconContent} />
                </div>
            </div>
        </div>
    );
};

export default RecipeInstruction;
