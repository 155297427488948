import React from "react";
import Icons, { IconType } from "../Icons/Icons";
import styles from "./HeaderTitleWithIcon.module.scss";

interface HeaderTitleWithIconProps {
    iconType: IconType;
    title: string;
}

const HeaderTitleWithIcon: React.FC<HeaderTitleWithIconProps> = ({ iconType, title }) => {
    return (
        <>
            <div className={styles.headerTitle}>
                <div className={styles.headerTitleIcon}>
                    <Icons iconType={iconType} />
                </div>
                <span className={`sectionTitle ${styles.headerTitle}`}>{title}</span>
            </div>
        </>
    );
};

export default HeaderTitleWithIcon;
