import { RecipesProps } from "../../api/recipesList";
import { RecipeSource } from "../RecipeView/RecipeView";

const generateRecipePathname = (recipeSource: RecipeSource, isWorkingHistory: boolean) => {
    if (recipeSource === RecipeSource.HISTORY_LIST) {
        return isWorkingHistory ? "receptura-robocza-historia" : "receptura-historia";
    }

    if (recipeSource === RecipeSource.WORKING_HISTORY_LIST) {
        return "receptura-robocza-historia";
    }

    if (recipeSource === RecipeSource.WORKING_LIST) {
        return "receptura-robocza";
    }

    return "receptura";
};

export const generateLinkToRecipeView = (
    recipe: RecipesProps,
    recipeSource: RecipeSource,
    isWorkingHistory: boolean
) => {
    const recipePathname = generateRecipePathname(recipeSource, isWorkingHistory);
    return {
        pathname: `/${recipePathname}/${recipe.recipeNumber}`,
        state: {
            recipeId: recipe.id,
            recipeSource: recipeSource,
        },
    };
};
