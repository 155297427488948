import { createAsyncAction } from "typesafe-actions";
import {
    DeleteWorkingIngredientRequest,
    UpdateWorkingIngredientCategoryRequest,
    UpdateWorkingIngredientDensityRequest,
    UpdateWorkingIngredientNameRequest,
    UpdateWorkingIngredientNameResponse,
    UpdateWorkingIngredientPriceRequest,
    WorkingIngredientsListResponse,
} from "../../api/workingIngredientList";

export const fetchWorkingIngredientsList = createAsyncAction(
    "FETCH_WORKING_INGREDIENT_LIST_REQUEST",
    "FETCH_WORKING_INGREDIENT_LIST_SUCCESS",
    "FETCH_WORKING_INGREDIENT_LIST_FAILURE"
)<void, WorkingIngredientsListResponse, Error>();

export const updateWorkingIngredientName = createAsyncAction(
    "UPDATE_WORKING_INGREDIENT_NAME_REQUEST",
    "UPDATE_WORKING_INGREDIENT_NAME_SUCCESS",
    "UPDATE_WORKING_INGREDIENT_NAME_FAILURE"
)<UpdateWorkingIngredientNameRequest, UpdateWorkingIngredientNameResponse, Error>();

export const updateWorkingIngredientCategory = createAsyncAction(
    "UPDATE_WORKING_INGREDIENT_CATEGORY_REQUEST",
    "UPDATE_WORKING_INGREDIENT_CATEGORY_SUCCESS",
    "UPDATE_WORKING_INGREDIENT_CATEGORY_FAILURE"
)<UpdateWorkingIngredientCategoryRequest, UpdateWorkingIngredientNameResponse, Error>();

export const updateWorkingIngredientPrice = createAsyncAction(
    "UPDATE_WORKING_INGREDIENT_PRICE_REQUEST",
    "UPDATE_WORKING_INGREDIENT_PRICE_SUCCESS",
    "UPDATE_WORKING_INGREDIENT_PRICE_FAILURE"
)<UpdateWorkingIngredientPriceRequest, UpdateWorkingIngredientNameResponse, Error>();

export const updateWorkingIngredientDensity = createAsyncAction(
    "UPDATE_WORKING_INGREDIENT_DENSITY_REQUEST",
    "UPDATE_WORKING_INGREDIENT_DENSITY_SUCCESS",
    "UPDATE_WORKING_INGREDIENT_DENSITY_FAILURE"
)<UpdateWorkingIngredientDensityRequest, UpdateWorkingIngredientNameResponse, Error>();

export const deleteWorkingIngredient = createAsyncAction(
    "DELETE_WORKING_INGREDIENT_REQUEST",
    "DELETE_WORKING_INGREDIENT_SUCCESS",
    "DELETE_WORKING_INGREDIENT_FAILURE"
)<DeleteWorkingIngredientRequest, UpdateWorkingIngredientNameResponse, Error>();
