import React from "react";
import { ProjectFieldTitle } from "../../projects.helper";
import { projectsMessages } from "../../projectsMessages";
import { FieldType } from "../../../../utils/forms.helper";
import styles from "../ProjectCreatorDefaultInputs/ProjectCreatorDefaultInputs.module.scss";
import Icons, { IconType } from "../../../Icons/Icons";
import { useSelect } from "../../../../hooks/useSelect";
import { useSelector } from "react-redux";
import { getWorkingRecipesListState } from "../../../../store/workingRecipe/workingRecipe.selector";
import RecipeFormCreator from "../../../RecipeFormCreator/RecipeFormCreator";
import { useHistory } from "react-router-dom";
import { AddRecipeSource } from "../../../AddRecipeButton/AddRecipeButton";
import { ProjectProps } from "../../../../api/projects";
interface AddRecipeButtonProps {
    initialValues?: ProjectProps;
    isOpenNewRecipe: boolean;
    setOpenNewRecipe(isOpen: boolean): void;
    register: any;
}

const ProjectButtonRecipeAdd: React.FC<AddRecipeButtonProps> = ({
    register,
    initialValues,
    isOpenNewRecipe,
    setOpenNewRecipe,
}) => {
    const [value, setValue] = React.useState("");
    const [recipes, setRecipe] = React.useState<string[]>([]);
    const { push } = useHistory();

    const { workingRecipes } = useSelector(getWorkingRecipesListState);

    const remappedRecipe = workingRecipes.map((item) => {
        return {
            value: item.recipeNumber,
            label: item.recipeNumber,
        };
    });

    const { selectedValue, selectComponent, setSelectedValue, setSelectedLabel } = useSelect(
        remappedRecipe,
        undefined,
        undefined,
        true
    );

    const submitValue = (submittedValue: string) => {
        const arrayOfRecipes = recipes.concat([submittedValue]);
        setRecipe(arrayOfRecipes);
        setValue("");
    };

    const onButtonClick = () => {
        submitValue(value);
        setSelectedValue("Wybierz z listy");
        setSelectedLabel("Wybierz z listy");
    };

    const removeRecipe = (selectedRecipe: string) => () => {
        const newRecipeList = recipes.filter((item) => item !== selectedRecipe);
        setRecipe(newRecipeList);
    };

    const handleOpenRecipe = () => {
        push({
            state: {
                addRecipeSource: AddRecipeSource.WORKING,
            },
        });
        setOpenNewRecipe(true);
    };

    const handleCloseNewRecipe = (addedRecipeName: string) => {
        setOpenNewRecipe(false);
        submitValue(addedRecipeName);
    };

    React.useEffect(() => {
        setValue(selectedValue);
    }, [selectedValue]);

    React.useEffect(() => {
        const initialRecipes = initialValues?.recipes;

        if (initialRecipes) {
            const array = initialRecipes.split(",");
            setValue(initialRecipes);
            setRecipe(array);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.buttonWrapper}>
                    <div className={styles.buttonWrapperLabel}>{"Receptury"}</div>
                    <input
                        style={{ display: "none" }}
                        value={recipes?.toString()}
                        ref={register()}
                        onChange={(e) => setValue(e.target.value)}
                        name={ProjectFieldTitle.RECIPES}
                        placeholder={projectsMessages.label.recipes}
                        type={FieldType.TEXT}
                    />
                    <div className={styles.buttonWrapperList}>{selectComponent}</div>
                    <button
                        onClick={onButtonClick}
                        type={"button"}
                        className={styles.buttonWrapperAdd}
                        disabled={!selectedValue.length || selectedValue === "Wybierz z listy"}>
                        Podepnij Recepture
                    </button>
                    <button
                        type={"button"}
                        className={`btn btn--default btn--height-medium ${styles.buttonWrapperAdd}`}
                        onClick={handleOpenRecipe}>
                        <div>Dodaj receptuę</div>
                        <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                    </button>
                </div>
                <div className={styles.recipe}>
                    <div>Zalinkowane receptury:</div>
                    <div className={styles.recipeList}>
                        {recipes.map((item, index) => {
                            return (
                                <span onClick={removeRecipe(item)} key={index} className={styles.recipeListLabel}>
                                    {item}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
            {isOpenNewRecipe && (
                <RecipeFormCreator
                    isRecipeEditor={false}
                    isRecipeCreator={true}
                    isWorkingRecipe={false}
                    isProjectRecipe={true}
                    onClose={handleCloseNewRecipe}
                />
            )}
        </div>
    );
};
export default ProjectButtonRecipeAdd;
