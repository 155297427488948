import { ActionType, createReducer } from "typesafe-actions";
import * as actions from ".//IngredientsHistory.actions";
import { fetchIngredientsHistory } from ".//IngredientsHistory.actions";
import { IngredientsHistory } from "api/IngredientsHistory";
import { compareListItems } from "utils/utils";

export type IngredientsHistoryAction = ActionType<typeof actions>;

export type IngredientsHistoryState = Readonly<{
    ingredientsHistory: IngredientsHistory[];
    isLoading: boolean;
}>;

export const ingredientsListInitialState: IngredientsHistoryState = {
    isLoading: false,
    ingredientsHistory: [],
};

export const ingredientsHistoryReducer = createReducer<IngredientsHistoryState, IngredientsHistoryAction>(
    ingredientsListInitialState
)
    .handleAction(fetchIngredientsHistory.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchIngredientsHistory.success, (state, action) => {
        return {
            ...state,
            ingredientsHistory: action.payload.ingredientsHistory.sort((firstItem, secondItem) =>
                compareListItems(secondItem.createdAt, firstItem.createdAt)
            ),
            isLoading: false,
        };
    })
    .handleAction(fetchIngredientsHistory.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
