import React from "react";
import styles from "./ProjectView.module.scss";
import { frontMessages } from "../../../utils/messages";
import HeaderTitleWithIcon from "../../HeaderTitleWithIcon/HeaderTitleWithIcon";
import Icons, { IconType } from "../../Icons/Icons";
import { ProjectProps } from "../../../api/projects";
import { projectsMessages } from "../projectsMessages";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { getRecipesListState } from "../../../store/RecipesList/RecipesList.selector";
import { Link, useHistory } from "react-router-dom";
import ProjectViewRecipeFinder from "./ProjectViewRecipeFInder/ProjectViewRecipeFinder";

interface ProjectViewProps {
    project?: ProjectProps;
    goToEditView(): void;
}

const ProjectView: React.FC<ProjectViewProps> = ({ project, goToEditView }) => {
    const componentRef = React.useRef() as any;
    const { recipesList } = useSelector(getRecipesListState);
    const hasProductionRecipe = recipesList.some((item) => item.recipeNumber === project?.productionNumber);
    const history = useHistory();
    const handleGoBack = () => history.push("/projekty");

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
                <button onClick={handleGoBack} className="btn btn--black">
                    {frontMessages.button.backToList}
                </button>
                <div className={styles.wrapperTitleHeader}>
                    <HeaderTitleWithIcon title={"PODGLĄD PROJEKTU"} iconType={IconType.VIEW} />
                </div>
            </div>
            {project && (
                <div className={styles.wrapper} ref={componentRef}>
                    <div className={styles.header}>
                        <div className={styles.headerDescription}>
                            <div className={styles.headerDescriptionNumber}>
                                <div className={styles.headerDescriptionTitle}>Numer:</div>
                                <div>{project.projectId}</div>
                            </div>
                            <div className={styles.headerDescriptionProject}>
                                <div className={styles.headerDescriptionTitle}>PROJEKT:</div>
                                <div className={styles.headerDescriptionTextarea}>{project.projectDescription}</div>
                            </div>
                        </div>
                        <div className={styles.headerTypeWrapper}>
                            <div className={styles.descSelectsWrapperHeader}>Typ:</div>
                            <div className={styles.descSelectsWrapperSelect}>{project.type}</div>
                        </div>
                    </div>
                    <div className={styles.desc}>
                        <div className={styles.descNum}>
                            <div className={styles.descNumWrapper}>
                                <div className={styles.descNumWrapperHeader}>Numer Produkcyjny</div>
                                {hasProductionRecipe ? (
                                    <Link to={`/receptura/${project.productionNumber}`}>
                                        <div className={`${styles.descNumWrapperInputs} ${styles.descNumWrapperLink}`}>
                                            {project.productionNumber}
                                        </div>
                                    </Link>
                                ) : (
                                    <div className={styles.descNumWrapperInputs}>{project.productionNumber}</div>
                                )}
                            </div>
                            <div className={styles.descNumWrapper}>
                                <div className={styles.descNumWrapperHeader}>Produkt konkurencji</div>
                                <div className={styles.descNumWrapperInputs}>{project.toWork}</div>
                            </div>
                            <div className={styles.descNumWrapper}>
                                <div className={styles.descNumWrapperHeader}>Numer wewnętrzny</div>
                                <div className={styles.descNumWrapperInputs}>{project.workingNumber}</div>
                            </div>
                        </div>
                        <div className={styles.descProps}>
                            <div className={styles.descPropsWrapper}>
                                <div className={styles.descPropsWrapperHeader}>Uwagi / Cechy:</div>
                                <div className={styles.descPropsWrapperTextarea}>{project.properties}</div>
                            </div>
                            <div className={styles.descPropsWrapper}>
                                <div className={styles.descPropsWrapperHeader}>Skład / Surowce:</div>
                                <div className={styles.descPropsWrapperTextarea}>{project.resources}</div>
                            </div>
                        </div>
                        <div className={styles.descSelects}>
                            <div className={styles.descSelectsWrapper}>
                                <div className={styles.descSelectsWrapperHeader}>STATUS:</div>
                                <div className={styles.descSelectsWrapperSelect}>{project.status}</div>
                            </div>
                            <div className={styles.descSelectsWrapper}>
                                <div className={styles.descSelectsWrapperHeader}>TERMIN REALIZACJI:</div>
                                <div className={`${styles.descSelectsWrapperSelect} ${styles.descSelectsWrapperDate}`}>
                                    {project.realizationDate}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.defaultWrapper}>
                        <div className={styles.defaultInputs}>
                            <div className={styles.defaultInputsValue}>Ciała stałe</div>
                            <div className={styles.defaultInputsLabel}>{project.solids}</div>
                        </div>
                        <div className={styles.defaultInputs}>
                            <div className={styles.defaultInputsValue}>Połysk</div>
                            <div className={styles.defaultInputsLabel}>{project.gloss}</div>
                        </div>
                        <div className={styles.defaultInputs}>
                            <div className={styles.defaultInputsValue}>Lepkość</div>
                            <div className={styles.defaultInputsLabel}>{project.viscosity}</div>
                        </div>
                        <div className={styles.defaultInputs}>
                            <div className={styles.defaultInputsValue}>Natrysk</div>
                            <div className={styles.defaultInputsLabel}>{project.spray}</div>
                        </div>
                        <div className={styles.defaultInputs}>
                            <div className={styles.defaultInputsValue}>gęstość</div>
                            <div className={styles.defaultInputsLabel}>{project.density}</div>
                        </div>
                    </div>
                    <div className={styles.wrapperTitleButton}>
                        <ReactToPrint
                            trigger={() => (
                                <button type="button" className="btn btn--default">
                                    <span>{projectsMessages.buttons.printProject}</span>
                                    <Icons iconType={IconType.PRINTER} styles={"icon-button"} />
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                        <button type="button" className="btn btn--default" onClick={goToEditView}>
                            <span>{projectsMessages.buttons.editProject}</span>
                            <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                        </button>
                    </div>
                    <ProjectViewRecipeFinder recipes={project.recipes} projectId={project.projectId} />
                </div>
            )}
        </div>
    );
};

export default ProjectView;
