import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchIngredientsList } from "store/IngredientList/ingredienstList.action";
import { getIngredientsListState } from "store/IngredientList/IngredientsList.selector";
import styles from "components/Ingredients/Ingredients.module.scss";
import IngredientsList from "components/IngredientsList/IngedientsList";
import { IngredientsSource } from "../WorkingIngredientList/WorkinIngredientList";

const Ingredients: React.FC = () => {
    const dispatch = useDispatch();
    const { ingredients } = useSelector(getIngredientsListState);

    useEffect(() => {
        if (!ingredients.length) {
            dispatch(fetchIngredientsList.request());
        }
    }, [dispatch]);

    return (
        <div className={styles.ingredients}>
            <IngredientsList ingredients={ingredients} ingredientsSource={IngredientsSource.INGREDIENTS} />
        </div>
    );
};

export default Ingredients;
