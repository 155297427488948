import React from "react";
import styles from "components/Ingredients/Ingredients.module.scss";
import { IngredientsProps } from "api/ingredientsList";
import { frontMessages } from "utils/messages";
import { Link } from "react-router-dom";
import { useSearcher } from "../../hooks/useSearcher";
import Icons, { IconType } from "../Icons/Icons";
import { usePagination } from "../../hooks/usePagination";
import { useSorting } from "../../hooks/useSorting";
import { sortByName, sortByNumber, Sorting } from "../../services/filter.services";
import { useSelector } from "react-redux";
import { getUserPrivileges } from "../../store/auth/auth.selector";
import HeaderTitleWithIcon from "../HeaderTitleWithIcon/HeaderTitleWithIcon";
import { IngredientsSource } from "../WorkingIngredientList/WorkinIngredientList";

interface IngredientListProps {
    ingredients: IngredientsProps[];
    ingredientsSource: IngredientsSource;
}

const IngredientsList: React.FC<IngredientListProps> = ({ ingredients, ingredientsSource }) => {
    const { searcher, searchInput } = useSearcher();
    const { handleSortBy, sortBy, setSortBy } = useSorting(true);
    const { handleSortBy: handleSortByCount, sortBy: sortByCount, setSortBy: setSortByCount } = useSorting(true);
    const { hasEditorPrivileges, hasPricePrivileges } = useSelector(getUserPrivileges);
    const ingredientList = ingredients
        .filter((item) => item.ingredient.toLowerCase().includes(searchInput))
        .sort((first, second) => sortByName(first.ingredient, second.ingredient, sortBy))
        .sort((first, second) => sortByNumber(first.ingredientCount, second.ingredientCount, sortByCount));
    const { paginationComponent, offset, PER_PAGE } = usePagination(ingredientList.length);

    const handleClickSortByName = () => {
        setSortByCount(Sorting.NONE);
        handleSortBy();
    };

    const handleClickSortByCount = () => {
        setSortBy(Sorting.NONE);
        handleSortByCount();
    };

    const ingredientPathname = ingredientsSource === IngredientsSource.INGREDIENTS ? "skladniki" : "skladniki-robocze";
    const title = ingredientsSource === IngredientsSource.INGREDIENTS ? "SKŁADNIKI" : "SKŁADNIKI ROBOCZE";

    return (
        <>
            <div className={styles.ingredientsTitle}>
                <HeaderTitleWithIcon title={title} iconType={IconType.INGREDIENT} />
                <div className={styles.headerTitle}>
                    <span>Składników: </span>
                    <span className="bold">{ingredientList.length}</span>
                </div>
                <div className={styles.ingredientsSearch}>{searcher}</div>
            </div>
            <div>
                <div className={`${styles.ingredientsHeader}`}>
                    <span className={styles.ingredientsIndex}>{frontMessages.history.index}</span>
                    <span className={styles.ingredientsName} onClick={handleClickSortByName}>
                        {frontMessages.history.ingredient}
                        <Icons iconType={IconType.SORT} styles={styles.ingredientsNameIcon} />
                    </span>
                    <span className={styles.ingredientsCount} onClick={handleClickSortByCount}>
                        {frontMessages.ingredients.count}
                        <Icons iconType={IconType.SORT} styles={styles.ingredientsNameIcon} />
                    </span>
                    <span className={styles.ingredientsCategory}>{frontMessages.ingredients.category}</span>
                    <span className={styles.ingredientsDensity}>{frontMessages.ingredients.density}</span>
                    <span className={styles.ingredientsPrice}>{frontMessages.ingredients.price}</span>
                </div>
                <ul>
                    {ingredientList.slice(offset, offset + PER_PAGE).map((item, index) => (
                        <li className={styles.ingredientsWrapper} key={item.id}>
                            <Link
                                to={
                                    hasEditorPrivileges
                                        ? {
                                              pathname: `/${ingredientPathname}/${item.id}`,
                                              state: {
                                                  itemId: item.id,
                                                  ingredientName: item.ingredient || "",
                                                  ingredientCount: item.ingredientCount,
                                                  price: item.price,
                                                  density: item.density,
                                                  description: item.description,
                                              },
                                          }
                                        : "#"
                                }>
                                <div className={styles.ingredientsListItem}>
                                    <span className={styles.ingredientsIndex}>{index + offset + 1 + ". "}</span>
                                    <span className={styles.ingredientsName}>{item.ingredient}</span>
                                    <span className={styles.ingredientsCount}>{`użycia: ${item.ingredientCount}`}</span>
                                    <span className={styles.ingredientsCategory}>{item.ingredientCategory}</span>
                                    <span className={styles.ingredientsDensity}>
                                        {item.price !== "0" && item.density}
                                    </span>
                                    <span className={styles.ingredientsPrice}>
                                        {hasPricePrivileges &&
                                            item.price &&
                                            item.price !== "0" &&
                                            `cena: ${item.price} zł`}
                                    </span>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            {paginationComponent}
        </>
    );
};

export default IngredientsList;
