import React from "react";
import { render } from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./assets/styles/index.modules.scss";
import { ProviderWrapper } from "components/Provider/ProviderWrapper";
import App from "./App";
import store from "./store/store";

const Root = () => (
    <ProviderWrapper store={store}>
        <App />
    </ProviderWrapper>
);

render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
