import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./WorkingIngredientList.action";
import { IngredientsProps } from "../../api/ingredientsList";
import { fetchWorkingIngredientsList } from "./WorkingIngredientList.action";

export type WorkingIngredientsListAction = ActionType<typeof actions>;

export type WorkingIngredientsListState = Readonly<{
    workingIngredients: IngredientsProps[];
    isLoading: boolean;
}>;

export const ingredientsListInitialState: WorkingIngredientsListState = {
    workingIngredients: [],
    isLoading: false,
};

export const workingIngredientsListReducer = createReducer<WorkingIngredientsListState, WorkingIngredientsListAction>(
    ingredientsListInitialState
)
    .handleAction(fetchWorkingIngredientsList.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchWorkingIngredientsList.success, (state, action) => {
        return {
            ...state,
            workingIngredients: action.payload.workingIngredients,
            isLoading: false,
        };
    })
    .handleAction(fetchWorkingIngredientsList.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
