export const projectsMessages = {
    descriptions: {
        solids: "Ciała stałe",
        gloss: "Połysk",
        spray: "Natrysk",
        viscosity: "Lepkość",
        density: "Gęstość",
    },
    label: {
        description: "Wpisz opis projektu...",
        projectId: "Numer projektu",
        productionNumber: "Numer produkcyjny",
        toWork: "Nazwę konkurencji",
        solids: "sucha masa",
        recipes: "receptury",
        spray: "nałożenie",
        gloss: "połysk",
        viscosity: "lepkość",
        density: "gestość",
        workingNumber: "Numer wewnętrzny",
        properties: "cechy produktu",
        resources: "wymagane surowce",
    },
    errors: {
        required: "Pole nie może być puste.",
    },
    buttons: {
        addpProject: "DODAJ NOWY PROJEKT",
        printProject: "WYDRUKUJ PROJEKT",
        editProject: "EDYTUJ PROJEKT",
        saveProject: "ZAPISZ  PROJEKT",
        deleteProject: "USUŃ PROJEKT",
    },
};
