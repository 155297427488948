import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./sampleList.actions";
import { compareListItems } from "utils/utils";
import { fetchSampleList } from "./sampleList.actions";
import { SampleProps } from "../../api/sampleList";

export type SampleListAction = ActionType<typeof actions>;

export type SampleListState = Readonly<{
    sampleList: SampleProps[];
    isLoading: boolean;
}>;

export const sampleListInitialState: SampleListState = {
    isLoading: false,
    sampleList: [],
};

export const sampleListReducer = createReducer<SampleListState, SampleListAction>(sampleListInitialState)
    .handleAction(fetchSampleList.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchSampleList.success, (state, action) => {
        return {
            ...state,
            sampleList: action.payload.sampleList.sort((firstItem, secondItem) =>
                compareListItems(secondItem.createdAt, firstItem.createdAt)
            ),
            isLoading: false,
        };
    })
    .handleAction(fetchSampleList.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
