import React from "react";
import LoginForm from "../LoginForm/LoginForm";
import { useSelector } from "react-redux";
import { getAuthorizationState } from "../../store/auth/auth.selector";
import { Redirect } from "react-router";
import { Logo } from "../Logo/Logo";
import styles from "./MainView.module.scss";
import routes from "../../routes";
import { useHistory } from "react-router-dom";

export const MainView: React.FC = () => {
    const { isAuthorized, token } = useSelector(getAuthorizationState);
    const { location } = useHistory();

    if (isAuthorized && token && location.pathname === routes.HOME) {
        return <Redirect to={routes.RECIPES} />;
    }

    return (
        <div className={styles.wrapper}>
            <Logo />
            <LoginForm />
        </div>
    );
};

export default MainView;
