import React from "react";
import ProjectsHeader from "./ProjectsHeader/ProjectsHeader";
import Step from "../Step/Step";
import styles from "../SampleWrapper/SampleWrapper.module.scss";
import Stepper from "../Stepper/Stepper";
import ProjectsList from "./ProjectsList/ProjectsList";
import ProjectCreator from "./ProjectCreator/ProjectCreator";
import { useProjectView } from "./ProjectView/useProjectView";

const Project: React.FC = () => {
    useProjectView();

    return (
        <div className={styles.wrapper}>
            <Stepper>
                <Step
                    step={1}
                    render={(props) => {
                        return (
                            <div className={styles.wrapperFirst}>
                                <ProjectsHeader handleOpenNewProject={() => props.navigateTo?.(2)} />
                                <ProjectsList />
                            </div>
                        );
                    }}
                />
                <Step
                    step={2}
                    render={(props) => {
                        return <ProjectCreator backToList={() => props.navigateTo?.(1)} isProjectEditor={false} />;
                    }}
                />
            </Stepper>
        </div>
    );
};

export default Project;
