import API, { post } from "./api";

export interface Authorization {
    username: string;
    password: string;
}

export interface VerifyMe {
    token: string;
}

const authorize = (credentials: any) =>
    post<Authorization>(`${API.baseAuthUrl}/signin`, credentials).then(({ parsedBody }) => parsedBody);

const verifyMe = (credentials: any) =>
    post<VerifyMe>(`${API.baseAuthUrl}/verify`, credentials).then(({ parsedBody }) => parsedBody);

const registerUser = (credentials: any) =>
    post<VerifyMe>(`${API.baseAuthUrl}/signup`, credentials).then(({ parsedBody }) => parsedBody);

export default {
    authorize,
    verifyMe,
    registerUser,
};
