import API, { get } from "api/api";

export interface IngredientsHistory {
    id: number;
    ingredient: string;
    userName: string;
    actionDescription: string;
    actionType: string;
    createdAt: string;
}

export interface IngredientsHistoryResponse {
    ingredientsHistory: IngredientsHistory[];
    success: boolean;
}

const getIngredientsHistory = (credentials: any) =>
    get<IngredientsHistoryResponse>(`${API.baseApiUrl}/ingredients/history`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

export default {
    getIngredientsHistory,
};
