import React from "react";
import { RecipesProps } from "../../../api/recipesList";
import styles from "./RecipeViewHeader.module.scss";

interface RecipeViewHeaderProps {
    selectedRecipe: RecipesProps;
}

const RecipeViewHeader: React.FC<RecipeViewHeaderProps> = ({ selectedRecipe }) => {
    return (
        <div className={styles.recipeHeader}>
            <div className={`${styles.recipeHeaderWrapper} ${styles.recipeHeaderWrapperNumber}`}>
                <div>Numer: </div>
                <span>{selectedRecipe.recipeNumber}</span>
            </div>
            <div className={`${styles.recipeHeaderWrapper} ${styles.recipeHeaderWrapperName}`}>
                <div>Nazwa: </div>
                <span>{selectedRecipe.recipeName}</span>
            </div>
            <div className={`${styles.recipeHeaderWrapper} ${styles.recipeHeaderWrapperCategory}`}>
                <div>Kat.: </div>
                <span>{selectedRecipe.category}</span>
            </div>
        </div>
    );
};

export default RecipeViewHeader;
