import routes from "../../routes";
import { frontMessages } from "utils/messages";
import { UserPrivilegesProps } from "../../store/auth/auth.reducer";

export enum MenuTypeElement {
    RECIPES = "recipes",
    OTHERS = "others",
    HISTORY = "history",
    USERS = "users",
    NONE = "none",
}

export interface MenuItemProps {
    menuItemName?: string;
    elementType?: MenuTypeElement;
    nested?: NestedMenuItemProps[];
}

export interface NestedMenuItemProps {
    menuItemName: string;
    linkTo: string;
}

export const getFullMenu = (privileges: UserPrivilegesProps): MenuItemProps[] => [
    {
        menuItemName: frontMessages.nav.recipes,
        elementType: MenuTypeElement.RECIPES,
        nested: [
            {
                menuItemName: frontMessages.nav.productionRecipes,
                linkTo: routes.RECIPES,
            },
            {
                menuItemName: frontMessages.nav.workingRecipes,
                linkTo: routes.WORKING_RECIPES,
            },
        ],
    },
    {
        menuItemName: frontMessages.nav.others,
        elementType: MenuTypeElement.OTHERS,
        nested: [
            {
                menuItemName: frontMessages.nav.categories,
                linkTo: routes.CATEGORIES,
            },
            {
                menuItemName: frontMessages.nav.ingredientsList,
                linkTo: routes.INGREDIENTS_LIST,
            },
            {
                menuItemName: frontMessages.nav.workingIngredientList,
                linkTo: routes.WORKING_INGREDIENT_LIST,
            },
            {
                menuItemName: frontMessages.nav.projects,
                linkTo: routes.PROJECTS,
            },
            {
                menuItemName: frontMessages.nav.samples,
                linkTo: routes.SAMPLES,
            },
        ],
    },
    {
        menuItemName: frontMessages.nav.history,
        elementType: MenuTypeElement.HISTORY,
        nested: [
            {
                menuItemName: frontMessages.nav.recipesHistory,
                linkTo: routes.RECIPES_HISTORY,
            },
            {
                menuItemName: frontMessages.nav.recipesHistoryWorking,
                linkTo: routes.RECIPES_HISTORY_WORKING,
            },
            {
                menuItemName: frontMessages.nav.ingredientsHistory,
                linkTo: routes.INGREDIENTS_HISTORY,
            },
        ],
    },
    {
        menuItemName: privileges.hasOwnerPrivileges ? frontMessages.nav.users : "",
        elementType: privileges.hasOwnerPrivileges ? MenuTypeElement.USERS : MenuTypeElement.NONE,
        nested: privileges.hasOwnerPrivileges
            ? [
                  {
                      menuItemName: frontMessages.nav.userManagement,
                      linkTo: routes.USERS,
                  },
              ]
            : [],
    },
];

export const getBasicMenu = (): MenuItemProps[] => [
    {
        menuItemName: frontMessages.nav.recipes,
        elementType: MenuTypeElement.RECIPES,
        nested: [
            {
                menuItemName: frontMessages.nav.productionRecipes,
                linkTo: routes.RECIPES,
            },
        ],
    },
    {
        menuItemName: frontMessages.nav.others,
        elementType: MenuTypeElement.OTHERS,
        nested: [
            {
                menuItemName: frontMessages.nav.categories,
                linkTo: routes.CATEGORIES,
            },
            {
                menuItemName: frontMessages.nav.ingredientsList,
                linkTo: routes.INGREDIENTS_LIST,
            },
        ],
    },
    {
        menuItemName: frontMessages.nav.history,
        elementType: MenuTypeElement.HISTORY,
        nested: [
            {
                menuItemName: frontMessages.nav.recipesHistory,
                linkTo: routes.RECIPES_HISTORY,
            },
        ],
    },
];
