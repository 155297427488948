import { getAccessToken, isPlainObject } from "../utils/utils";

export interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

export const http = <T>(request: RequestInfo, init?: RequestInit): Promise<HttpResponse<T>> => {
    return new Promise((resolve, reject) => {
        let response: HttpResponse<T>;
        fetch(request, init)
            .then((res) => {
                response = res;
                return res.json();
            })
            .then((body) => {
                if (response.ok) {
                    response.parsedBody = body;
                    resolve(response);
                } else {
                    response.parsedBody = body;
                    reject(response);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export async function post<T>(path: string, body: any, args?: RequestInit): Promise<HttpResponse<T>> {
    const defaultHeaders = {
        method: "POST",
        body: isPlainObject(body) ? JSON.stringify(body) : body,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    const userToken = getAccessToken();
    const authHeaders = {
        ...defaultHeaders,
        headers: {
            ...defaultHeaders.headers,
            Authorization: userToken,
        },
    };

    const headers = userToken ? authHeaders : defaultHeaders;

    return await http<T>(new Request(path, { ...args, ...headers }));
}

export async function get<T>(path: string, args?: RequestInit): Promise<HttpResponse<T>> {
    const defaultHeaders = {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };

    const userToken = getAccessToken();
    const authHeaders = {
        ...defaultHeaders,
        headers: {
            ...defaultHeaders.headers,
            Authorization: userToken,
        },
    };

    const headers = userToken ? authHeaders : defaultHeaders;

    return await http<T>(new Request(path, { ...args, ...headers }));
}

export default {
    baseAuthUrl: process.env.REACT_APP_AUTH,
    baseApiUrl: process.env.REACT_APP_API_URL,
};
