import { AppState } from "../rootReducer";
import { createSelector } from "reselect";

export enum UserRoles {
    OWNER = "ROLE_OWNER",
    ADMIN = "ROLE_ADMIN",
    VIEWER = "ROLE_VIEWER",
    PRICE = "ROLE_PRICE",
    EDITOR = "ROLE_EDITOR",
}

const authorizationState = (state: AppState) => state.auth;

export const getAuthorizationState = createSelector(authorizationState, (state) => state);

export const getUserPrivileges = createSelector(authorizationState, (state) => state.privileges);
