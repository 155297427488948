import React from "react";
import ReactPaginate from "react-paginate";
import styles from "./hooks.module.scss";
export const usePagination = (length: number, perPage?: number) => {
    const PER_PAGE = perPage ? perPage : 50;
    const [currentPage, setCurrentPage] = React.useState(1);
    const [offset, setOffset] = React.useState(0);
    const handlePageClick = (e: any) => {
        console.log(e);
        const selectedPage = e.selected;
        const offset = selectedPage * PER_PAGE;
        setCurrentPage(e.selected);
        setOffset(offset);
    };
    const pageCount = Math.ceil(length / PER_PAGE);

    const paginationComponent =
        length > PER_PAGE ? (
            <div className={styles.pagination}>
                <ReactPaginate
                    previousLabel={" <-"}
                    nextLabel={" -> "}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            </div>
        ) : null;

    return {
        paginationComponent,
        offset,
        currentPage,
        PER_PAGE,
    };
};
