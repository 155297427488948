import React from "react";
import { useSelector } from "react-redux";
import { getProjectListState } from "../../../store/projects/projects.selector";
import styles from "./ProjectsList.module.scss";
import HeaderTitleWithIcon from "../../HeaderTitleWithIcon/HeaderTitleWithIcon";
import Icons, { IconType } from "../../Icons/Icons";
import { useSearcher } from "../../../hooks/useSearcher";
import { useSelect } from "../../../hooks/useSelect";
import { usePagination } from "../../../hooks/usePagination";
import { statusOptions } from "../projects.helper";
import { filterProjects, sortByDate } from "../../../services/filter.services";
import { useDateFilter } from "../../../hooks/useDateFilter";
import { useSorting } from "../../../hooks/useSorting";
import { Link } from "react-router-dom";

const ProjectsList: React.FC = () => {
    const { projectsList } = useSelector(getProjectListState);
    const { searcher, searchInput } = useSearcher();
    const { paginationComponent, offset, PER_PAGE } = usePagination(projectsList.length);
    const { selectedValue, selectComponent } = useSelect(statusOptions, undefined, "Wybierz STATUS");
    const { handleSortBy, sortBy } = useSorting(false);
    const { searchEndDateComponent, searchStartDateComponent, searchStartDate, searchEndDate } = useDateFilter();

    return (
        <div className={styles.projects}>
            <div className={styles.projectsTitle}>
                <HeaderTitleWithIcon title={"LISTA PROJEKTÓW"} iconType={IconType.INGREDIENT} />
                <div className={styles.projectsTitle}>
                    <span>{`Projektów: `}</span>
                    <span className="bold">{projectsList.length}</span>
                </div>
                <div className={styles.projectsTitleDate}>{searcher}</div>
                <div className={styles.projectsTitleDate}>{selectComponent}</div>
                <div className={styles.projectsTitleDate}>{searchStartDateComponent}</div>
                <div className={styles.projectsTitleDate}>{searchEndDateComponent}</div>
            </div>
            <div className={styles.projectsHeader}>
                <span className={styles.projectsLp}>lp.</span>
                <span className={styles.projectsNumber}>Numer</span>
                <span className={styles.projectsDescription}>Opis</span>
                <span className={styles.projectsType}>Typ</span>
                <span className={styles.projectsDate} onClick={handleSortBy}>
                    Utworzono
                    <Icons iconType={IconType.SORT} styles={styles.projectsNameIcon} />
                </span>
                <span className={styles.projectsRealization}> Realizacja </span>
                <span className={styles.projectsStatus}> Status </span>
                <span className={styles.projectsUser}> Użytkownik </span>
            </div>
            <ul>
                {projectsList
                    .filter((item) => filterProjects(item, searchInput, selectedValue, searchStartDate, searchEndDate))
                    .sort((first, second) => sortByDate(first.createdAt, second.createdAt, sortBy))
                    .slice(offset, offset + PER_PAGE)
                    .map((item, index) => {
                        return (
                            <li key={item.id} className={styles.projectsWrapper}>
                                <Link to={`/projekty/${item.projectId}`} key={item.projectId}>
                                    <div className={styles.projectsListItem}>
                                        <span className={styles.projectsLp}>{index + 1 + offset + ". "}</span>
                                        <span className={styles.projectsNumber}>{item.projectId}</span>
                                        <span className={styles.projectsDescription}>{item.projectDescription}</span>
                                        <span className={styles.projectsType}>{item.type}</span>
                                        <span className={styles.projectsDate}>{item.createdAt.slice(0, 10)}</span>
                                        <span className={styles.projectsDate}>
                                            {item.realizationDate?.slice(0, 10)}
                                        </span>
                                        <span className={styles.projectsStatus}>{item.status}</span>
                                        <span className={styles.projectsUser}>{item.userName}</span>
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
            <div className={styles.projectsPagination}>{paginationComponent}</div>
        </div>
    );
};
export default ProjectsList;
