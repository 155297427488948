import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./projects.actions";
import { compareListItems } from "utils/utils";
import { fetchProjectList } from "./projects.actions";
import { ProjectProps } from "../../api/projects";

export type ProjectListAction = ActionType<typeof actions>;

export type ProjectListState = Readonly<{
    projectsList: ProjectProps[];
    isLoading: boolean;
}>;

export const projectListInitialState: ProjectListState = {
    isLoading: false,
    projectsList: [],
};

export const projectListReducer = createReducer<ProjectListState, ProjectListAction>(projectListInitialState)
    .handleAction(fetchProjectList.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchProjectList.success, (state, action) => {
        return {
            ...state,
            projectsList: action.payload.projectsList.sort((firstItem, secondItem) =>
                compareListItems(secondItem.createdAt, firstItem.createdAt)
            ),
            isLoading: false,
        };
    })
    .handleAction(fetchProjectList.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
