import API, { get, post } from "api/api";

export interface UserManagementResponse {
    users: UserManagementProps[];
}

export interface EditUserApiResponse {
    success: boolean;
    message: string;
}

export interface UserEditProps {
    roles: string[];
    isBlocked: boolean;
    id: number;
}

export interface UserManagementProps {
    isBlocked: boolean;
    id: number;
    email: string;
    roles: string[];
    createdAt: string;
    username: string;
}

const fetchUserList = (credentials: any) =>
    get<UserManagementResponse>(`${API.baseApiUrl}/userList`, credentials).then(({ parsedBody }) => parsedBody);

const editUser = (credentials: any) =>
    post(`${API.baseApiUrl}/edit-user`, credentials).then(({ parsedBody }) => parsedBody);

export default {
    fetchUserList,
    editUser,
};
