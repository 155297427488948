import React from "react";
import { history } from "../../store/store";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { AnyAction, Store } from "redux";

interface ProviderWrapper {
    // eslint-disable-next-line
    store: Store<any, AnyAction>;
    children: React.ReactNode;
}

export const ProviderWrapper: React.FC<ProviderWrapper> = ({ children, store }) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
);
