import { all, takeEvery } from "redux-saga/effects";
import { authorize, createNewUser, verifyMe } from "./auth/auth.actions";
import { authorizationSaga, registerUserSaga, verifyMeSaga } from "./auth/auth.saga";
import { addNewRecipe, deleteRecipe, editRecipe, printRecipe } from "./recipeOperations/recipeOperations.actions";
import {
    addNewRecipeSaga,
    deleteRecipeSaga,
    editRecipeSaga,
    printRecipeSaga,
} from "./recipeOperations/recipeOperations.saga";
import {
    deleteIngredientSaga,
    fetchIngredientsListSaga,
    updateIngredientCategorySaga,
    updateIngredientDensitySaga,
    updateIngredientNameSaga,
    updateIngredientPriceSaga,
} from "./IngredientList/IngredientList.saga";
import {
    deleteIngredient,
    fetchIngredientsList,
    updateIngredientCategory,
    updateIngredientDensity,
    updateIngredientName,
    updateIngredientPrice,
} from "./IngredientList/ingredienstList.action";
import { fetchIngredientsHistorySaga } from "store/ingredientsHistory/IngredientsHistory.saga";
import { fetchIngredientsHistory } from "store/ingredientsHistory/IngredientsHistory.actions";
import { fetchRecipeListSaga } from "store/RecipesList/RecipesList.saga";
import { fetchRecipesList } from "store/RecipesList/RecipesList.actions";
import { fetchRecipesHistorySaga, fetchWorkingRecipesHistorySaga } from "store/recipesHistory/recipesHistory.saga";
import { fetchRecipesHistory, fetchWorkingRecipesHistory } from "store/recipesHistory/recipesHistory.actions";
import { addNewCategory, editCategory, fetchCategories } from "store/categories/categories.actions";
import { addNewCategorySaga, editCategorySaga, fetchCategoriesSaga } from "store/categories/categories.saga";
import {
    addNewWorkingRecipe,
    deleteWorkingRecipe,
    editWorkingRecipe,
    fetchWorkingRecipesList,
} from "store/workingRecipe/workingRecipe.actions";
import {
    addNewWorkingRecipeSaga,
    deleteWorkingRecipeSaga,
    editWorkingRecipeSaga,
    fetchWorkingRecipesSaga,
} from "store/workingRecipe/workingRecipe.saga";
import { editUser, fetchUserList } from "store/userManagement/userManagement.actions";
import { editUserSaga, fetchUserListSaga } from "store/userManagement/userManagement.saga";
import { addNewSample, deleteSample, editSample, fetchSampleList } from "./sampleList/sampleList.actions";
import { addNewSampleSaga, deleteSampleSaga, editSampleSaga, fetchSamplesSaga } from "./sampleList/sampleList.saga";
import { addNewProject, deleteProject, editProject, fetchProjectList } from "./projects/projects.actions";
import { addNewProjectSaga, deleteProjectSaga, editProjectSaga, fetchProjectsSaga } from "./projects/projects.saga";
import {
    deleteWorkingIngredient,
    fetchWorkingIngredientsList,
    updateWorkingIngredientCategory,
    updateWorkingIngredientDensity,
    updateWorkingIngredientName,
    updateWorkingIngredientPrice,
} from "./workingIngredients/WorkingIngredientList.action";
import {
    deleteWorkingIngredientSaga,
    fetchWorkingIngredientsListSaga,
    updateWorkingIngredientCategorySaga,
    updateWorkingIngredientDensitySaga,
    updateWorkingIngredientNameSaga,
    updateWorkingIngredientPriceSaga,
} from "./workingIngredients/WorkingIngredientList.saga";

export function* rootSaga(): Generator {
    yield all([
        takeEvery(authorize.request, authorizationSaga),
        takeEvery(addNewRecipe.request, addNewRecipeSaga),
        takeEvery(editRecipe.request, editRecipeSaga),
        takeEvery(editWorkingRecipe.request, editWorkingRecipeSaga),
        takeEvery(deleteRecipe.request, deleteRecipeSaga),
        takeEvery(printRecipe.request, printRecipeSaga),
        takeEvery(verifyMe.request, verifyMeSaga),
        takeEvery(createNewUser.request, registerUserSaga),
        takeEvery(addNewSample.request, addNewSampleSaga),
        takeEvery(editSample.request, editSampleSaga),
        takeEvery(deleteSample.request, deleteSampleSaga),
        takeEvery(deleteWorkingRecipe.request, deleteWorkingRecipeSaga),
        takeEvery(fetchSampleList.request, fetchSamplesSaga),
        takeEvery(fetchIngredientsList.request, fetchIngredientsListSaga),
        takeEvery(fetchWorkingIngredientsList.request, fetchWorkingIngredientsListSaga),
        takeEvery(updateIngredientName.request, updateIngredientNameSaga),
        takeEvery(updateWorkingIngredientName.request, updateWorkingIngredientNameSaga),
        takeEvery(updateIngredientPrice.request, updateIngredientPriceSaga),
        takeEvery(updateWorkingIngredientPrice.request, updateWorkingIngredientPriceSaga),
        takeEvery(updateIngredientDensity.request, updateIngredientDensitySaga),
        takeEvery(updateWorkingIngredientDensity.request, updateWorkingIngredientDensitySaga),
        takeEvery(deleteIngredient.request, deleteIngredientSaga),
        takeEvery(deleteWorkingIngredient.request, deleteWorkingIngredientSaga),
        takeEvery(updateIngredientCategory.request, updateIngredientCategorySaga),
        takeEvery(updateWorkingIngredientCategory.request, updateWorkingIngredientCategorySaga),
        takeEvery(fetchIngredientsHistory.request, fetchIngredientsHistorySaga),
        takeEvery(fetchRecipesList.request, fetchRecipeListSaga),
        takeEvery(fetchRecipesHistory.request, fetchRecipesHistorySaga),
        takeEvery(fetchWorkingRecipesHistory.request, fetchWorkingRecipesHistorySaga),
        takeEvery(fetchCategories.request, fetchCategoriesSaga),
        takeEvery(addNewWorkingRecipe.request, addNewWorkingRecipeSaga),
        takeEvery(fetchWorkingRecipesList.request, fetchWorkingRecipesSaga),
        takeEvery(addNewCategory.request, addNewCategorySaga),
        takeEvery(editCategory.request, editCategorySaga),
        takeEvery(fetchUserList.request, fetchUserListSaga),
        takeEvery(editUser.request, editUserSaga),
        takeEvery(deleteProject.request, deleteProjectSaga),
        takeEvery(fetchProjectList.request, fetchProjectsSaga),
        takeEvery(editProject.request, editProjectSaga),
        takeEvery(addNewProject.request, addNewProjectSaga),
    ]);
}
