export enum ProjectFieldTitle {
    PROJECT_DESCRIPTION = "projectDescription",
    PRODUCTION_NUMBER = "productionNumber",
    WORKING_NUMBER = "workingNumber",
    TO_WORK = "toWork",
    PROPERTIES = "properties",
    RESOURCES = "resources",
    REALIZATION_DATE = "realizationDate",
    VISCOSITY = "viscosity",
    GLOSS = "gloss",
    SOLIDS = "solids",
    DENSITY = "density",
    SPRAY = "spray",
    RECIPES = "recipes",
    PROJECT_ID = "projectId",
}

export const typeOptions = [
    {
        value: "WODNE",
        label: "WODNE",
    },
    {
        value: "ROZPUSZCZALNIKOWE",
        label: "ROZPUSZCZALNIKOWE",
    },
];

export const statusOptions = [
    {
        value: "W TRAKCIE",
        label: "W TRAKCIE",
    },
    {
        value: "WERYFIKACJA",
        label: "WERYFIKACJA",
    },
    {
        value: "OCZEKUJE",
        label: "OCZEKUJE",
    },
    {
        value: "ODRZUCONO",
        label: "ODRZUCONO",
    },
    {
        value: "UKOŃCZONO",
        label: "UKOŃCZONO",
    },
    {
        value: "KOREKTA",
        label: "KOREKTA",
    },
];
