import React from "react";
import { FieldTitle, FieldType } from "utils/forms.helper";
import { frontMessages } from "utils/messages";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addNewCategory } from "store/categories/categories.actions";
import styles from "./AddNewCategory.module.scss";
import Stepper from "components/Stepper/Stepper";
import Step from "components/Step/Step";
import Icons, { IconType } from "../Icons/Icons";

const AddNewCategory: React.FC = () => {
    const { handleSubmit, register } = useForm<FormData>();
    const dispatch = useDispatch();
    const onSubmit = (previousStep?: () => void) =>
        handleSubmit((values: any) => {
            dispatch(addNewCategory.request({ categoryName: values.categoryName }));
            previousStep?.();
        });
    return (
        <div className={styles.newCategory}>
            <Stepper>
                <Step
                    render={(props) => {
                        return (
                            <button
                                className="btn btn--default btn--width-medium"
                                onClick={props.onNextStep}
                                type="submit">
                                <div>{frontMessages.button.addCategory}</div>
                                <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                            </button>
                        );
                    }}
                />
                <Step
                    render={(props) => {
                        return (
                            <form className={styles.newCategoryForm} onSubmit={onSubmit(props.onPreviousStep)}>
                                <span className={styles.newCategoryTitle}>Podaj nazwę kategorii</span>
                                <input
                                    className={styles.newCategoryInput}
                                    ref={register}
                                    type={FieldType.TEXT}
                                    placeholder={frontMessages.placeholders.inputCategoryName}
                                    name={FieldTitle.CATEGORY_NAME}
                                />
                                <button type="submit" className="btn btn--default btn--width-medium">
                                    <span>{frontMessages.button.add}</span>
                                    <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                                </button>
                                <button
                                    onClick={props.onPreviousStep}
                                    className="btn btn--black btn--width-medium"
                                    type="button">
                                    <span>{frontMessages.button.cancel}</span>
                                </button>
                            </form>
                        );
                    }}
                />
            </Stepper>
        </div>
    );
};
export default AddNewCategory;
