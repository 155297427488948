import React from "react";
import NewRecipeCreator from "../RecipeFormCreator";

const NewRecipeCreatorWrapper: React.FC = () => {
    return (
        <NewRecipeCreator
            isRecipeEditor={false}
            isRecipeCreator={true}
            isWorkingRecipe={false}
            isProjectRecipe={false}
        />
    );
};

export default NewRecipeCreatorWrapper;
