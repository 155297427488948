import React, { useEffect } from "react";
import styles from "../Ingredients/Ingredients.module.scss";
import IngredientsList from "../IngredientsList/IngedientsList";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkingIngredientsList } from "../../store/workingIngredients/WorkingIngredientList.action";
import { getWorkingIngredientsListState } from "../../store/workingIngredients/WorkingIngredientList.selector";
import { fetchWorkingRecipesList } from "../../store/workingRecipe/workingRecipe.actions";
import { getWorkingRecipesListState } from "../../store/workingRecipe/workingRecipe.selector";

export enum IngredientsSource {
    INGREDIENTS = "INGREDIENTS",
    WORKING_INGREDIENTS = "WORKING_INGREDIENTS",
}

const WorkingIngredientList: React.FC = () => {
    const dispatch = useDispatch();
    const { workingIngredients } = useSelector(getWorkingIngredientsListState);
    const { workingRecipes } = useSelector(getWorkingRecipesListState);

    useEffect(() => {
        if (!workingIngredients.length) {
            dispatch(fetchWorkingIngredientsList.request());
        }

        if (!workingRecipes?.length) {
            dispatch(fetchWorkingRecipesList.request());
        }
    }, [dispatch]);

    return (
        <div className={styles.ingredients}>
            <IngredientsList
                ingredients={workingIngredients}
                ingredientsSource={IngredientsSource.WORKING_INGREDIENTS}
            />
        </div>
    );
};

export default WorkingIngredientList;
