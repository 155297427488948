import API, { get, post } from "./api";

export interface ProjectProps {
    projectDescription: string;
    productionNumber: string;
    workingNumber: string;
    toWork: string;
    status: string;
    realizationDate: string;
    resources: string;
    properties: string;
    userName: string;
    id: number;
    createdAt: string;
    type: string;
    viscosity: string;
    solids: string;
    density: string;
    gloss: string;
    spray: string;
    recipes: string;
    projectId: string;
}

export interface ProjectPropsResponse {
    projectsList: ProjectProps[];
}

const addNewProject = (credentials: any) =>
    post<ProjectProps>(`${API.baseApiUrl}/add-project`, credentials).then(({ parsedBody }) => parsedBody);

const getProjectList = (credentials: any) =>
    get<ProjectProps>(`${API.baseApiUrl}/projects`, credentials).then(({ parsedBody }) => parsedBody);

const editProject = (credentials: any) =>
    post<ProjectProps>(`${API.baseApiUrl}/edit-project`, credentials).then(({ parsedBody }) => parsedBody);

const deleteProject = (credentials: any) =>
    post<ProjectProps>(`${API.baseApiUrl}/delete-project`, credentials).then(({ parsedBody }) => parsedBody);

export default {
    addNewProject,
    getProjectList,
    editProject,
    deleteProject,
};
