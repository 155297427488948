import * as React from "react";
import routes from "../../routes";
import { Redirect } from "react-router";

export interface StepperProps {
    isInMobileView?: boolean;
    isHeadless?: boolean;
    isScrollToTopDisabled: boolean;
    defaultStep?: number;
    redirectUrl?: string;
    isStepInvalid: boolean;
    visitedStep: number;
    submitStep: () => void;
    children: React.ReactNode;
}

export interface StepperState {
    currentStep: number;
    totalSteps: number;
}

class Stepper extends React.Component<StepperProps, StepperState> {
    static defaultProps: Partial<StepperProps> = {
        isHeadless: true,
        isScrollToTopDisabled: false,
        /* used for handling steps from outside */
        defaultStep: 1,
    };

    state = {
        currentStep: (this.props.defaultStep || 1) - 1,
        totalSteps: React.Children.count(this.props.children),
    };

    componentDidUpdate(prevProps: StepperProps) {
        if (prevProps.defaultStep !== this.props.defaultStep) {
            this.setState({
                currentStep: (this.props.defaultStep || 1) - 1,
            });
        }
    }

    componentDidMount() {
        const { defaultStep } = this.props;

        if (defaultStep) {
            if (this._isStepInRange(defaultStep)) {
                this.setState({
                    currentStep: defaultStep - 1,
                });
            }
        }
    }

    goToPreviousStep = () => this.setState({ currentStep: this.state.currentStep - 1 });

    goToNextStep = () => this.setState({ currentStep: this.state.currentStep + 1 });

    navigateTo(step: number) {
        if (this._isStepInRange(step)) {
            return this.setState({
                currentStep: step - 1,
            });
        }
    }

    render() {
        const { defaultStep, redirectUrl } = this.props;

        if (defaultStep && !this._isStepInRange(this.state.currentStep)) {
            return <Redirect to={redirectUrl || routes.HOME} />;
        }

        return (
            <>
                {React.Children.map(this.props.children, (child, index) => {
                    return React.cloneElement(child as React.ReactElement, {
                        isHeadless: this.props.isHeadless,
                        isCompleted: this._isStepCompleted(index),
                        isActive: this._isStepActive(index),
                        step: this._setCurrentStep(index),
                        displayPrevious: this._isPreviousStepBtnAvailable(),
                        displayNext: this._isNextStepBtnAvailable(),
                        displaySubmit: this._isSubmitFormBtnAvailable(),
                        isStepInvalid: this.props.isStepInvalid,
                        submitStep: this.props.submitStep,
                        currentStep: this.state.currentStep,
                        onPreviousStep: () => this.goToPreviousStep(),
                        onNextStep: () => this.goToNextStep(),
                        navigateTo: (step: number) => this.navigateTo(step),
                    });
                })}
            </>
        );
    }

    private _isStepCompleted = (index: number) => this.state.currentStep > index;

    private _isStepActive = (index: number) => index === this.state.currentStep;

    private _setCurrentStep = (index: number) => index + 1;

    private _isPreviousStepBtnAvailable = () => this.state.currentStep > 0;

    private _isNextStepBtnAvailable = () => this.state.currentStep < this.state.totalSteps;

    private _isSubmitFormBtnAvailable = () => this.state.currentStep === this.state.totalSteps;

    private _isStepHeaderSeparatorVisible = (index: number) => this.state.totalSteps - 1 !== index;

    private _isStepInRange = (initialStep: number) =>
        !isNaN(initialStep) && initialStep <= this.state.totalSteps && initialStep >= 0;
}

export default Stepper;
