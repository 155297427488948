import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecipesHistoryState } from "store/recipesHistory/recipesHistory.selector";
import { fetchRecipesHistory } from "store/recipesHistory/recipesHistory.actions";
import RecipesList from "components/RecipesList/RecipesList";
import { RecipeSource } from "components/RecipeView/RecipeView";
import styles from "./RecipesHistory.module.scss";
const RecipesHistory: React.FC = () => {
    const dispatch = useDispatch();
    const { recipesHistory } = useSelector(getRecipesHistoryState);

    React.useEffect(() => {
        if (!recipesHistory?.length) {
            dispatch(fetchRecipesHistory.request());
        }
    }, []);

    return (
        <div className={styles.wrapper}>
            <RecipesList
                recipesListState={recipesHistory}
                recipeSource={RecipeSource.HISTORY_LIST}
                isHistory={true}
                isWorkingHistory={false}
            />
        </div>
    );
};

export default RecipesHistory;
