import { call, put } from "redux-saga/effects";
import Api from "api/recipesList";
import { notification } from "components/Notification/Notification";
import { fetchRecipesList } from "store/RecipesList/RecipesList.actions";
import { RecipesListResponse } from "api/recipesList";

export function* fetchRecipeListSaga(action: ReturnType<typeof fetchRecipesList.request>): Generator {
    try {
        const response = yield call(Api.fetchRecipesList, action.payload);
        const typedResponse = response as RecipesListResponse;
        yield put(fetchRecipesList.success({ recipes: typedResponse.recipes }));
    } catch (err: any) {
        notification(err.message, false);
        yield put(fetchRecipesList.failure(err));
    }
}
