import * as React from "react";

export interface IStepProps {
    render: (props: IStepProps) => any;
    isInMobileView?: boolean;
    isHeadless?: boolean;
    isCompleted?: boolean;
    isActive?: boolean;
    displayPrevious?: boolean;
    displayNext?: boolean;
    displaySubmit?: boolean;
    step?: number;
    label?: string;
    nextBtnLabel?: string;
    previousBtnLabel?: string;
    submitButtonRef?: React.Ref<HTMLDivElement>;
    isStepInvalid?: boolean;
    currentStep?: number;
    submitStep?: () => void;
    onPreviousStep?: () => any;
    onNextStep?: () => any;
    navigateTo?: (step: number) => any;
}

class Step extends React.Component<IStepProps> {
    render() {
        const { isActive } = this.props;

        if (!isActive) {
            return null;
        }

        return this.props.render(this.props);
    }
}
export default Step;
