import { createAsyncAction } from "typesafe-actions";
import { ApiResponse } from "../recipeOperations/recipeOperations.actions";
import { ProjectPropsResponse } from "../../api/projects";

export const fetchProjectList = createAsyncAction(
    "FETCH_PROJECT_LIST_REQUEST",
    "FETCH_PROJECT_LIST_SUCCESS",
    "FETCH_PROJECT_LIST_FAILURE"
)<void, ProjectPropsResponse, Error>();

export const deleteProject = createAsyncAction(
    "DELETE_PROJECT_REQUEST",
    "DELETE_PROJECT_SUCCESS",
    "DELETE_PROJECT_FAILURE"
)<{ id: number }, void, Error>();

export const editProject = createAsyncAction(
    "EDIT_PROJECT_REQUEST",
    "EDIT_PROJECT_REQUEST_SUCCESS",
    "EDIT_PROJECT_REQUEST_FAILURE"
)<ProjectPropsResponse, void, Error>();

export const addNewProject = createAsyncAction("ADD_NEW_PROJECT_REQUEST", "ADD_PROJECT_SUCCESS", "ADD_PROJECT_FAILURE")<
    ProjectPropsResponse,
    ApiResponse,
    Error
>();
