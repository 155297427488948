import React from "react";
import { useForm } from "react-hook-form";
import Input from "../../Input/Input";
import { FieldType } from "../../../utils/forms.helper";
import styles from "./ProjectCreator.module.scss";
import { projectsMessages } from "../projectsMessages";
import { ProjectFieldTitle, statusOptions, typeOptions } from "../projects.helper";
import { useSelect } from "../../../hooks/useSelect";
import { frontMessages } from "../../../utils/messages";
import HeaderTitleWithIcon from "../../HeaderTitleWithIcon/HeaderTitleWithIcon";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationState } from "../../../store/auth/auth.selector";
import { addNewProject, deleteProject, editProject } from "../../../store/projects/projects.actions";
import { ProjectProps } from "../../../api/projects";
import ProjectCreatorButtons from "./ProjectCreatorButtons/ProjectCreatorButtons";
import { IconType } from "../../Icons/Icons";
import ProjectCreatorInputs from "./ProjectCreatorInputs/ProjectCreatorInputs";
import ProjectCreatorDefaultInputs from "./ProjectCreatorDefaultInputs/ProjectCreatorDefaultInputs";

export interface ProjectCreatorFormData {
    projectDescription: string;
    productionNumber: string;
    workingNumber: string;
    toWork: string;
    status: string;
    realizationDate: string;
    resources: string;
    properties: string;
    solids: string;
    recipes: string;
}

interface ProjectCreatorProps {
    initialValues?: ProjectProps;
    isProjectEditor: boolean;
    backToList: any;
    backToProjectView?(): void;
}

const ProjectCreator: React.FC<ProjectCreatorProps> = ({
    backToList,
    initialValues,
    isProjectEditor,
    backToProjectView,
}) => {
    const [isOpenNewRecipe, setOpenRecipe] = React.useState(false);
    const { selectedValue, selectComponent, setSelectedValue } = useSelect(
        statusOptions,
        undefined,
        initialValues?.status
    );
    const {
        selectedValue: selectedTypeValue,
        selectComponent: selectedTypeComponent,
        setSelectedValue: setSelectedTypeValue,
    } = useSelect(typeOptions, undefined, initialValues?.type);
    const dispatch = useDispatch();
    const { userName } = useSelector(getAuthorizationState);
    const { handleSubmit, register, errors, setValue } = useForm<ProjectCreatorFormData>({ mode: "onBlur" });

    const handleDeleteProject = () => initialValues?.id && dispatch(deleteProject.request({ id: initialValues.id }));

    const setOpenNewRecipe = (isOpen: boolean) => setOpenRecipe(isOpen);

    const onSubmit = () =>
        handleSubmit((values: any) => {
            const reformatValues = {
                userName: userName,
                status: selectedValue,
                type: selectedTypeValue,
                id: isProjectEditor ? initialValues?.id : undefined,
                ...values,
            };

            if (isProjectEditor) {
                dispatch(editProject.request(reformatValues));
            } else {
                dispatch(addNewProject.request(reformatValues));
            }
            backToList();
        });

    const handleClickBackButton = () => {
        if (backToProjectView && isProjectEditor) {
            backToProjectView();
        } else {
            backToList();
        }
    };

    const handleOnSubmit = (e: any) => {
        e.preventDefault();

        if (!isOpenNewRecipe) {
            onSubmit()();
        }
    };

    React.useEffect(() => {
        if (initialValues) {
            const initialValuesMap = Object.entries(initialValues);
            initialValuesMap.map((item) => {
                return setValue(item[0], item[1]);
            });

            setSelectedValue(initialValues.status);
            setSelectedTypeValue(initialValues.type);
        }
    }, []);

    return (
        <form onSubmit={handleOnSubmit} className={styles.wrapper} key={"projectCreator"}>
            <div>
                <div className={styles.wrapperTitle}>
                    <button type={"button"} className="btn btn--black" onClick={handleClickBackButton}>
                        {frontMessages.button.backToList}
                    </button>
                    <div className={styles.wrapperTitleHeader}>
                        <HeaderTitleWithIcon
                            title={isProjectEditor ? "EDYTUJ PROJEKT" : "DODAJ PROJEKT"}
                            iconType={isProjectEditor ? IconType.EDIT : IconType.PLUS}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.header}>
                <div className={styles.headerNumber}>
                    <div className={styles.headerDescriptionTitle}>NUMER:</div>
                    <Input
                        name={ProjectFieldTitle.PROJECT_ID}
                        required={true}
                        label={""}
                        register={register}
                        placeholder={projectsMessages.label.projectId}
                        type={FieldType.TEXT}
                        errors={errors.projectDescription}
                        errorMessage={projectsMessages.errors.required}
                    />
                </div>
                <div className={styles.headerDescription}>
                    <div className={styles.headerDescriptionTitle}>PROJEKT:</div>
                    <div className={styles.headerDescriptionTextarea}>
                        <Input
                            name={ProjectFieldTitle.PROJECT_DESCRIPTION}
                            required={true}
                            label={projectsMessages.label.description}
                            register={register}
                            placeholder={projectsMessages.label.description}
                            type={FieldType.TEXT_AREA}
                            errors={errors.projectDescription}
                            errorMessage={projectsMessages.errors.required}
                        />
                    </div>
                </div>
                <div className={styles.headerTypeWrapper}>
                    <div className={styles.descSelectsWrapperHeader}>Typ:</div>
                    <div className={styles.descSelectsWrapperSelect}>{selectedTypeComponent}</div>
                </div>
            </div>
            <div className={styles.desc}>
                <ProjectCreatorInputs register={register} errors={errors} selectComponent={selectComponent} />
            </div>
            <div>
                <ProjectCreatorDefaultInputs
                    register={register}
                    errors={errors}
                    initialValues={initialValues}
                    setOpenNewRecipe={setOpenNewRecipe}
                    isOpenNewRecipe={isOpenNewRecipe}
                />
            </div>
            <ProjectCreatorButtons isProjectEditor={isProjectEditor} handleDeleteProject={handleDeleteProject} />
        </form>
    );
};

export default ProjectCreator;
