import API, { get, post } from "./api";

export interface IngredientsListResponse {
    ingredients: IngredientsProps[];
}

export interface IngredientsProps {
    id: number;
    ingredientCount: number;
    price?: string;
    ingredient: string;
    density?: string;
    description?: string;
    ingredientCategory?: string;
    ingredientSource?: string;
}

export interface UpdateIngredientNameResponse {
    success: boolean;
    message: string;
}

export interface UpdateIngredientNameRequest {
    id: number;
    price: string;
    oldName: string;
    newName: string;
    userName: string;
    ingredientCategory: string;
}

export interface UpdateIngredientPriceRequest {
    id: number;
    price: string;
    newPrice: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

export interface UpdateIngredientCategoryRequest {
    id: number;
    price: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

export interface UpdateIngredientDensityRequest {
    id: number;
    density: string;
    newDensity: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

export interface DeleteIngredientRequest {
    id: number;
    price: string;
    name: string;
    userName: string;
    ingredientCategory: string;
}

const fetchIngredientsList = (credentials: any) =>
    get<IngredientsListResponse>(`${API.baseApiUrl}/ingredients`, credentials).then(({ parsedBody }) => parsedBody);

const updateIngredientName = (credentials: any) =>
    post<UpdateIngredientNameResponse>(`${API.baseApiUrl}/ingredient/update-name`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const updateIngredientPrice = (credentials: any) =>
    post<UpdateIngredientNameResponse>(`${API.baseApiUrl}/ingredient/update-price`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const updateIngredientDensity = (credentials: any) =>
    post<UpdateIngredientNameResponse>(`${API.baseApiUrl}/ingredient/update-density`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const updateIngredientCategory = (credentials: any) =>
    post<UpdateIngredientNameResponse>(`${API.baseApiUrl}/ingredient/update-category`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const deleteIngredient = (credentials: any) =>
    post<UpdateIngredientNameResponse>(`${API.baseApiUrl}/ingredient/delete-ingredient`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

export default {
    fetchIngredientsList,
    updateIngredientName,
    updateIngredientDensity,
    updateIngredientPrice,
    updateIngredientCategory,
    deleteIngredient,
};
