import { call, put } from "redux-saga/effects";
import Api, { AddCategoryResponse, CategoriesResponse } from "api/categories";
import { notification } from "components/Notification/Notification";
import { addNewCategory, editCategory, fetchCategories } from "store/categories/categories.actions";
import { fetchRecipesList } from "store/RecipesList/RecipesList.actions";
import { fetchWorkingRecipesList } from "store/workingRecipe/workingRecipe.actions";
import { fetchRecipesHistory } from "store/recipesHistory/recipesHistory.actions";

export function* fetchCategoriesSaga(action: ReturnType<typeof fetchCategories.request>): Generator {
    try {
        const response = yield call(Api.fetchCategories, action.payload);
        const typedResponse = response as CategoriesResponse;
        yield put(fetchCategories.success({ categories: typedResponse.categories }));
    } catch (err: any) {
        notification(err.message, false);
        yield put(fetchCategories.failure(err));
    }
}

export function* addNewCategorySaga(action: ReturnType<typeof addNewCategory.request>): Generator {
    try {
        const response = yield call(Api.addCategory, action.payload);
        const typedResponse = response as AddCategoryResponse;
        yield put(
            addNewCategory.success({
                message: typedResponse.message,
                success: typedResponse.success,
            })
        );
        notification(typedResponse.message, typedResponse.success);
        yield put(fetchCategories.request());
    } catch (err: any) {
        notification(err.message, false);
        yield put(addNewCategory.failure(err));
    }
}

export function* editCategorySaga(action: ReturnType<typeof editCategory.request>): Generator {
    try {
        const response = yield call(Api.editCategory, action.payload);
        const typedResponse = response as AddCategoryResponse;
        yield put(
            editCategory.success({
                message: typedResponse.message,
                success: typedResponse.success,
            })
        );
        notification(typedResponse.message, typedResponse.success);
        yield put(fetchCategories.request());
        yield put(fetchRecipesList.request());
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchRecipesHistory.request());
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(editCategory.failure(response));
    }
}
