import styles from "./WarningNotification.module.scss";
import React from "react";

interface NotificationWarningProps {
    label: string;
    notificationContentClass?: string;
}

const WarningNotification: React.FC<NotificationWarningProps> = ({ label, notificationContentClass }) => {
    return (
        <span
            className={`${styles.notificationText} ${notificationContentClass}`}
            dangerouslySetInnerHTML={{ __html: label }}
        />
    );
};

export default WarningNotification;
