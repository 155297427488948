import { useDispatch } from "react-redux";
import React from "react";
import { verifyMe } from "../store/auth/auth.actions";
import { getAccessToken } from "../utils/utils";
import { fetchCategories } from "../store/categories/categories.actions";
import { fetchRecipesList } from "../store/RecipesList/RecipesList.actions";

export const useAppLandingPage = () => {
    const dispatch = useDispatch();
    const userToken = getAccessToken();

    React.useEffect(() => {
        if (userToken) {
            dispatch(verifyMe.request({ token: userToken }));
            dispatch(fetchCategories.request());
            dispatch(fetchRecipesList.request());
        }
    }, [userToken]);

    React.useEffect(() => {
        document.title = `${process.env.REACT_APP_NAME} receptury`;
    }, []);
};
