import { createAsyncAction } from "typesafe-actions";
import { SamplePropsResponse } from "../../api/sampleList";
import { ApiResponse } from "../recipeOperations/recipeOperations.actions";

export const fetchSampleList = createAsyncAction(
    "FETCH_SAMPLE_LIST_REQUEST",
    "FETCH_SAMPLE_LIST_SUCCESS",
    "FETCH_SAMPLE_LIST_FAILURE"
)<void, SamplePropsResponse, Error>();

export const deleteSample = createAsyncAction(
    "DELETE_SAMPLE_REQUEST",
    "DELETE_SAMPLE_SUCCESS",
    "DELETE_SAMPLE_FAILURE"
)<{ id: number }, void, Error>();

export const editSample = createAsyncAction(
    "EDIT_SAMPLE_REQUEST",
    "EDIT_SAMPLE_REQUEST_SUCCESS",
    "EDIT_SAMPLE_REQUEST_FAILURE"
)<SamplePropsResponse, void, Error>();

export const addNewSample = createAsyncAction("ADD_NEW_SAMPLE_REQUEST", "ADD_SAMPLE_SUCCESS", "ADD_SAMPLE_FAILURE")<
    SamplePropsResponse,
    ApiResponse,
    Error
>();
