import React from "react";
import { getAuthorizationState, getUserPrivileges } from "store/auth/auth.selector";
import { AppState } from "store/rootReducer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Recipes.module.scss";
import AddRecipeButton, { AddRecipeSource } from "../AddRecipeButton/AddRecipeButton";
import { getRecipesListState } from "store/RecipesList/RecipesList.selector";
import { getIngredientsListState } from "store/IngredientList/IngredientsList.selector";
import { IngredientsProps } from "api/ingredientsList";
import { RecipesProps } from "api/recipesList";
import RecipesList from "components/RecipesList/RecipesList";
import { RecipeSource } from "components/RecipeView/RecipeView";
import { frontMessages } from "../../utils/messages";

export interface RecipesComponentProps extends RouteComponentProps {
    recipesListState: RecipesProps[];
    ingredientsListState: IngredientsProps[];
    isAuthorized: boolean;
    hasEditorPrivileges: boolean;
    fetchRecipeList(): void;
}

class Recipes extends React.Component<RecipesComponentProps> {
    render() {
        return (
            <>
                <div>
                    {this.props.hasEditorPrivileges && (
                        <AddRecipeButton
                            addRecipeSource={AddRecipeSource.PRODUCTION}
                            recipeButtonTitle={frontMessages.button.addRecipe}
                        />
                    )}
                </div>
                <div className={styles.recipes}>
                    <RecipesList
                        recipesListState={this.props.recipesListState}
                        recipeSource={RecipeSource.MAIN_LIST}
                        isHistory={false}
                        isWorkingHistory={false}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        authorizationState: getAuthorizationState(state).isAuthorized,
        recipesListState: getRecipesListState(state).recipesList,
        ingredientsListState: getIngredientsListState(state).ingredients,
        hasEditorPrivileges: getUserPrivileges(state).hasEditorPrivileges,
    };
};

export default withRouter(connect(mapStateToProps, null)(Recipes));
