import { ActionType, createReducer } from "typesafe-actions";
import * as actions from ".//RecipesList.actions";
import { fetchRecipesList } from "store/RecipesList/RecipesList.actions";
import { RecipesProps } from "api/recipesList";
import { compareListItems } from "utils/utils";

export type RecipesListAction = ActionType<typeof actions>;

export type RecipesListState = Readonly<{
    recipesList: RecipesProps[];
    isLoading: boolean;
}>;

export const ingredientsListInitialState: RecipesListState = {
    isLoading: false,
    recipesList: [],
};

export const recipesListReducer = createReducer<RecipesListState, RecipesListAction>(ingredientsListInitialState)
    .handleAction(fetchRecipesList.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchRecipesList.success, (state, action) => {
        return {
            ...state,
            recipesList: action.payload.recipes.sort((firstItem, secondItem) =>
                compareListItems(secondItem.createdAt, firstItem.createdAt)
            ),
            isLoading: false,
        };
    })
    .handleAction(fetchRecipesList.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
