import React from "react";
import "assets/styles/variables.modules.scss";
import styles from "./ThemeSwitcher.module.scss";

const THEME = "THEME";

const lightTheme = {
    fontColor: "black",
    background: "lightgray",
    menuBackground: "#9898a6",
    buttonDefault: "#677C8CFF",
    iconBackground: "#677C8CFF",
    inputBorder: "black",
};

const darkTheme = {
    fontColor: "white",
    background: "#202124",
    menuBackground: `rgba(56, 64, 81, 0.7)`,
    buttonDefault: "lightgray",
    iconBackground: "lightgray",
    inputBorder: "#bdc6d8",
};

const ThemeSwitcher: React.FC = () => {
    const [isDark, setIsDark] = React.useState(true);

    const setLightTheme = () => {
        setIsDark(false);
        localStorage.setItem(THEME, "light");
        document.documentElement.style.setProperty("--dark-bg-color", lightTheme.background);
        document.documentElement.style.setProperty("--menu-background", lightTheme.menuBackground);
        document.documentElement.style.setProperty("--font-primary", lightTheme.fontColor);
        document.documentElement.style.setProperty("--button-default", lightTheme.buttonDefault);
        document.documentElement.style.setProperty("--icon-background", lightTheme.iconBackground);
        document.documentElement.style.setProperty("--input-border", lightTheme.inputBorder);
    };

    const setDarkTheme = () => {
        setIsDark(true);
        localStorage.setItem(THEME, "dark");
        document.documentElement.style.setProperty("--dark-bg-color", darkTheme.background);
        document.documentElement.style.setProperty("--menu-background", darkTheme.menuBackground);
        document.documentElement.style.setProperty("--font-primary", darkTheme.fontColor);
        document.documentElement.style.setProperty("--button-default", darkTheme.buttonDefault);
        document.documentElement.style.setProperty("--icon-background", darkTheme.iconBackground);
        document.documentElement.style.setProperty("--input-border", darkTheme.inputBorder);
    };

    React.useEffect(() => {
        const theme = localStorage.getItem(THEME);
        if (theme === "light") {
            setLightTheme();
        } else {
            setDarkTheme();
        }
    }, []);

    const changeColorTheme = () => {
        if (isDark) {
            setLightTheme();
        } else {
            setDarkTheme();
        }
    };

    return (
        <div
            className={`${styles.wrapper} ${isDark ? styles.wrapperDark : styles.wrapperLight}`}
            onClick={changeColorTheme}>
            <input
                type="checkbox"
                checked={isDark}
                id={"switcher"}
                className={styles.checkbox}
                onChange={changeColorTheme}
            />
            <label className={styles.switch} form={"switcher"} />
        </div>
    );
};

export default ThemeSwitcher;
