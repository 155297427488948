import React from "react";
import styles from "./RecipeIngredientAutocomplete.module.scss";
import { NewRecipeState } from "../../RecipeFormCreator/RecipeFormCreator";
import { useSelector } from "react-redux";
import { getIngredientsListState } from "../../../store/IngredientList/IngredientsList.selector";
import { getWorkingIngredientsListState } from "../../../store/workingIngredients/WorkingIngredientList.selector";
interface RecipeIngredientAutocompleteProps {
    setInputList: React.Dispatch<React.SetStateAction<NewRecipeState[]>>;
    inputList: NewRecipeState[];
    index: number;
}
const RecipeIngredientAutocomplete: React.FC<RecipeIngredientAutocompleteProps> = ({
    inputList,
    index,
    setInputList,
}) => {
    const { ingredients } = useSelector(getIngredientsListState);
    const { workingIngredients } = useSelector(getWorkingIngredientsListState);

    const shouldDisplayAutocomplete = inputList[index].isActive && inputList[index].ingredientName.length > 1;
    const setAutocompleteValue = (ingredientName: string, index: number, ingredientPrice?: string) => () => {
        const ingredientList = [...inputList];
        ingredientList[index].ingredientPrice = ingredientPrice ? ingredientPrice : "0";
        ingredientList[index].ingredientName = ingredientName;
        ingredientList[index].isActive = false;
        setInputList(ingredientList);
    };

    if (!shouldDisplayAutocomplete) {
        return null;
    }

    const list = ingredients.concat(workingIngredients);

    return (
        <ul className={styles.autocomplete}>
            {list
                .filter((item) =>
                    item.ingredient.includes(
                        inputList[index].ingredientName.toUpperCase() || inputList[index].ingredientName
                    )
                )
                .map((item) => {
                    return (
                        <li key={item.id} onMouseDown={setAutocompleteValue(item.ingredient, index, item.price)}>
                            <span className={styles.autocompleteValue}>
                                {item.ingredientSource === "workingIngredient"
                                    ? `${item.ingredient}*`
                                    : item.ingredient}
                            </span>
                        </li>
                    );
                })}
        </ul>
    );
};

export default RecipeIngredientAutocomplete;
