import React from "react";
import { RecipeSource } from "../RecipeView/RecipeView";
import { IconType } from "../Icons/Icons";
import HeaderTitleWithIcon from "../HeaderTitleWithIcon/HeaderTitleWithIcon";
interface ListHeaderProps {
    recipeSource: RecipeSource;
}
const ListHeaderTitle: React.FC<ListHeaderProps> = ({ recipeSource }) => {
    const matchIcon = () => {
        const iconStrategy = {
            [RecipeSource.HISTORY_LIST]: () => IconType.HISTORY,
            [RecipeSource.WORKING_HISTORY_LIST]: () => IconType.HISTORY,
            [RecipeSource.WORKING_LIST]: () => IconType.WORKING,
            [RecipeSource.MAIN_LIST]: () => IconType.LIST,
            [RecipeSource.EMPTY]: () => IconType.LIST,
        };

        if (iconStrategy[recipeSource]) {
            return iconStrategy[recipeSource]();
        }

        return IconType.LIST;
    };

    const matchTitle = () => {
        const titleStrategy = {
            [RecipeSource.MAIN_LIST]: () => "RECEPTURY PRODUKCYJNE",
            [RecipeSource.WORKING_LIST]: () => "RECEPTURY LABORATORYJNE",
            [RecipeSource.HISTORY_LIST]: () => "HISTORIA RECEPTUR",
            [RecipeSource.WORKING_HISTORY_LIST]: () => "HISTORIA ROBOCZYCH",
            [RecipeSource.EMPTY]: () => "",
        };

        if (titleStrategy[recipeSource]) {
            return titleStrategy[recipeSource]();
        }

        return "RECEPTURY PRODUKCYJNE";
    };

    return <HeaderTitleWithIcon title={matchTitle()} iconType={matchIcon()} />;
};

export default ListHeaderTitle;
