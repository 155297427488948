import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./userManagement.actions";
import { UserManagementProps } from "api/userManagement";
import { fetchUserList } from "./userManagement.actions";

export type UserListAction = ActionType<typeof actions>;

export type UserListState = Readonly<{
    users: UserManagementProps[];
    isLoading: boolean;
}>;

export const ingredientsListInitialState: UserListState = {
    isLoading: false,
    users: [],
};

export const userListReducer = createReducer<UserListState, UserListAction>(ingredientsListInitialState)
    .handleAction(fetchUserList.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchUserList.success, (state, action) => {
        return {
            ...state,
            users: action.payload.users,
            isLoading: false,
        };
    })
    .handleAction(fetchUserList.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
