import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./workingRecipe.actions";
import { RecipesProps } from "api/recipesList";
import { compareListItems } from "utils/utils";
import { fetchWorkingRecipesList } from "./workingRecipe.actions";

export type WorkingRecipesListAction = ActionType<typeof actions>;

export type WorkingRecipesListState = Readonly<{
    workingRecipes: RecipesProps[];
    isLoading: boolean;
}>;

export const ingredientsListInitialState: WorkingRecipesListState = {
    isLoading: false,
    workingRecipes: [],
};

export const workingRecipesListReducer = createReducer<WorkingRecipesListState, WorkingRecipesListAction>(
    ingredientsListInitialState
)
    .handleAction(fetchWorkingRecipesList.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchWorkingRecipesList.success, (state, action) => {
        return {
            ...state,
            workingRecipes: action.payload.workingRecipes.sort((firstItem, secondItem) =>
                compareListItems(secondItem.createdAt, firstItem.createdAt)
            ),
            isLoading: false,
        };
    })
    .handleAction(fetchWorkingRecipesList.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
