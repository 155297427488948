import API, { get } from "api/api";

export interface RecipesListResponse {
    recipes: RecipesProps[];
}

export interface RecipesProps {
    ingredients: Ingredient[];
    id: number;
    company: string;
    recipeNumber: string;
    defaultValue: string;
    recipeName: string;
    density: string;
    hardener: string;
    recipeDensity: string;
    viscosity: string;
    recipeSum: number;
    userName: string;
    createdAt: string;
    updatedAt: string;
    category: string;
    actionType?: string;
    actionDescription: string;
}

export interface Ingredient {
    value: number | string;
    description?: string;
    name: string;
    price?: string;
    density?: string;
}

const fetchRecipesList = (credentials: any) =>
    get<RecipesListResponse>(`${API.baseApiUrl}/recipeList`, credentials).then(({ parsedBody }) => parsedBody);

export default {
    fetchRecipesList,
};
