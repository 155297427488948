import React from "react";
import { Sorting } from "../services/filter.services";

export const useSorting = (isEmptyInitial: boolean) => {
    const sorting = isEmptyInitial ? Sorting.NONE : Sorting.DESCENDING;
    const [sortBy, setSortBy] = React.useState(sorting);
    const handleSortBy = () =>
        sortBy === Sorting.ASCENDING ? setSortBy(Sorting.DESCENDING) : setSortBy(Sorting.ASCENDING);

    return {
        sortBy,
        setSortBy,
        handleSortBy,
    };
};
