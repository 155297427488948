import React, { useEffect } from "react";
import styles from "./IngredientsHistory.module.scss";
import { frontMessages } from "utils/messages";
import { fetchIngredientsHistory } from "../../store/ingredientsHistory/IngredientsHistory.actions";
import { useDispatch, useSelector } from "react-redux";
import { getIngredientsHistoryState } from "../../store/ingredientsHistory/IngredientsHistory.selector";
import { useSearcher } from "../../hooks/useSearcher";
import { usePagination } from "../../hooks/usePagination";
import { useDateFilter } from "../../hooks/useDateFilter";
import Icons, { IconType } from "../Icons/Icons";
import { useSorting } from "../../hooks/useSorting";
import { sortByDate } from "../../services/filter.services";
import HeaderTitleWithIcon from "../HeaderTitleWithIcon/HeaderTitleWithIcon";
import { getUserPrivileges } from "../../store/auth/auth.selector";

const IngredientsHistoryList: React.FC = () => {
    const dispatch = useDispatch();
    const { ingredientsHistory } = useSelector(getIngredientsHistoryState);
    const { searchInput, searcher } = useSearcher();
    const { filterHistory, searchStartDateComponent, searchEndDateComponent } = useDateFilter();
    const { handleSortBy, sortBy } = useSorting(false);
    const { hasPricePrivileges } = useSelector(getUserPrivileges);
    const ingredientList = ingredientsHistory
        .filter((item) => filterHistory(item, searchInput))
        .sort((firstItem, secondItem) => sortByDate(firstItem.createdAt, secondItem.createdAt, sortBy));
    const { paginationComponent, offset, PER_PAGE } = usePagination(ingredientList.length);

    useEffect(() => {
        if (!ingredientsHistory?.length) {
            dispatch(fetchIngredientsHistory.request());
        }
    }, []);

    return (
        <div className={styles.history}>
            <div className={styles.historyTitle}>
                <HeaderTitleWithIcon title={"HISTORIA SKŁADNIKÓW"} iconType={IconType.HISTORY} />
                <div>
                    <span>Ilość akcji: </span>
                    <span className="bold">{ingredientList.length}</span>
                </div>
                <div className={styles.historyTitle}>
                    <div>{searcher}</div>
                    <div className={styles.historyTitleDate}>{searchStartDateComponent}</div>
                    <div className={styles.historyTitleDate}>{searchEndDateComponent}</div>
                </div>
            </div>
            <div>
                <div className={styles.historyHeader}>
                    <span className={styles.historyIndex}>{frontMessages.history.index}</span>
                    <span className={styles.historyName}>{frontMessages.history.ingredient}</span>
                    <span className={styles.historyDate} onClick={handleSortBy}>
                        {frontMessages.history.data}
                        <Icons iconType={IconType.SORT} styles={styles.historyDateIcon} />
                    </span>
                    <span className={styles.historyUser}>{frontMessages.history.user}</span>
                    <span className={styles.historyDescriptionHeader}>{frontMessages.history.action}</span>
                </div>
                <ul>
                    {ingredientList.slice(offset, offset + PER_PAGE).map((item, index) => {
                        return (
                            <li className={styles.historyListItemWrapper} key={index}>
                                <span className={styles.historyIndex}>{index + 1 + offset}.</span>
                                <span className={styles.historyName}>{item.ingredient}</span>
                                <span className={styles.historyDate}>{item.createdAt.slice(0, 10)} </span>
                                <span className={styles.historyUser}>{item.userName}</span>
                                <span className={styles.historyDescription}>
                                    {item.actionType.includes("price") && !hasPricePrivileges
                                        ? "Zmieniono cenę składnika"
                                        : item.actionDescription}
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {paginationComponent}
        </div>
    );
};

export default IngredientsHistoryList;
