export const frontMessages = {
    history: {
        index: "lp.",
        data: "Data",
        user: "Użytkownik",
        ingredient: "Składnik",
        action: "Akcja",
    },
    loginUser: "Zaloguj",
    createNewAccount: "Załóż nowe konto",
    edit: "Edytuj uprawnienia",
    login: {
        label: "Podaj login",
        errorMessage: "Login jest wymagany",
    },
    email: {
        label: "Podaj emial",
        errorMessage: "Email jest wymagany",
    },
    password: {
        label: "Podaj hasło",
        errorMessage: "Hasło jest wymagane",
    },
    registerPassword: {
        label: "Podaj hasło administratora",
        errorMessage: "Hasło administratora jest wymagane",
    },
    privileges: {
        viewer: "Uprawnienia podstawowe",
        editor: "Uprawnienia edycji",
        superUser: "Admin",
        price: "Uprawnienia cen",
        owner: "Uprawnienia właściciela",
        blocked: "Zablokuj użytkownika",
        privilegesError: "Uprawnienia są wymagane",
    },
    placeholders: {
        inputCategoryName: "Podaj nazwę kategorii",
        newCategoryName: "Podaj nową nazwę kategorii",
        newIngredientName: "Podaj nową nazwę składnika",
        IngredientName: "Wpisz nazwę składnika",
        changePrice: "Zmień cenę",
        changeCategory: "Dodaj / zmień kategorię",
        changeDensity: "Zmień gęstość",
        ingredientValue: "Wpisz ilość",
        companyName: "Podaj nazwę firmy",
        hardener: "Podaj utwardzacz",
        viscosity: "Podaj oczekiwaną lepkość",
        density: "Podaj oczekiwaną gęstoć",
    },
    button: {
        add: "DODAJ",
        addRecipe: "DODAJ RECEPTURĘ",
        addWorkingRecipe: "DODAJ RECEPTURĘ ROBOCZĄ",
        addCategory: "DODAJ KATEGORIĘ",
        addSample: "DODAJ PRÓBKĘ",
        addProject: "DODAJ PROJEKT",
        addUser: "DODAJ UŻYTKOWNIKA",
        saveRecipe: "ZAPISZ RECEPTURĘ",
        saveRecipeFromWorking: "DODAJ RECEPTURĘ ROBOCZĄ DO RECEPTUR PRODUKCYJNYCH",
        saveWorkingRecipe: "DODAJ JAKO RECEPTURĘ ROBCZĄ",
        saveAnotherWorkingRecipe: "DODAJ JAKO INNĄ RECEPTURĘ ROBCZĄ",
        addIngredient: "DODAJ SKŁADNIK",
        editRecipe: "EDYTUJ RECEPTURĘ",
        editSample: "EDYTUJ PRÓBKĘ",
        printWorkingRecipe: "WYDRUKUJ RECEPTURĘ ROBOCZĄ",
        printProductionRecipe: "WYDRUKUJ RECEPTURĘ PRODUKCYJNĄ",
        save: "ZAPISZ",
        edit: "EDYTUJ",
        cancel: "ANULUJ",
        close: "ZAMKNIJ",
        change: "ZMIEŃ",
        backToList: "POWRÓT",
        delete: "USUŃ",
        deleteWorking: "USUŃ RECEPTURĘ ROBOCZĄ",
        editWorkingRecipe: "EDYTUJ RECEPTURĘ ROBOCZĄ",
        saveEditWorkingRecipe: "ZAPISZ RECEPTURĘ ROBOCZĄ",
    },
    addRecipe: {
        executive: "Wykonawca:",
        startDate: "Data rozpoczęcia:",
        endDate: "Data zakończenia:",
        form: {
            recipeNumber: "Numer receptury",
            recipeErrorRequired: "Pole nie może być puste",
            recipeErrorRequiredShort: "Pole wymagane",
            recipeName: "Nazwa receptury",
            category: "Kategoria",
            minRequired: "Wymagane minimum 5 znaków",
        },
    },
    sample: {
        form: {
            sampleName: "Nazwa próbki",
            recipeErrorRequired: "Pole nie może być puste",
            supplier: "Dostawca",
            description: "Opis",
            placement: "Umiejscowienie",
            minRequired: "Wymagane minimum 5 znaków",
        },
    },
    ingredients: {
        price: "Cena",
        count: "Ilość wystąpień",
        density: "gęstość",
        name: "Składnik: ",
        remove: "Usuń składnik",
        handleName: "Zarządzaj nazwą składnika",
        handlePrice: "Zarządzaj ceną składnika",
        handleCategory: "Zarządzaj kategorią składnika",
        category: "kategoria",
        handleDensity: "Zarządzaj gęstością składnika",
        changeNameDescription:
            "Zmień nawę wybranego składnika na nową wartość. Nazwa składnika zostanie zmieniona globalnie we wszystich recepturach.",
        changePriceDescription:
            "Zmień cene wybranego składnika na nową wartość. Cena składnika zostanie zmieniona globalnie we wszystich receptrurach.",
        changeCategoryDescription: "Dodaj kategorię składnika.",
        changeDensityDescription:
            "Zmień gęstość wybranego składnika na nową wartość. Gęstość składnika zostanie zmieniona globalnie we wszystich receptrurach.",
        removeDescription:
            "Usuwa składnik z bazy danych, ta możliwość jest dostępna tylko gdy nie jest wykorzystywany w żądnej recepturze.",
        workingName: "Skladniki robocze",
    },
    nav: {
        recipes: "RECEPTURY",
        productionRecipes: "PRODUKCYJNE",
        ingredientsList: "SKŁADNIKI",
        workingIngredientList: "SKŁADNIKI ROBOCZE",
        history: "HISTORIA",
        workingRecipes: "ROBOCZE",
        users: "UŻYTKOWNICY",
        categories: "KATEGORIE",
        others: "INNE",
        recipesHistory: "HISTORIA PRODUKCYJNYCH",
        recipesHistoryWorking: "HISTORIA ROBOCZYCH",
        userManagement: "ZARZĄDZAJ UŻYTKOWNIKAMI",
        ingredientsHistory: "HISTORIA SKŁADNIKÓW",
        samples: "PRÓBY",
        projects: "PROJEKTY",
    },
};
