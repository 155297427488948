import React from "react";
import { useSelector } from "react-redux";
import { getAuthorizationState } from "../../store/auth/auth.selector";

export const useRecipeViewContent = () => {
    const [sum, setRecipeSum] = React.useState(0);
    const [productionPurchase, setProductionPurchase] = React.useState("");
    const [valuesToPrint, setValueToPrint] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { userName } = useSelector(getAuthorizationState);
    const handleSumChange = (value: any) => setRecipeSum(value);
    const closeModal = () => setIsModalOpen(false);
    const openModal = () => setIsModalOpen(true);
    const handleProductionPurchase = (value: string) => setProductionPurchase(value);
    return {
        userName,
        isModalOpen,
        handleSumChange,
        closeModal,
        openModal,
        valuesToPrint,
        sum,
        setRecipeSum,
        setValueToPrint,
        handleProductionPurchase,
        productionPurchase,
    };
};
