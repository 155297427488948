import React from "react";
import styles from "./ProjectCreatorButtons.module.scss";
import { projectsMessages } from "../../projectsMessages";
import Icons, { IconType } from "../../../Icons/Icons";

interface ProjectCreatorButtonsProps {
    isProjectEditor: boolean;
    handleDeleteProject: any;
}

const ProjectCreatorButtons: React.FC<ProjectCreatorButtonsProps> = ({ isProjectEditor, handleDeleteProject }) => {
    return (
        <div className={styles.button}>
            {!isProjectEditor ? (
                <button type="submit" className="btn btn--default">
                    <span>{projectsMessages.buttons.addpProject}</span>
                    <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                </button>
            ) : (
                <>
                    <button type="submit" className="btn btn--default btn--green">
                        <span>{projectsMessages.buttons.saveProject}</span>
                        <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                    </button>
                    <button onClick={handleDeleteProject} type="button" className="btn btn--default btn--red">
                        <span>{projectsMessages.buttons.deleteProject}</span>
                        <Icons iconType={IconType.TRASH} styles={"icon-button"} />
                    </button>
                </>
            )}
        </div>
    );
};

export default ProjectCreatorButtons;
