import { RecipesProps } from "api/recipesList";
import { compareListItems } from "utils/utils";
import { SampleProps } from "../api/sampleList";
import { ProjectProps } from "../api/projects";

export enum Sorting {
    DESCENDING = "DESCENDING",
    ASCENDING = "ASCENDING",
    NONE = "NONE",
}

export const sortByDate = (firstItemCreatedAt: string, secondItemCreatedAt: string, sortBy: Sorting) => {
    if (sortBy === Sorting.DESCENDING) {
        return compareListItems(secondItemCreatedAt, firstItemCreatedAt);
    }

    return compareListItems(firstItemCreatedAt, secondItemCreatedAt);
};

export const sortByName = (firstItemName: string, secondItemName: string, sortBy: Sorting) => {
    if (sortBy === Sorting.NONE) {
        return 0;
    }

    if (sortBy === Sorting.DESCENDING) {
        return secondItemName?.localeCompare(firstItemName);
    }

    return firstItemName?.localeCompare(secondItemName);
};

export const sortByNumber = (firstNumber: number, secondNumber: number, sortBy: Sorting) => {
    if (sortBy === Sorting.NONE) {
        return 0;
    }

    if (sortBy === Sorting.DESCENDING) {
        return compareListItems(secondNumber, firstNumber);
    }

    return compareListItems(firstNumber, secondNumber);
};

const filterByNameOrNumber = (
    item: RecipesProps,
    searchInput: string,
    searchStartDate: string,
    searchEndDate: string,
    isHistory: boolean
) => {
    const searchedValue = searchInput.toLowerCase();

    if (searchInput !== "" && searchInput.length > 2) {
        if (isHistory) {
            const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
            const setEndDate = searchEndDate === "" ? currentDate : searchEndDate;

            return (
                (item.recipeName.toLowerCase().includes(searchedValue) ||
                    item.recipeNumber.toLowerCase().includes(searchedValue)) &&
                item.createdAt >= searchStartDate &&
                item.createdAt <= setEndDate
            );
        }

        return (
            item.recipeName.toLowerCase().includes(searchedValue) ||
            item.recipeNumber.toLowerCase().includes(searchedValue)
        );
    }

    if (searchInput === "" && isHistory) {
        const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
        const setEndDate = searchEndDate === "" ? currentDate : searchEndDate;

        return item.createdAt >= searchStartDate && item.createdAt <= setEndDate;
    }

    return item;
};

export const filterRecipes = (
    item: RecipesProps,
    categorySelect: string,
    searchInput: string,
    searchStartDate: string,
    searchEndDate: string,
    isHistory: boolean
) => {
    const filteredValues = filterByNameOrNumber(item, searchInput, searchStartDate, searchEndDate, isHistory);

    if (categorySelect !== "") {
        return categorySelect.includes(item.category) && filteredValues;
    }

    return item && filteredValues;
};

export const filterSamples = (
    item: SampleProps,
    searchInput: string,
    selectedValue: string,
    searchStartDate: string,
    searchEndDate: string
) => {
    const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
    const setEndDate = searchEndDate === "" ? currentDate : searchEndDate;

    if (selectedValue) {
        const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];
        return getKeyValue(selectedValue)(item).toLowerCase().includes(searchInput);
    }

    return (
        (item.sampleName.toLowerCase().includes(searchInput) &&
            item.createdAt >= searchStartDate &&
            item.createdAt <= setEndDate) ||
        (item.supplier.toLowerCase().includes(searchInput) &&
            item.createdAt >= searchStartDate &&
            item.createdAt <= setEndDate)
    );
};

export const filterProjects = (
    item: ProjectProps,
    searchInput: string,
    selectedValue: string,
    searchStartDate: string,
    searchEndDate: string
) => {
    const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
    const setEndDate = searchEndDate === "" ? currentDate : searchEndDate;

    if (selectedValue) {
        return selectedValue === item.status;
    }

    return (
        item.projectDescription.toLowerCase().includes(searchInput) &&
        item.createdAt >= searchStartDate &&
        item.createdAt <= setEndDate
    );
};
