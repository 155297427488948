import React from "react";
import styles from "./RecipesList.module.scss";
import { RecipesProps } from "api/recipesList";
import { filterRecipes, sortByDate } from "services/filter.services";
import { useSelector } from "react-redux";
import { getCategories } from "store/categories/categories.selector";
import { Link } from "react-router-dom";
import { RecipeSource } from "components/RecipeView/RecipeView";
import Icons, { IconType } from "../Icons/Icons";
import { usePagination } from "../../hooks/usePagination";
import { useSearcher } from "../../hooks/useSearcher";
import { useDateFilter } from "../../hooks/useDateFilter";
import { useSorting } from "../../hooks/useSorting";
import { useSelect } from "../../hooks/useSelect";
import { generateLinkToRecipeView } from "./recipeList.helper";
import RecipeListHeader from "./RecipeListHeader/RecipeListHeader";
import { useIsInMobileView } from "../../hooks/useIsInMobileView";

interface RecipesListProps {
    recipesListState: RecipesProps[];
    isHistory: boolean;
    isWorkingHistory: boolean;
    recipeSource: RecipeSource;
}

const RecipesList: React.FC<RecipesListProps> = ({ recipesListState, isHistory, recipeSource, isWorkingHistory }) => {
    const { categories } = useSelector(getCategories);
    const { searchInput, searcher } = useSearcher();
    const { selectedValue, selectComponent } = useSelect(categories);
    const { handleSortBy, sortBy } = useSorting(false);
    const { searchEndDateComponent, searchStartDateComponent, searchStartDate, searchEndDate } = useDateFilter();
    const { isMobile } = useIsInMobileView();
    const list = recipesListState
        .filter((item) => filterRecipes(item, selectedValue, searchInput, searchStartDate, searchEndDate, isHistory))
        .sort((firstItem, secondItem) => sortByDate(firstItem.createdAt, secondItem.createdAt, sortBy));
    const { PER_PAGE, offset, paginationComponent } = usePagination(list.length);

    return (
        <div>
            <RecipeListHeader
                recipesListState={list}
                recipeSource={recipeSource}
                searcher={searcher}
                selectComponent={selectComponent}
                searchEndDateComponent={searchEndDateComponent}
                searchStartDateComponent={searchStartDateComponent}
                isHistory={isHistory}
            />
            <div className={styles.list}>
                <div className={styles.listHeader}>
                    <span className={styles.listIndex}>lp.</span>
                    <span className={styles.listNumber}>Numer</span>
                    <span className={styles.listName}>Nazwa</span>
                    {!isMobile && <span className={styles.listCategory}>Kategoria</span>}
                    {!isMobile && <span className={styles.listCounts}>ilość skł.</span>}
                    {!isMobile && (
                        <span onClick={handleSortBy} className={styles.listCreated}>
                            Utworzono
                            <Icons iconType={IconType.SORT} styles={styles.listIcon} />
                        </span>
                    )}
                    <span className={styles.listEdited}>Edytowano</span>
                    {!isMobile && <span className={styles.listAuthor}>Autor</span>}
                </div>
            </div>
            <ul>
                {list.slice(offset, offset + PER_PAGE).map((recipe, index) => {
                    return (
                        <li className={styles.listWrapper} key={index}>
                            <Link to={generateLinkToRecipeView(recipe, recipeSource, isWorkingHistory)}>
                                <div className={styles.listListItem}>
                                    <span className={styles.listIndex}>{index + 1 + offset + ". "}</span>
                                    <span className={styles.listNumber}>{recipe.recipeNumber}</span>
                                    <span className={styles.listName}>{recipe.recipeName}</span>
                                    {!isMobile && <span className={styles.listCategory}>{recipe.category}</span>}
                                    {!isMobile && (
                                        <span className={styles.listCounts}>{recipe.ingredients?.length}</span>
                                    )}
                                    {!isMobile && (
                                        <span className={styles.listCreated}>{recipe.createdAt.slice(0, 10)}</span>
                                    )}
                                    <span className={styles.listEdited}>{recipe.updatedAt.slice(0, 10)}</span>
                                    {!isMobile && <span className={styles.listAuthor}>{recipe.userName}</span>}
                                </div>
                                {isHistory && (
                                    <div className={styles.listHistory}>
                                        <div className={styles.listHistoryDescription}>
                                            <div className={styles.listHistoryDescriptionTitle}>opis: </div>
                                            <span>{recipe.actionDescription}</span>
                                        </div>
                                    </div>
                                )}
                            </Link>
                        </li>
                    );
                })}
            </ul>
            {paginationComponent}
        </div>
    );
};

export default RecipesList;
