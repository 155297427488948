import React from "react";
import styles from "./RecipeView.module.scss";
import { useForm } from "react-hook-form";
import { RecipesProps } from "../../api/recipesList";
import { RecipeActionType } from "../../utils/forms.helper";
import PrintRecipeModal from "../RecipeFormCreator/RecipeCreatorModals/PrintRecipeModal";
import RecipeViewFooter from "./RecipeViewFooter/RecipeViewFooter";
import { useRecipeViewContent } from "./useRecipeView";
import RecipeViewFooterButton from "./RecipeViewFooterButtons/RecipeViewFooterButton";
import { useHistory } from "react-router-dom";
import { getRecipeSource } from "./recipeView.helper";
import RecipeViewPrintHeader from "./RecipeViewPrintHeader/RecipeViewPrintHeader";
import RecipeViewHeader from "./RecipeViewHeader/RecipeViewHeader";
import RecipeViewPrintFooter from "./RecipeViewPrintFooter/RecipeViewPrintFooter";
import RecipeViewList from "./RecipeViewList/RecipeViewList";

export enum RecipeSource {
    MAIN_LIST = "mainList",
    WORKING_LIST = "workingList",
    HISTORY_LIST = "historyList",
    WORKING_HISTORY_LIST = "workingHistoryList",
    EMPTY = "'",
}

interface RecipeViewProps {
    selectedRecipe: RecipesProps;
    recipeSource: RecipeSource;
    onNextStep: any;
}

const RecipeView: React.FC<RecipeViewProps> = ({ onNextStep, selectedRecipe, recipeSource }) => {
    const { location } = useHistory();
    const source = recipeSource || getRecipeSource(location.pathname);
    const isWorkingRecipe = source === RecipeSource.WORKING_LIST;
    const isMainRecipe = source === RecipeSource.MAIN_LIST;
    const { register, handleSubmit } = useForm<FormData>();
    const componentRef = React.useRef() as any;
    const {
        sum,
        valuesToPrint,
        userName,
        closeModal,
        openModal,
        handleSumChange,
        setRecipeSum,
        isModalOpen,
        setValueToPrint,
        productionPurchase,
        handleProductionPurchase,
    } = useRecipeViewContent();

    React.useEffect(() => {
        if (selectedRecipe?.recipeSum) {
            setRecipeSum(selectedRecipe.recipeSum);
        }
        // eslint-disable-next-line
    }, [selectedRecipe]);

    if (!selectedRecipe) {
        return null;
    }

    const onSubmit = () =>
        handleSubmit((values: any) => {
            const reformatValues = {
                ...selectedRecipe,
                userName: userName,
                actionType: RecipeActionType.PRINT,
                recipeSum: values.recipeSum,
                id: selectedRecipe.id,
            };

            setValueToPrint(reformatValues as any);
        });

    return (
        <div className={styles.print} ref={componentRef}>
            <div className={styles.wrapper}>
                <RecipeViewPrintHeader
                    selectedRecipe={selectedRecipe}
                    sum={sum}
                    productionPurchase={productionPurchase}
                />
                <div className={styles.recipe}>
                    <RecipeViewHeader selectedRecipe={selectedRecipe} />
                    <RecipeViewList
                        sum={sum}
                        selectedRecipe={selectedRecipe}
                        register={register}
                        onSubmit={onSubmit}
                        handleSumChange={handleSumChange}
                        isWorkingRecipe={isWorkingRecipe}
                        isMainRecipe={isMainRecipe}
                    />
                    <div className={styles.brakePage} />
                    <RecipeViewFooter selectedRecipe={selectedRecipe} />
                </div>
            </div>
            <RecipeViewPrintFooter />
            <div className={styles.buttons}>
                {isMainRecipe && isModalOpen && (
                    <PrintRecipeModal
                        modalIsOpen={isModalOpen}
                        valuesToPrint={valuesToPrint}
                        componentRef={componentRef}
                        productionPurchase={productionPurchase}
                        handleProductionPurchase={handleProductionPurchase}
                        closeModal={closeModal}
                    />
                )}
                <RecipeViewFooterButton
                    componentRef={componentRef}
                    onNextStep={onNextStep}
                    isMainRecipe={isMainRecipe}
                    isWorkingRecipe={isWorkingRecipe}
                    openModal={openModal}
                />
            </div>
        </div>
    );
};

export default RecipeView;
