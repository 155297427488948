import React from "react";
import { Route, RouteProps } from "react-router";
import MainView from "../MainView/MainView";

export interface ProtectedRouteProps extends RouteProps {
    isAuthorized: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthorized, ...props }) => {
    if (!isAuthorized) {
        return (
            <Route
                {...props}
                component={() => {
                    return (
                        <div className="content">
                            <MainView />
                        </div>
                    );
                }}
            />
        );
    } else {
        return <Route {...props} />;
    }
};
