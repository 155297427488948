import React from "react";
import { UserManagementProps } from "../../../api/userManagement";
import { useForm } from "react-hook-form";
import styles from "./EditUserPrivilages.module.scss";
import Input from "../../Input/Input";
import { FieldTitle, FieldType } from "../../../utils/forms.helper";
import { frontMessages } from "../../../utils/messages";
import { prepareUserRoles, UserPrivileges } from "../users.helper";
import { useDispatch } from "react-redux";
import { editUser } from "../../../store/userManagement/userManagement.actions";

interface EditUserPrivilegesProps {
    handleCloseEditForm(): void;
    selectedUser?: UserManagementProps;
}

type FormData = {
    viewer: boolean;
    editor: boolean;
    owner: boolean;
    price: boolean;
    isBlocked: boolean;
};

const EditUserPrivileges: React.FC<EditUserPrivilegesProps> = ({ handleCloseEditForm, selectedUser }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm<FormData>();
    const onSubmit = handleSubmit((values) => {
        const reformattedValues = {
            isBlocked: values.isBlocked,
            roles: prepareUserRoles(values),
            id: selectedUser ? selectedUser.id : 0,
        };

        console.log(reformattedValues);
        dispatch(editUser.request(reformattedValues));
        handleCloseEditForm();
    });

    return (
        <div className={styles.wrapper}>
            <form onSubmit={onSubmit} className={styles.formWrapper} autoComplete="off" key={selectedUser?.id}>
                <div className={styles.wrapperName}>{selectedUser?.username}</div>
                <div className={styles.wrapperEmail}>{selectedUser?.email}</div>
                <Input
                    name={FieldTitle.VIEWER_PRIVILEGE}
                    required={true}
                    isChecked={selectedUser?.roles.some((item) => item === UserPrivileges.VIEWER)}
                    label={frontMessages.privileges.viewer}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.privileges.privilegesError}
                    errors={errors.viewer}
                />
                <Input
                    name={FieldTitle.EDITOR_PRIVILEGE}
                    required={false}
                    isChecked={selectedUser?.roles.some((item) => item === UserPrivileges.EDITOR)}
                    label={frontMessages.privileges.editor}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.registerPassword.errorMessage}
                    errors={errors.editor}
                />
                <Input
                    name={FieldTitle.PRICE_PRIVILEGE}
                    required={false}
                    isChecked={selectedUser?.roles.some((item) => item === UserPrivileges.PRICE)}
                    label={frontMessages.privileges.price}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.privileges.privilegesError}
                    errors={errors.price}
                />
                <Input
                    name={FieldTitle.OWNER_PRIVILEGE}
                    required={false}
                    isChecked={selectedUser?.roles.some((item) => item === UserPrivileges.OWNER)}
                    label={frontMessages.privileges.owner}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.registerPassword.errorMessage}
                    errors={errors.owner}
                />
                <Input
                    name={FieldTitle.IS_BLOCKED}
                    required={false}
                    isChecked={selectedUser?.isBlocked}
                    label={frontMessages.privileges.blocked}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.registerPassword.errorMessage}
                    errors={errors.isBlocked}
                />
                <button className={`btn btn--green ${styles.wrapperButton}`} type="submit">
                    {frontMessages.edit}
                </button>
            </form>
            <button className={`btn--black ${styles.wrapperButton}`} onClick={handleCloseEditForm} type="button">
                {frontMessages.button.close}
            </button>
        </div>
    );
};

export default EditUserPrivileges;
