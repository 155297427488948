import React from "react";
import { useSelector } from "react-redux";
import { getWorkingIngredientsListState } from "../../../store/workingIngredients/WorkingIngredientList.selector";
import { getIngredientsListState } from "../../../store/IngredientList/IngredientsList.selector";
import { setPrice } from "../../../utils/utils";

interface RecipeIngredientPriceProps {
    item: any;
    registerForm: any;
    fieldName: string;
    index: number;
    handleChangeIngredients(index: number, event: any): void;
}

const RecipeIngredientPrice: React.FC<RecipeIngredientPriceProps> = ({
    registerForm,
    item,
    fieldName,
    index,
    handleChangeIngredients,
}) => {
    const { workingIngredients } = useSelector(getWorkingIngredientsListState);
    const { ingredients } = useSelector(getIngredientsListState);

    const workingPrice = workingIngredients.find((ingredient) => ingredient.ingredient === item.ingredientName);
    const productionPrice = ingredients.find((ingredient) => ingredient.ingredient === item.ingredientName);
    const price = setPrice(item.ingredientPrice, workingPrice?.price || "0", productionPrice?.price || "0");

    if (price !== "0") {
        return <span>{(Number(price) * (item.ingredientValue / 100)).toFixed(3)}</span>;
    }

    return (
        <input
            name={`${fieldName}.price`}
            placeholder="cena"
            type="number"
            ref={registerForm}
            onBlur={(event) => handleChangeIngredients(index, event)}
        />
    );
};

export default RecipeIngredientPrice;
