import { createAsyncAction } from "typesafe-actions";
import {
    AddNewRecipeResponseProps,
    ApiResponse,
    RecipeCreatorFormProps,
} from "store/recipeOperations/recipeOperations.actions";
import { WorkingRecipesListResponse } from "api/workingRecipe";

export const addNewWorkingRecipe = createAsyncAction(
    "ADD_NEW_WORKING_RECIPE_REQUEST",
    "ADD_NEW_WORKING_RECIPE_SUCCESS",
    "ADD_NEW_WORKING_RECIPE_FAILURE"
)<RecipeCreatorFormProps, AddNewRecipeResponseProps, Error>();

export const fetchWorkingRecipesList = createAsyncAction(
    "FETCH_WORKING_RECIPES_LIST_REQUEST",
    "FETCH_WORKING_RECIPES_LIST_SUCCESS",
    "FETCH_WORKING_RECIPES_LIST_FAILURE"
)<void, WorkingRecipesListResponse, Error>();

export const editWorkingRecipe = createAsyncAction(
    "EDIT_WORKING_RECIPE_REQUEST",
    "EDIT_WORKING_RECIPE_SUCCESS",
    "EDIT_WORKING_RECIPE_FAILURE"
)<RecipeCreatorFormProps, ApiResponse, Error>();

export const deleteWorkingRecipe = createAsyncAction(
    "DELETE_WORKING_RECIPE_REQUEST",
    "DELETE_WORKING_RECIPE_SUCCESS",
    "DELETE_WORKING_RECIPE_FAILURE"
)<RecipeCreatorFormProps, ApiResponse, Error>();
