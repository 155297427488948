import React from "react";
import styles from "./CheckboxComponent.module.scss";
import { frontMessages } from "../../utils/messages";

export interface CheckboxInterface {
    item: string;
}

const assignUserRole = (item: string) => {
    if (item === "admin") {
        return frontMessages.privileges.superUser;
    }

    if (item === "price") {
        return frontMessages.privileges.price;
    }

    if (item === "editor") {
        return frontMessages.privileges.editor;
    }

    if (item === "viewer") {
        return frontMessages.privileges.viewer;
    }

    if (item === "owner") {
        return frontMessages.privileges.owner;
    }

    return item;
};

const CheckboxComponent: React.FC<CheckboxInterface> = ({ item }) => {
    return (
        <div className={styles.checkbox}>
            <label>
                <div className={styles.usersListRolesTitle}>{assignUserRole(item)}</div>
                <input name={item} type="checkbox" checked={true} onChange={() => assignUserRole(item)} />
                <span />
            </label>
        </div>
    );
};
export default CheckboxComponent;
