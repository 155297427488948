import React from "react";
import { Link } from "react-router-dom";
import styles from "./NavLink.module.scss";
import { useSelector } from "react-redux";
import { getRouterState } from "store/router/router.selector";

export interface NavLinkProps {
    linkTo: string;
    children: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ children, linkTo }) => {
    const { location } = useSelector(getRouterState);
    const isActive = location.pathname === linkTo;

    if (linkTo) {
        return (
            <Link className={`${isActive ? styles.active : ""}`} to={linkTo}>
                {children}
            </Link>
        );
    }

    return <>{children}</>;
};

export default NavLink;
