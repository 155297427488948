import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./auth.actions";
import { authorize, verifyMe } from "./auth.actions";
import {
    getAccessToken,
    handleBasicPrivileges,
    handleEditorPrivileges,
    handleOwnerPrivileges,
    handlePricePrivileges,
} from "utils/utils";

export type AuthorizationAction = ActionType<typeof actions>;

export type AuthorizationState = Readonly<{
    isAuthorized: boolean;
    isAuthorizing: boolean;
    authorizationFailed: boolean;
    isBlocked: boolean;
    token: string | null;
    userName: string;
    userRoles: string[];
    privileges: UserPrivilegesProps;
}>;

export interface UserPrivilegesProps {
    hasOnlyBasicPrivileges: boolean;
    hasOwnerPrivileges: boolean;
    hasEditorPrivileges: boolean;
    hasPricePrivileges: boolean;
}

const accessToken = getAccessToken();

export const authorizationInitialState: AuthorizationState = {
    isAuthorized: !!accessToken,
    isAuthorizing: false,
    authorizationFailed: false,
    token: accessToken,
    isBlocked: false,
    userName: "",
    userRoles: [],
    privileges: {
        hasOnlyBasicPrivileges: false,
        hasOwnerPrivileges: false,
        hasEditorPrivileges: false,
        hasPricePrivileges: false,
    },
};

export const authorizationReducer = createReducer<AuthorizationState, AuthorizationAction>(authorizationInitialState)
    .handleAction([authorize.request], () => {
        return {
            ...authorizationInitialState,
            isAuthorized: false,
            isAuthorizing: true,
            token: null,
        };
    })
    .handleAction(authorize.success, (state, action) => {
        return {
            ...state,
            isAuthorizing: false,
            isAuthorized: true,
            userName: action.payload.username,
            userRoles: action.payload.roles,
            token: action.payload.accessToken,
            isBlocked: action.payload.isBlocked,
            privileges: {
                hasOnlyBasicPrivileges: handleBasicPrivileges(action.payload.roles),
                hasOwnerPrivileges: handleOwnerPrivileges(action.payload.roles),
                hasEditorPrivileges: handleEditorPrivileges(action.payload.roles),
                hasPricePrivileges: handlePricePrivileges(action.payload.roles),
            },
        };
    })
    .handleAction(authorize.failure, (state: any) => {
        return {
            ...state,
            authorizationFailed: true,
            isAuthorizing: false,
        };
    })
    .handleAction(verifyMe.success, (state, action) => {
        return {
            ...state,
            isAuthorizing: false,
            isAuthorized: true,
            userName: action.payload.username,
            userRoles: action.payload.roles,
            isBlocked: action.payload.isBlocked,
            privileges: {
                hasOnlyBasicPrivileges: handleBasicPrivileges(action.payload.roles),
                hasOwnerPrivileges: handleOwnerPrivileges(action.payload.roles),
                hasEditorPrivileges: handleEditorPrivileges(action.payload.roles),
                hasPricePrivileges: handlePricePrivileges(action.payload.roles),
            },
        };
    })
    .handleAction(verifyMe.failure, (state: any) => {
        return {
            ...state,
            isAuthorized: false,
            authorizationFailed: true,
            isAuthorizing: false,
            token: null,
        };
    })
    .handleAction(actions.LOG_OUT as any, () => {
        return {
            isAuthorized: false,
            isAuthorizing: false,
            authorizationFailed: false,
            token: "",
            isBlocked: false,
            userName: "",
            userRoles: [],
            privileges: {
                hasOnlyBasicPrivileges: false,
                hasOwnerPrivileges: false,
                hasEditorPrivileges: false,
                hasPricePrivileges: false,
            },
        };
    });
