import { createAsyncAction } from "typesafe-actions";
import { RecipesHistoryResponse } from "api/recipesHistory";

export const fetchRecipesHistory = createAsyncAction(
    "FETCH_RECIPES_HISTORY_REQUEST",
    "FETCH_RECIPES_HISTORY_SUCCESS",
    "FETCH_RECIPES_HISTORY_FAILURE"
)<void, RecipesHistoryResponse, Error>();

export const fetchWorkingRecipesHistory = createAsyncAction(
    "FETCH_WORKING_RECIPES_HISTORY_REQUEST",
    "FETCH_WORKING_RECIPES_HISTORY_SUCCESS",
    "FETCH_WORKING_RECIPES_HISTORY_FAILURE"
)<void, RecipesHistoryResponse, Error>();
