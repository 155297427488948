import React from "react";
import { RecipesProps } from "../../../api/recipesList";
import styles from "./RecipeViewPrintHeader.module.scss";
import { useSelector } from "react-redux";
import { getAuthorizationState } from "../../../store/auth/auth.selector";

export interface RecipeViewPrintHeader {
    selectedRecipe: RecipesProps;
    sum: number;
    productionPurchase: string;
}
const RecipeViewPrintHeader: React.FC<RecipeViewPrintHeader> = ({ sum, selectedRecipe, productionPurchase }) => {
    const date = new Date();
    const { userName } = useSelector(getAuthorizationState);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerProduction}>{`Zlecenie Producyjne numer: ${productionPurchase}`}</div>
                <div className={styles.headerInfo}>
                    <div className={styles.headerLeft}>
                        <div className={styles.headerLeftItem}>
                            <div>{"Nazwa Wyrobu: "}</div>
                            <span>{selectedRecipe.recipeNumber}</span>
                        </div>
                        <div className={styles.headerLeftItem}>
                            <div>{"Ilość: "}</div>
                            <span>{`${sum}kg`}</span>
                        </div>
                    </div>
                    <div className={styles.headerRight}>
                        <div className={styles.headerLeftItem}>
                            <div>{"Data wydrukuWydrukował:"}</div>
                            <span className={styles.headerDate}>
                                {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
                            </span>
                        </div>
                        <div className={styles.headerLeftItem}>
                            <div>{"Wydrukował:"}</div>
                            <span className={styles.headerDate}>{userName}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.production}>
                <div className={styles.productionLabel}>{"1. PRODUCKJA"}</div>
                <div className={styles.productionItems}>
                    <div className={styles.productionItemsName}>{"Wykonawca:"}</div>
                    <div className={styles.productionItemsStartDate}>{"Data rozpoczęcia:"}</div>
                    <div className={styles.productionItemsEndDate}>{"Data zakończenia:"}</div>
                </div>
            </div>
        </>
    );
};

export default RecipeViewPrintHeader;
