import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./recipesHistory.actions";
import { RecipesProps } from "api/recipesList";
import { fetchRecipesHistory, fetchWorkingRecipesHistory } from "./recipesHistory.actions";
import { compareListItems } from "utils/utils";

export type RecipesHistoryAction = ActionType<typeof actions>;

export type RecipesHistoryState = Readonly<{
    recipesHistory: RecipesProps[];
    workingRecipesHistory: RecipesProps[];
    isLoading: boolean;
}>;

export const ingredientsListInitialState: RecipesHistoryState = {
    isLoading: false,
    recipesHistory: [],
    workingRecipesHistory: [],
};

export const recipesHistoryReducer = createReducer<RecipesHistoryState, RecipesHistoryAction>(
    ingredientsListInitialState
)
    .handleAction(fetchRecipesHistory.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchRecipesHistory.success, (state, action) => {
        return {
            ...state,
            recipesHistory: action.payload.recipesHistory.sort((firstItem, secondItem) =>
                compareListItems(secondItem.createdAt, firstItem.createdAt)
            ),
            isLoading: false,
        };
    })
    .handleAction(fetchRecipesHistory.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    })
    .handleAction(fetchWorkingRecipesHistory.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchWorkingRecipesHistory.success, (state, action) => {
        return {
            ...state,
            workingRecipesHistory: action.payload.recipesHistory.sort((firstItem, secondItem) =>
                compareListItems(secondItem.createdAt, firstItem.createdAt)
            ),
            isLoading: false,
        };
    })
    .handleAction(fetchWorkingRecipesHistory.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
