import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export const notification = (message: string, success: boolean, title?: string) => {
    store.addNotification({
        title: title && title,
        message: message,
        type: success ? "success" : "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
            showIcon: true,
        },
    });
};

export const notificationsMessages = {
    loginError: "niepoprawny login lub hasło",
    loginSuccess: "Zalogowano pomyślnie",
};
