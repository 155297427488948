import API, { post } from "./api";

export interface Authorization {
    username: string;
    password: string;
}

const addNewRecipe = (credentials: any) =>
    post<Authorization>(`${API.baseApiUrl}/new-recipe`, credentials).then(({ parsedBody }) => parsedBody);

const editRecipe = (credentials: any) =>
    post<Authorization>(`${API.baseApiUrl}/edit-recipe`, credentials).then(({ parsedBody }) => parsedBody);

const deleteRecipe = (credentials: any) =>
    post<Authorization>(`${API.baseApiUrl}/delete-recipe`, credentials).then(({ parsedBody }) => parsedBody);

const printRecipe = (credentials: any) =>
    post<Authorization>(`${API.baseApiUrl}/print-recipe`, credentials).then(({ parsedBody }) => parsedBody);

export default {
    addNewRecipe,
    editRecipe,
    deleteRecipe,
    printRecipe,
};
