import API, { get } from "api/api";
import { RecipesProps } from "api/recipesList";

export interface RecipesHistoryResponse {
    recipesHistory: RecipesProps[];
}

const fetchRecipesHistory = (credentials: any) =>
    get<RecipesHistoryResponse>(`${API.baseApiUrl}/history`, credentials).then(({ parsedBody }) => parsedBody);

const fetchWorkingRecipesHistory = (credentials: any) =>
    get<RecipesHistoryResponse>(`${API.baseApiUrl}/working-history`, credentials).then(({ parsedBody }) => parsedBody);

export default {
    fetchRecipesHistory,
    fetchWorkingRecipesHistory,
};
