import React from "react";
import styles from "./hooks.module.scss";
import { useClickedOutside } from "./useClickedOutside";
import { useSearcher } from "./useSearcher";

export const useSelect = (list: any[], afterSelect?: () => void, placeholder?: string, isSearchable?: boolean) => {
    const initialLabel = placeholder || "Wybierz z listy";
    const [selectedValue, setSelectedValue] = React.useState("");
    const [isDropDownOpen, setDropDownOpen] = React.useState(false);
    const [selectedLabel, setSelectedLabel] = React.useState(initialLabel);
    const { ref } = useClickedOutside(() => setDropDownOpen(false));

    const { searcher, searchInput } = useSearcher();

    const handleSelectChange = (value: string, label: string) => () => {
        setSelectedLabel(label);
        setSelectedValue(value);
        setDropDownOpen(false);
    };

    const clearSelectValues = () => {
        setSelectedValue("");
        setSelectedLabel(initialLabel);
        afterSelect?.();
    };

    const handleOpenDropdown = () => setDropDownOpen(true);

    const selectComponent = (
        <div className={styles.selectWrapper} ref={ref}>
            <div className={styles.select}>
                <div className={styles.selectInput} onMouseDown={handleOpenDropdown}>
                    <span>{selectedLabel}</span>
                    {!selectedValue && <div className={styles.selectIconList} />}
                    {selectedValue && <div className={styles.selectIconCross} onMouseDown={clearSelectValues} />}
                    {isDropDownOpen && (
                        <>
                            <ul className={styles.selectList}>
                                {isSearchable && (
                                    <li className={`${styles.selectListItem} ${styles.selectSearcher}`}>{searcher}</li>
                                )}
                                {list
                                    .filter((item) => (isSearchable ? item.label?.includes(searchInput) : item))
                                    .map((item, index) => {
                                        const itemValue = item.categoryName || item.value;
                                        const itemLabel = item.categoryName || item.label;
                                        return (
                                            <li
                                                className={styles.selectListItem}
                                                key={index}
                                                onClick={handleSelectChange(itemValue, itemLabel)}>
                                                <span>{itemLabel}</span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </div>
    );

    return {
        selectedValue,
        selectedLabel,
        selectComponent,
        setSelectedValue,
        setSelectedLabel,
    };
};
