import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FieldTitle, FieldType } from "utils/forms.helper";
import { frontMessages } from "utils/messages";
import styles from "./IngredientDetails.module.scss";
import Stepper from "../Stepper/Stepper";
import Step from "../Step/Step";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteIngredient,
    updateIngredientCategory,
    updateIngredientDensity,
    updateIngredientName,
    updateIngredientPrice,
} from "store/IngredientList/ingredienstList.action";
import { getAuthorizationState } from "store/auth/auth.selector";
import RecipesList from "components/RecipesList/RecipesList";
import { getRecipesListState } from "store/RecipesList/RecipesList.selector";
import { Link, useLocation } from "react-router-dom";
import routes from "routes";
import { getRouterState } from "store/router/router.selector";
import { Redirect } from "react-router";
import { history } from "store/store";
import { RecipeSource } from "components/RecipeView/RecipeView";
import WarningNotification from "../WarningNotification/WarningNotification";
import Icons, { IconType } from "../Icons/Icons";
import { getIngredientsListState } from "../../store/IngredientList/IngredientsList.selector";
import { filterRecipesToIngredients, getIngredientDetailFromUrl } from "./ingredientDetail.ihelper";
import { getWorkingIngredientsListState } from "../../store/workingIngredients/WorkingIngredientList.selector";
import { getWorkingRecipesListState } from "../../store/workingRecipe/workingRecipe.selector";
import {
    deleteWorkingIngredient,
    fetchWorkingIngredientsList,
    updateWorkingIngredientCategory,
    updateWorkingIngredientDensity,
    updateWorkingIngredientName,
    updateWorkingIngredientPrice,
} from "../../store/workingIngredients/WorkingIngredientList.action";
import { fetchWorkingRecipesList } from "../../store/workingRecipe/workingRecipe.actions";

type IngredientsPriceFormData = {
    price: string;
};

type IngredientsNameFormData = {
    newName: string;
};

type IngredientsDensityFormData = {
    density: string;
};

type IngredientsCategoryFormData = {
    ingredientCategory: string;
};

const IngredientDetails: React.FC = () => {
    const { location } = useSelector(getRouterState);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const locationType = location.state as any;
    const { userName } = useSelector(getAuthorizationState);
    const { recipesList } = useSelector(getRecipesListState);
    const { ingredients } = useSelector(getIngredientsListState);
    const { workingIngredients } = useSelector(getWorkingIngredientsListState);
    const { workingRecipes } = useSelector(getWorkingRecipesListState);

    const isWorkingIngredient = pathname.includes("robocze");
    const inList = isWorkingIngredient ? workingIngredients : ingredients;
    const recList = isWorkingIngredient ? workingRecipes : recipesList;

    const { handleSubmit, register, errors } = useForm<IngredientsNameFormData>({ mode: "onBlur" });
    const {
        register: registerPrice,
        handleSubmit: handleSubmitPrice,
        errors: priceErrors,
    } = useForm<IngredientsPriceFormData>({ mode: "onBlur" });
    const {
        register: registerCategory,
        handleSubmit: handleSubmitCategory,
        errors: categoryErrors,
    } = useForm<IngredientsCategoryFormData>({ mode: "onBlur" });
    const {
        register: registerDensity,
        handleSubmit: handleSubmitDensity,
        errors: densityError,
    } = useForm<IngredientsDensityFormData>({ mode: "onBlur" });

    const ingredientDetails = inList.find(
        (item) => item.id.toString() === getIngredientDetailFromUrl(location.pathname)
    );

    const changeLocation = () =>
        history.push({ pathname: isWorkingIngredient ? routes.WORKING_INGREDIENT_LIST : routes.INGREDIENTS_LIST });

    const onSubmitName = () =>
        handleSubmit((values: any) => {
            const details = {
                id: locationType?.itemId || ingredientDetails?.id,
                oldName: locationType?.ingredientName || ingredientDetails?.ingredient,
                newName: values.newName,
                userName: userName,
                price: locationType?.price || ingredientDetails?.price || "0",
                ingredientCategory: locationType?.ingredientCategory || ingredientDetails?.ingredientCategory,
            };

            isWorkingIngredient
                ? dispatch(updateWorkingIngredientName.request(details))
                : dispatch(updateIngredientName.request(details));

            changeLocation();
        });

    const onSubmitPrice = () =>
        handleSubmitPrice((values: any) => {
            const details = {
                id: locationType?.itemId || ingredientDetails?.id,
                name: locationType?.ingredientName || ingredientDetails?.ingredient,
                newPrice: values.price,
                userName: userName,
                price: locationType?.price || ingredientDetails?.price || "0",
                ingredientCategory: locationType?.ingredientCategory || ingredientDetails?.ingredientCategory,
            };

            isWorkingIngredient
                ? dispatch(updateWorkingIngredientPrice.request(details))
                : dispatch(updateIngredientPrice.request(details));

            changeLocation();
        });

    const onSubmitCategory = () =>
        handleSubmitCategory((values: any) => {
            const details = {
                id: locationType?.itemId || ingredientDetails?.id,
                name: locationType?.ingredientName || ingredientDetails?.ingredient,
                userName: userName,
                price: locationType?.price || ingredientDetails?.price || "0",
                ingredientCategory:
                    values.ingredientCategory ||
                    locationType?.ingredientCategory ||
                    ingredientDetails?.ingredientCategory,
            };
            isWorkingIngredient
                ? dispatch(updateWorkingIngredientCategory.request(details))
                : dispatch(updateIngredientCategory.request(details));

            changeLocation();
        });

    const onSubmitDensity = () =>
        handleSubmitDensity((values: any) => {
            const details = {
                id: locationType?.itemId || ingredientDetails?.id,
                name: locationType?.ingredientName || ingredientDetails?.ingredient,
                newDensity: values.density,
                userName: userName,
                density: locationType?.density || ingredientDetails?.density || 0,
                ingredientCategory: locationType?.ingredientCategory || ingredientDetails?.ingredientCategory,
            };

            isWorkingIngredient
                ? updateWorkingIngredientDensity.request(details)
                : updateIngredientDensity.request(details);

            changeLocation();
        });

    const handleDeleteIngredient = () => {
        const details = {
            id: locationType?.itemId,
            name: locationType?.ingredientName,
            userName: userName,
            price: locationType?.price || ingredientDetails?.price || "0",
            ingredientCategory: locationType?.ingredientCategory || ingredientDetails?.ingredientCategory,
        };

        isWorkingIngredient
            ? dispatch(deleteWorkingIngredient.request(details))
            : dispatch(deleteIngredient.request(details));

        changeLocation();
    };

    useEffect(() => {
        if (isWorkingIngredient) {
            if (!workingIngredients.length) {
                dispatch(fetchWorkingIngredientsList.request());
            }

            if (!workingRecipes?.length) {
                dispatch(fetchWorkingRecipesList.request());
            }
        }
    }, [dispatch]);

    if (!locationType?.ingredientName) {
        return <Redirect to={isWorkingIngredient ? routes.WORKING_INGREDIENT_LIST : routes.INGREDIENTS_LIST} />;
    }

    return (
        <div className={styles.detail}>
            <div className={styles.detailName}>
                <span className={styles.detailNameTitle}>
                    {isWorkingIngredient ? frontMessages.ingredients.workingName : frontMessages.ingredients.name}
                </span>
                <span className={styles.detailNameIngredient}> {locationType?.ingredientName}</span>
            </div>
            <div>
                <div>
                    <span>{locationType?.ingredientName || ingredientDetails?.ingredient}</span>
                    <Stepper>
                        <Step
                            render={(props) => {
                                return (
                                    <>
                                        <button
                                            className={`btn--default ${styles.detailButton}`}
                                            onClick={props.onNextStep}>
                                            {frontMessages.ingredients.handleName}
                                        </button>
                                    </>
                                );
                            }}
                        />
                        <Step
                            render={(props) => {
                                return (
                                    <div className={styles.detailFormWrapper}>
                                        <WarningNotification label={frontMessages.ingredients.changeNameDescription} />
                                        <form key={1} onSubmit={onSubmitName()}>
                                            <input
                                                ref={register({ minLength: 2, required: true })}
                                                type={FieldType.TEXT}
                                                placeholder={frontMessages.placeholders.newIngredientName}
                                                name={FieldTitle.NEW_NAME}
                                                id={FieldTitle.NEW_NAME}
                                            />
                                            {errors.newName && (
                                                <span className={styles.detailError}>
                                                    pole jest wymagane minimum dwa znaki
                                                </span>
                                            )}
                                            <button
                                                className="btn btn--default btn--green btn--width-medium"
                                                type="submit">
                                                {frontMessages.button.change}
                                                <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                                            </button>
                                            <button
                                                className="btn--black btn--width-medium"
                                                onClick={props.onPreviousStep}
                                                type="button">
                                                {frontMessages.button.close}
                                            </button>
                                        </form>
                                    </div>
                                );
                            }}
                        />
                    </Stepper>
                </div>
                <>
                    <span>{locationType?.price || ingredientDetails?.price || "Dodaj cenę"} zł</span>
                    <Stepper>
                        <Step
                            render={(props) => {
                                return (
                                    <button
                                        className={`btn--default ${styles.detailButton}`}
                                        onClick={props.onNextStep}>
                                        {frontMessages.ingredients.handlePrice}
                                    </button>
                                );
                            }}
                        />
                        <Step
                            render={(props) => {
                                return (
                                    <div className={styles.detailFormWrapper}>
                                        <WarningNotification label={frontMessages.ingredients.changePriceDescription} />
                                        <form key={2} onSubmit={onSubmitPrice()}>
                                            <input
                                                ref={registerPrice({ required: true })}
                                                type={FieldType.NUMBER}
                                                step="0.01"
                                                placeholder={frontMessages.placeholders.changePrice}
                                                name={FieldTitle.PRICE}
                                            />
                                            {priceErrors.price && (
                                                <span className={styles.detailError}>pole nie może być puste</span>
                                            )}
                                            <button
                                                className="btn btn--default btn--green btn--width-medium"
                                                type="submit">
                                                {frontMessages.button.change}
                                                <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                                            </button>
                                            <button
                                                className="btn--black btn--width-medium"
                                                onClick={props.onPreviousStep}
                                                type="button">
                                                {frontMessages.button.close}
                                            </button>
                                        </form>
                                    </div>
                                );
                            }}
                        />
                    </Stepper>
                </>
                <>
                    <span>{locationType?.density || ingredientDetails?.density || "Dodaj gęstość"}</span>
                    <Stepper>
                        <Step
                            render={(props) => {
                                return (
                                    <button
                                        className={`btn--default ${styles.detailButton}`}
                                        onClick={props.onNextStep}>
                                        {frontMessages.ingredients.handleDensity}
                                    </button>
                                );
                            }}
                        />
                        <Step
                            render={(props) => {
                                return (
                                    <div className={styles.detailFormWrapper}>
                                        <WarningNotification
                                            label={frontMessages.ingredients.changeDensityDescription}
                                        />
                                        <form key={3} onSubmit={onSubmitDensity()}>
                                            <input
                                                ref={registerDensity({ required: true })}
                                                type={FieldType.NUMBER}
                                                step="0.01"
                                                placeholder={frontMessages.placeholders.changeDensity}
                                                name={FieldTitle.DENSITY}
                                            />
                                            {densityError.density && (
                                                <span className={styles.detailError}>pole nie może być puste</span>
                                            )}
                                            <button
                                                className="btn btn--default btn--green btn--width-medium"
                                                type="submit">
                                                {frontMessages.button.change}
                                                <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                                            </button>
                                            <button
                                                className="btn--black btn--width-medium"
                                                onClick={props.onPreviousStep}
                                                type="button">
                                                {frontMessages.button.close}
                                            </button>
                                        </form>
                                    </div>
                                );
                            }}
                        />
                    </Stepper>
                </>
                <>
                    <span>
                        {locationType?.ingredientCategory || ingredientDetails?.ingredientCategory || "Dodaj kategorię"}
                    </span>
                    <Stepper>
                        <Step
                            render={(props) => {
                                return (
                                    <button
                                        className={`btn--default ${styles.detailButton}`}
                                        onClick={props.onNextStep}>
                                        {frontMessages.ingredients.handleCategory}
                                    </button>
                                );
                            }}
                        />
                        <Step
                            render={(props) => {
                                return (
                                    <div className={styles.detailFormWrapper}>
                                        <WarningNotification
                                            label={frontMessages.ingredients.changeCategoryDescription}
                                        />
                                        <form key={4} onSubmit={onSubmitCategory()}>
                                            <input
                                                ref={registerCategory({ required: true })}
                                                type={FieldType.TEXT}
                                                placeholder={frontMessages.placeholders.changeCategory}
                                                name={FieldTitle.INGREDIENT_CATEGORY}
                                            />
                                            {categoryErrors.ingredientCategory && (
                                                <span className={styles.detailError}>pole nie może być puste</span>
                                            )}
                                            <button
                                                className="btn btn--default btn--green btn--width-medium"
                                                type="submit">
                                                {frontMessages.button.change}
                                                <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                                            </button>
                                            <button
                                                className="btn--black btn--width-medium"
                                                onClick={props.onPreviousStep}
                                                type="button">
                                                {frontMessages.button.close}
                                            </button>
                                        </form>
                                    </div>
                                );
                            }}
                        />
                    </Stepper>
                </>
                <Stepper>
                    <Step
                        render={(props) => {
                            return (
                                <button className={`btn--default ${styles.detailButton}`} onClick={props.onNextStep}>
                                    {frontMessages.ingredients.remove}
                                </button>
                            );
                        }}
                    />
                    <Step
                        render={(props) => {
                            return (
                                <div className={styles.detailFormWrapper}>
                                    <WarningNotification label={frontMessages.ingredients.removeDescription} />
                                    <button
                                        onClick={handleDeleteIngredient}
                                        type="button"
                                        className={`btn btn--red btn--default btn--width-medium ${
                                            locationType?.ingredientCount !== 0 ? styles.detailFormWrapperDisabled : ""
                                        }`}
                                        disabled={locationType?.ingredientCount !== 0}>
                                        <span>{frontMessages.ingredients.remove}</span>
                                        <Icons iconType={IconType.TRASH} styles={"icon-button"} />
                                    </button>
                                    <button
                                        className="btn--black btn--width-medium"
                                        onClick={props.onPreviousStep}
                                        type="button">
                                        {frontMessages.button.close}
                                    </button>
                                </div>
                            );
                        }}
                    />
                </Stepper>
            </div>
            <Link to={isWorkingIngredient ? routes.WORKING_INGREDIENT_LIST : routes.INGREDIENTS_LIST}>
                <button className={`btn--black ${styles.detailBackButton}`} type="button">
                    {frontMessages.button.backToList}
                </button>
            </Link>
            <div>
                <RecipesList
                    recipesListState={filterRecipesToIngredients(recList, locationType)}
                    isHistory={false}
                    isWorkingHistory={false}
                    recipeSource={isWorkingIngredient ? RecipeSource.WORKING_LIST : RecipeSource.MAIN_LIST}
                />
            </div>
        </div>
    );
};

export default IngredientDetails;
