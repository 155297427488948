import ModalComponent from "../../modal/Modal";
import { frontMessages } from "../../../utils/messages";
import React from "react";
import ReactToPrint from "react-to-print";
import { printRecipe } from "../../../store/recipeOperations/recipeOperations.actions";
import { useDispatch } from "react-redux";
import styles from "./RecipeModal.module.scss";

interface PrintRecipeModalProps {
    modalIsOpen: boolean;
    valuesToPrint: any;
    componentRef: any;
    productionPurchase: string;
    closeModal(): void;
    handleProductionPurchase(value: string): void;
}

const PrintRecipeModal: React.FC<PrintRecipeModalProps> = ({
    modalIsOpen,
    closeModal,
    componentRef,
    valuesToPrint,
    productionPurchase,
    handleProductionPurchase,
}) => {
    const dispatch = useDispatch();

    const handleAfterPrint = () => {
        dispatch(printRecipe.request(valuesToPrint as any));
        closeModal();
    };

    return (
        <ModalComponent modalIsOpen={modalIsOpen} closeModal={closeModal}>
            <div className={styles.wrapper}>
                <span className={styles.wrapperTitle}>
                    Czy napewno chcesz wydrukować recepturę ? (zostanie dodany wpis do rejestru)
                </span>
                <div className={styles.wrapperInput}>
                    <span>Podaj Numer Prtodukcyjny</span>
                    <input
                        name={"numer produkcyjny"}
                        value={productionPurchase}
                        onChange={(event) => handleProductionPurchase(event.target.value)}
                    />
                </div>
                <div className={styles.wrapperButtons}>
                    <ReactToPrint
                        bodyClass={styles.buttons}
                        onBeforePrint={handleAfterPrint}
                        trigger={() => (
                            <button form="form" type="submit" className={"btn--default btn--width-medium"}>
                                {frontMessages.button.printProductionRecipe}
                            </button>
                        )}
                        content={() => componentRef.current}
                    />
                    <button className="btn--black" onClick={closeModal}>
                        ZAMKNIJ
                    </button>
                </div>
            </div>
        </ModalComponent>
    );
};

export default PrintRecipeModal;
