import React from "react";
import styles from "./RecipeActionButtons.module.scss";
import Icons, { IconType } from "../../Icons/Icons";
import { NewRecipeState } from "../RecipeFormCreator";
interface RecipeActionButtonsProps {
    inputList: NewRecipeState[];
    setInputList: React.Dispatch<React.SetStateAction<NewRecipeState[]>>;
    index: number;
    item: NewRecipeState;
}
const RecipeActionButtons: React.FC<RecipeActionButtonsProps> = ({ inputList, setInputList, index, item }) => {
    const addIngredient = (index: number) => () =>
        setInputList((fields) => {
            const outputState = fields.slice(0);
            outputState.splice(index + 1, 0, {
                ingredientName: "",
                ingredientValue: "",
                ingredientDescription: null,
                ingredientPrice: "0",
                isActive: false,
            });
            return outputState;
        });

    const removeIngredient = (index: number) => () => {
        if (inputList.length > 1) {
            const outputState = [...inputList];

            if (index !== -1) {
                outputState.splice(index, 1);
                setInputList(outputState);
            }
        }
    };

    const addGap = (index: number) => () => {
        const ingredientList = [...inputList];
        ingredientList[index].ingredientDescription = "GAP";
        setInputList(ingredientList);
    };

    return (
        <>
            <div onMouseDown={addIngredient(index)} className={styles.contentIcon}>
                <Icons iconType={IconType.PLUS} styles={styles.contentIconContent} />
            </div>
            <div
                onMouseDown={removeIngredient(index)}
                className={`${styles.contentIcon} ${inputList.length === 1 ? styles.contentIconDisabled : ""}`}>
                <Icons iconType={IconType.MINUS} styles={styles.contentIconContent} />
            </div>
            {item.ingredientDescription === null ? (
                <div onMouseDown={addGap(index)} className={styles.contentIcon}>
                    <Icons iconType={IconType.WORKING} styles={styles.contentIconContent} />
                </div>
            ) : (
                <div className={styles.contentEmptyIcon} />
            )}
        </>
    );
};

export default RecipeActionButtons;
