import React from "react";
import { RecipesProps } from "../../../api/recipesList";
import styles from "./RecipeViewFooter.module.scss";

interface RecipeViewFooterProps {
    selectedRecipe: RecipesProps;
}

const RecipeViewFooter: React.FC<RecipeViewFooterProps> = ({ selectedRecipe }) => {
    return (
        <div className={styles.recipeFooter}>
            <div className={styles.recipeFooterDeafult}>
                <span className={styles.recipeFooterDeafultTitle}>Dodatkowe uwagi: </span>
                <span className={styles.recipeFooterDeafultDescription}>{selectedRecipe.defaultValue} </span>
            </div>
            <div className={styles.recipeFinal}>
                <div className={`${styles.recipeFinalWrapper}`}>
                    <span>Firma:</span>
                    <span>{selectedRecipe.company}</span>
                </div>
                <div className={`${styles.recipeFinalWrapper}`}>
                    <span>Utwardzacz:</span>
                    <span>{selectedRecipe.hardener}</span>
                </div>
                <div className={`${styles.recipeFinalWrapper}`}>
                    <span>Lepkość:</span>
                    <span>{selectedRecipe.viscosity}</span>
                </div>
                <div className={`${styles.recipeFinalWrapper} `}>
                    <span>Gęstość:</span>
                    <span>{selectedRecipe.density}</span>
                </div>
            </div>
            <div className={styles.recipeLast}>
                <span>ostatnia edycja:</span>
                <span>{selectedRecipe.updatedAt.slice(0, 10)}</span>
            </div>
        </div>
    );
};

export default React.memo(RecipeViewFooter);
