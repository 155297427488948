import { call, put } from "redux-saga/effects";
import Api from "../../api/recipeOperations";
import { notification } from "components/Notification/Notification";
import {
    addNewRecipe,
    editRecipe,
    AddNewRecipeResponseProps,
    deleteRecipe,
    printRecipe,
} from "./recipeOperations.actions";
import { history } from "store/store";
import routes from "routes";
import { fetchRecipesList } from "../RecipesList/RecipesList.actions";
import { fetchRecipesHistory } from "../recipesHistory/recipesHistory.actions";
import { fetchIngredientsHistory } from "../ingredientsHistory/IngredientsHistory.actions";
import { fetchIngredientsList } from "../IngredientList/ingredienstList.action";

export function* addNewRecipeSaga(action: any): Generator {
    try {
        const response = yield call(Api.addNewRecipe, action.payload);
        const typedResponse = response as AddNewRecipeResponseProps;
        notification(typedResponse.message, typedResponse.success);
        yield put(addNewRecipe.success(typedResponse));
        yield put(fetchRecipesList.request());
        yield put(fetchRecipesHistory.request());
        yield put(fetchIngredientsHistory.request());
        yield put(fetchIngredientsList.request());
        history.push({ pathname: routes.RECIPES });
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(addNewRecipe.failure(response));
    }
}

export function* editRecipeSaga(action: ReturnType<typeof editRecipe.request>): Generator {
    try {
        const response = yield call(Api.editRecipe, action.payload);
        const typedResponse = response as AddNewRecipeResponseProps;
        notification(typedResponse.message, typedResponse.success);
        if (typedResponse.message) {
            yield put(editRecipe.success(typedResponse));
            yield put(fetchRecipesList.request());
            yield put(fetchRecipesHistory.request());

            yield put(fetchIngredientsHistory.request());
            yield put(fetchIngredientsList.request());
            history.push({ pathname: routes.RECIPES });
        }
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(editRecipe.failure(response));
    }
}

export function* deleteRecipeSaga(action: ReturnType<typeof deleteRecipe.request>): Generator {
    try {
        const response = yield call(Api.deleteRecipe, action.payload);
        const typedResponse = response as AddNewRecipeResponseProps;
        notification(typedResponse.message, typedResponse.success);
        yield put(deleteRecipe.success(typedResponse));
        yield put(fetchRecipesList.request());
        yield put(fetchRecipesHistory.request());
        yield put(fetchIngredientsHistory.request());
        yield put(fetchIngredientsList.request());
        history.push({ pathname: routes.RECIPES });
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(deleteRecipe.failure(response));
    }
}

export function* printRecipeSaga(action: ReturnType<typeof printRecipe.request>): Generator {
    try {
        const response = yield call(Api.printRecipe, action.payload);
        const typedResponse = response as AddNewRecipeResponseProps;
        notification(typedResponse.message, typedResponse.success);
        yield put(printRecipe.success(typedResponse));
        yield put(fetchRecipesHistory.request());
    } catch (response: any) {
        notification(response.parsedBody.message, false);
        yield put(deleteRecipe.failure(response));
    }
}
