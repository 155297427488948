import API, { get, post } from "api/api";

export interface CategoriesResponse {
    categories: CategoriesProps[];
}

export interface CategoriesProps {
    categoryName: string;
    categoryId: number;
}

export interface AddCategoryResponse {
    success: boolean;
    message: string;
}

const fetchCategories = (credentials: any) =>
    get<CategoriesResponse>(`${API.baseApiUrl}/categories`, credentials).then(({ parsedBody }) => parsedBody);

const addCategory = (credentials: any) =>
    post<AddCategoryResponse>(`${API.baseApiUrl}/categories/add-category`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

const editCategory = (credentials: any) =>
    post<AddCategoryResponse>(`${API.baseApiUrl}/categories/edit-category`, credentials).then(
        ({ parsedBody }) => parsedBody
    );

export default {
    fetchCategories,
    addCategory,
    editCategory,
};
